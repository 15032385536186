import { ratingHive, scoreHive, approvalHive, placeholderTab, messagesTab, learnersTab, classesTab, subjectsTab, projectsTab, evidenceTab, skillsTab, portfoliosTab } from "./utilitiesConstants";
import { handleToggle, getSelectionState, getSelectionMode, saveState, getState } from "./utilitiesState"
import { rd, skillNameText, rdPreferShort, rdDefaultWidth } from "./utilitiesResponsiveData"
import { lookupLingo, TermChat, TermClass, TermEvidence, TermExtracurricular, TermInactive, TermLearner, TermPortfolio, TermProject, TermSkill, TermSubject } from "./customizable"
import { Unassigned, Active, Complete, Extracurricular, isStepID, isEvidenceID, getItemID, getItem3ID, getSkillByID, getEvidenceByID } from "./data"

// consider: rename to .js (no jsx in file now)
// consider: split out state management functions? move tabs and updateLingo back to Navigator? move isActive back to Evidence? move the tabs to utilitiesTabs?

const PortfolioGroup = 1, ProjectsGroup = 2, EvidenceGroup = 3;

const findByID = (state, id) => state.find((item) => Number(item) === Number(id));
const doFilteredSearch = (searchString) => (!searchString || searchString.length < 1) ? 0 : searchString.length;    // would normally be a boolean, but using as a memo dependency

// consolidates app level state 
const setAppState = (
    contentState,           // the current page type displayed in the content pane
    classState,             // the current (LiFT) class displayed/selected
    projectState,           // the current (LiFT) project displayed/selected
    rubricState,            // the current (LiFT) rubric selected/displayed in details pane
    navSelectState,         // the selection state "owned" by Navigator--exposed to update Navigator selection state when page is changed
    navExpandState,         // the expansion state "owned" by Navigator--exposed to open Class have on selection
    searchState,            // the current searchstring, if any
    showDetails,            // show details pane
    showPreview,            // show preview pane
    projectListRef,         // quick project access ref (for synchronizing)
    studentListRef,         // quick student access ref (for synchronizing)
    smallHeaderState,       // use small header size
    autoCloseState,         // auto-close groups during keyboard navigation
    autoOpenPortfoliosState,    // auto-oepn portofliso during keyboard navigation
    colorInfoState,         // the scheme that defines the LiFT data colors--used as a useMemo dependency for efficiency
    colorState,             // the actual colors schemes built from colorInfoState
    projectFilterState,     // used to filter projects
    evidenceFilterState,    // used to filter evidence
    skillFilterState,       // used to filter skills
    statusFilterState,      // used to filter status
    signInState,            // used to sign in/sign out
) => {
    return Object.seal({
        contentState,
        classState,
        projectState,
        rubricState,
        navSelectState,
        navExpandState,
        searchState,
        showDetails,
        showPreview,
        projectListRef,
        studentListRef,
        smallHeaderState,
        autoCloseState,
        autoOpenPortfoliosState,
        colorInfoState,
        colorState,
        projectFilterState,
        evidenceFilterState,
        skillFilterState,
        statusFilterState,
        signInState,
        navigationObject: 0,     // meaning is context dependent--to navigate to a specific page+object (such as classes pane plus a specific class), set later
    })
}

// use to consolidate component level state 
const setComponentState = (
    componentType,          // classesTab, etc. so that undo stack can reconstuct contentPane
    appState,               // copy app state, to eliminate need to pass another parameter
    expansionState,         // which nodes are expanded
    selectionState,         // which nodes are selected (using multi-selecct)
    singleSelectState,      // which node is selected (using single select) --use an array, 0: item, 1: level1, 2: group
    gradeState,             // any uncommitted changes to grades, shared by all components
    pinState,               // which nodes are pinned
    activeTabState,         // which tab is active
    viewModeState,          // which view mode is active
    filterState,            // which filters are applied (to start, just use an array of IDs, so 401 means filter to first skill, -202 means filter to second class, assume empty means no filter and elememt IDs means just show those)
    bulkModeState,          // in bulk selection mode (so, use selectionState vs singleSelectState)
    selectionInfo,          // selection info for pageMap, etc.
) => {
    // special case for navigation, which is missing several state params
    if (componentType !== placeholderTab && (isNaN(componentType) || !appState || !expansionState || !selectionState || !singleSelectState || !gradeState || !pinState || !activeTabState || !viewModeState || !filterState || !bulkModeState || !selectionInfo))
        console.debug(`SetComponentState: missing or invalid parameters: ${componentType} ${appState} ${expansionState} ${selectionState} ${singleSelectState} ${gradeState} ${pinState} ${activeTabState} ${viewModeState} ${filterState} ${bulkModeState} ${selectionInfo}`);

    return Object.seal({
        componentType,
        appState,
        expansionState,
        selectionState,
        singleSelectState,
        gradeState,
        pinState,
        activeTabState,
        viewModeState,
        filterState,
        bulkModeState,
        selectionInfo,

        classes: null,
        handleClick: null,
        searchExpandoState: null,
        scrollRef: null
    })
}

const updateComponentState = (stateData, classes, handleClick, searchExpandoState = null) => {
    stateData.classes = classes;
    stateData.handleClick = handleClick;
    stateData.searchExpandoState = searchExpandoState;
}

// use to consolidate details pane state
const setDetailsPaneState = (
    appState,               // copy app state, to eliminate need to pass another parameter
    gradeState,             // any uncommitted changes to grades, shared by all components
    viewModeState,          // which view mode is active
    bulkModeState,          // in bulk selection mode
    skillIDState,           // skillID of rubric being displayed
    helpState,              // help context being displayed
) => {
    return Object.seal({
        appState,
        gradeState,
        viewModeState,
        bulkModeState,
        skillIDState,
        helpState,
    })
}

const setUndoState = (
    stateValue,
    objectID,
    hive,
    newValue,
    oldValue,
    tooltip
) => {
    return Object.seal({
        stateValue,
        objectID,
        hive,
        newValue,
        oldValue,
        tooltip
    })
}

const averageValue = (dataArray) => {
    return dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
}

const weightedAverage = (dataArray) => {
    let total = 0, divisor = 0;
    dataArray.forEach(scoreInfo => {
        total += scoreInfo.grade * scoreInfo.weight / 100;
        divisor += scoreInfo.weight / 100;
    });
    if (divisor === 0) return 0;
    return Math.round(total / divisor);
}

// return updated ratings, scores, and approvals, adjust for teacher changes (for skills, might work for other objects)
const getUpdatedResults = (stateData, itemID, skillInfo) => {
    const changedRating = saveState(stateData.gradeState, itemID, ratingHive, 0, getState);
    const updatedRating = changedRating ?? skillInfo?.rating ?? 0;
    const changedScore = saveState(stateData.gradeState, itemID, scoreHive, 0, getState);
    const updatedScore = changedScore ?? skillInfo?.score ?? 0;
    const changedApproval = saveState(stateData.gradeState, itemID, approvalHive, 0, getState);
    const updatedApproval = changedApproval ?? skillInfo?.approved ?? false;
    // const changedStatus = saveState(stateData.gradeState, itemID, statusHive, 0, getState);
    const updatedStatus = skillInfo?.status ?? 0;

    return [updatedRating, updatedScore, updatedApproval, updatedStatus];
}

// return an array of updated ratings, scores, and approvals, adjust for teacher changes (for an array of assignedSkills, might work for other objects)
const getUpdatedResultArray = (classData, stateData, studentID, evidenceID, assignedSkills, filterState = null) => {
    let skillNames = [];
    let skillIDs = [];
    let updatedRatings = [], changedRating, updatedRating;
    let updatedScores = [], changedScore, updatedScore;
    let updatedApprovals = [], changedApproval, updatedApproval;

    // handle filter
    let useFilter = false, filter = null;
    if (filterState) {
        [filter] = filterState;
        if (filter && filter.length > 0 && filter[0] !== "all")
            useFilter = true;
    }

    assignedSkills?.forEach(skill => {
        if (!useFilter || filter.find(filterID => filterID === skill.ID)) {
            const skillID = getItem3ID(studentID, evidenceID, skill.ID);
            const skillObj = getSkillByID(classData.skillList, skill.ID);
            const skillName = rd(skillObj, skillNameText, false, rdDefaultWidth, rdPreferShort);

            skillNames.push(skillName);
            skillIDs.push(skillID);
            changedRating = saveState(stateData.gradeState, skillID, ratingHive, 0, getState);
            updatedRating = changedRating ?? skill?.rating ?? 0;
            updatedRatings.push(updatedRating);
            changedScore = saveState(stateData.gradeState, skillID, scoreHive, 0, getState);
            updatedScore = changedScore ?? skill?.score ?? 0;
            updatedScores.push(updatedScore);
            changedApproval = saveState(stateData.gradeState, skillID, approvalHive, 0, getState);
            updatedApproval = changedApproval ?? skill?.approved ?? false;
            updatedApprovals.push(updatedApproval);
        }
    });

    return [skillNames, skillIDs, updatedRatings, updatedScores, updatedApprovals];
}

// build results arrays for a specific class, used by histogram
// todo: real function would need to be filtered by classID, but that's not in fake data now 
const getStudentResultsByClass = (stateData, student, classID, useEvidence = true) => {
    let ratings = [], scores = [], approvals = [], status = [];
    let evidenceInPortfolio = 0, evidenceAssigned = 0;
    const iterator = useEvidence ? student.evidence : student.skills;
    for (let i = 0; i < iterator.length; i++) {
        const itemInfo = iterator[i];

        // update grade based on teacher edits
        const itemID = getItemID(student.ID, itemInfo.ID);
        const [updatedRating, updatedScore, updatedApproval, updatedStatus] = getUpdatedResults(stateData, itemID, itemInfo);
        ratings.push(updatedRating);
        scores.push(updatedScore);
        approvals.push(updatedApproval);
        status.push(updatedStatus);
        evidenceInPortfolio += itemInfo?.evidenceInPortfolio ?? 0;
        evidenceAssigned += itemInfo?.evidenceAssigned ?? 0;
    }
    return [ratings, scores, approvals, status, evidenceInPortfolio, evidenceAssigned];
}

// build results arrays for a specific result, used by histogram
const getStudentsResults = (stateData, studentList, resultID, useTriple = false, parentID = 0) => {
    let ratings = [], scores = [], approvals = [], status = [];
    let evidenceInPortfolio = 0, evidenceAssigned = 0;
    const useEvidence = isEvidenceID(resultID);
    for (let i = 0; i < studentList.length; i++) {
        const student = studentList[i];
        const itemInfo = useEvidence ? getEvidenceByID(student.evidence, resultID) : getSkillByID(student.skills, resultID);
        if (!itemInfo) continue;

        // update grade based on teacher edits
        const itemID = useTriple ? getItem3ID(student.ID, parentID, itemInfo.ID) : getItemID(student.ID, itemInfo.ID);
        if (!itemID) {
            ratings.push(0);
            scores.push(0);
            approvals.push(0);
            status.push(0);
            continue;
        }
        const [updatedRating, updatedScore, updatedApproval, updatedStatus] = getUpdatedResults(stateData, itemID, itemInfo);
        ratings.push(updatedRating);
        scores.push(updatedScore);
        approvals.push(updatedApproval);
        status.push(updatedStatus);
        evidenceInPortfolio += itemInfo?.evidenceInPortfolio ?? 0;
        evidenceAssigned += itemInfo?.evidenceAssigned ?? 0;
    }
    return [ratings, scores, approvals, status, evidenceInPortfolio, evidenceAssigned];
}

const getAssignedName = (assignedState) => {
    switch (assignedState) {
        case Unassigned: return "Unassigned";
        case Active: return "Active";
        case Complete: return lookupLingo(TermInactive, true);
        case Extracurricular: return lookupLingo(TermExtracurricular, true);
        default:
            console.debug(`getAssignedName: invalid parameter: ${assignedState}`);
            return "";
    }
}

const getGroupName = (group) => {
    switch (group) {
        case PortfolioGroup: return lookupLingo(TermPortfolio, true, true);
        case ProjectsGroup: return lookupLingo(TermProject, true, true);
        case EvidenceGroup: return lookupLingo(TermEvidence, true, true);
        default:
            console.debug(`getGroupName: invalid parameter: ${group}`);
            return "";
    }
}

const getPageNameID = (pageID) => {
    // todo: not handled: dashboardTab, calendarTab, groupsTab, classExplorerTab, progressTab, trashTab,
    // consider: better to have an array index lookup if there is a robust way to do so (maybe there will be later)
    switch (pageID) {
        case messagesTab: return TermChat;
        case learnersTab: return TermLearner;
        case classesTab: return TermClass;
        case subjectsTab: return TermSubject;
        case projectsTab: return TermProject;
        case evidenceTab: return TermEvidence;
        case skillsTab: return TermSkill;
        case portfoliosTab: return TermPortfolio;
        default: return 0;
    }
}

// can also be used to get offsets for projectID
const getClassListOffset = (classList, classID) => {
    let index = 0;
    for (let i = 0; i < classList.length; i++) {
        let ID = classList[i].ID;
        if (ID === classID) {
            index++;
            // console.debug(`getClassListOffset: ${class}, index: ${index}`);
            return index;
        }
        if (!isStepID(ID))
            index++;
    }
    return 0;
}

const countSeletedItems = (stateData) => {
    const [selectedItems] = stateData.selectionState;
    const selectAllMode = handleToggle(stateData.selectionState, 0, getSelectionMode);
    const iterator = !selectAllMode ? selectedItems : stateData.selectionInfo.pageMap;

    // loop through selected items, skipping groups
    let count = 0;
    iterator?.forEach((item) => {
        const cellID = item.ID?.toString();
        if (cellID != null && (!selectAllMode || !item.group)) {
            if (handleToggle(stateData.selectionState, cellID, getSelectionState))
                count++;
        }
    });
    return count;
}

// todo: not finished, need to set other counts based on status (always use pageMap?)
const countSeletedItemsBreakdown = (stateData) => {
    const [selectedItems] = stateData.selectionState;
    const selectAllMode = handleToggle(stateData.selectionState, 0, getSelectionMode);
    const iterator = !selectAllMode ? selectedItems : stateData.selectionInfo.pageMap;

    // loop through selected items, skipping groups
    let count = 0, underReview = 0, submitted = 0, inProgress = 0, assigned = 0;
    iterator?.forEach((item) => {
        const cellID = item.ID?.toString();
        if (cellID != null && (!selectAllMode || !item.group)) {
            if (handleToggle(stateData.selectionState, cellID, getSelectionState)) {
                count++;
            }
        }
    });
    return [count, underReview, submitted, inProgress, assigned];
}

export {
    doFilteredSearch, averageValue, weightedAverage, getUpdatedResults, getUpdatedResultArray, getStudentResultsByClass, getStudentsResults, getAssignedName, getGroupName, getPageNameID,
    getClassListOffset, setAppState, setComponentState, updateComponentState, setDetailsPaneState, setUndoState, countSeletedItems, countSeletedItemsBreakdown, findByID,
    PortfolioGroup, ProjectsGroup, EvidenceGroup,
}
