import { changeContentPage } from "./App";
import { setUndoState } from "./utilities";
import { classesTab, projectsTab, evidenceTab, portfoliosTab } from "./utilitiesConstants";
import { saveState, setState, removeState } from "./utilitiesState";
import { lookupLingo, TermClass, TermEvidence, TermPortfolio, TermProject } from "./customizable";

const backStack = [];
const undoStack = [];
let redoState; // consider: could make redoStack for full undo/redo
let lastUndoMessage;

// current page's state is always at top of backStack, so Back pops stack and returns previous page's state
const handleBack = () => {
  const len = backStack.length;
  if (len < 2) return;

  backStack.pop(); // pop the top of backStack (which is current page)
  const backPageState = backStack[len - 2]; // now top of stack (after pop)
  if (backPageState) changeContentPage(0, backPageState);
};

const canGoBack = () => {
  return backStack.length > 1;
};

// consider: could make tooltip more elaborate--could give specific object name
// current page's state is always at top of backStack, so Back pops stack and returns previous page's state--so give that one in tooltip
const backContentPageTT = () => {
  const len = backStack.length;
  if (len < 2) return "Click to go back";

  // console.debug(`Back stack TT: length: ${backStack.length} componentType: ${backStack[len - 2]?.componentType}`);
  switch (backStack[len - 2].componentType) {
    case classesTab:
      return `Click to return to ${lookupLingo(TermClass, true, true)}`;
    case projectsTab:
      return `Click to return to ${lookupLingo(TermProject, true, true)}`;
    case evidenceTab:
      return `Click to return to ${lookupLingo(TermEvidence, true, true)}`;
    case portfoliosTab:
      return `Click to return to ${lookupLingo(TermPortfolio, true, true)}`;
    default:
      return "";
  }
};

// confirm: OK if oldValue is not defined?
const saveUndoState = (stateValue, objectID, hive, newValue, oldValue, tooltip) => {
  if (!stateValue || !objectID || isNaN(hive) || isNaN(newValue) || !tooltip) console.debug("saveUndoState: missing or invalid parameters");
  const len = undoStack.length;
  if (len > 0) {
    // ignore "change" if same as last change (all too easy in React)
    let lastUndo = undoStack[len - 1];
    if (lastUndo.stateValue === stateValue && lastUndo.objectID === objectID && lastUndo.hive === hive && lastUndo.oldValue === oldValue) return;
  }

  let undoState = setUndoState(stateValue, objectID, hive, newValue, oldValue, tooltip);
  undoStack.push(undoState);
  // console.debug(`saveUndo: stack size: ${undoStack.length}`);
};

const handleUndo = () => {
  const len = undoStack.length;
  if (len === 0) return false;
  const lastUndo = undoStack[len - 1];
  lastUndoMessage = lastUndo.tooltip;
  console.debug(`Undo message: ${lastUndoMessage}`);

  if (len === 1) {
    saveState(lastUndo.stateValue, lastUndo.objectID, lastUndo.hive, lastUndo.oldValue, removeState);
    redoState = undoStack.pop();
  } else {
    const undoState = undoStack.pop();
    saveState(undoState.stateValue, undoState.objectID, undoState.hive, undoState.oldValue, setState);
    redoState = undoState;
  }
  // console.debug(`handleUndo: stack size: ${undoStack.length}, newValue: ${lastUndo.newValue}, oldValue: ${lastUndo.oldValue}`);
  return true;
};

const handleRedo = () => {
  console.debug(`redo called`);
  if (redoState) {
    saveState(redoState.stateValue, redoState.objectID, redoState.hive, redoState.newValue, setState);
    saveUndoState(redoState.stateValue, redoState.objectID, redoState.hive, redoState.newValue, redoState.oldValue, redoState.tooltip);
    redoState = 0;
  }
};

// consider: if have ability to getObjectName from objectID, could add ` (from ${getObjectName(lastUndo.objectID)})` to tooltip
const getUndoTT = () => {
  const len = undoStack.length;
  if (len === 0) return "Undo last command";

  let lastUndo = undoStack[len - 1];
  if (lastUndo.tooltip) return lastUndo.tooltip;
  return "Undo last command";
};

const canUndo = () => {
  return undoStack.length > 0;
};

const getUndoMessage = () => lastUndoMessage;
const clearUndoMessage = () => (lastUndoMessage = null);

export { backStack, handleBack, canGoBack, backContentPageTT, handleUndo, handleRedo, saveUndoState, getUndoTT, canUndo, getUndoMessage, clearUndoMessage };
