import { firstHive, lastHive } from "./utilitiesConstants";

const getSelectionState = 0,
  toggleSelectionState = 1,
  tickSelectionState = 2,
  clearSelectionState = 3,
  replaceSelectionState = 4,
  clearAllState = 5,
  selectAllState = 6,
  countSelected = 7,
  getSelectionMode = 8;
const setState = 1,
  getState = 2,
  removeState = 3;

// getting this code right was tricky. what doesn't work:
// Doesn't work 1: setSelectionState([...selectionState, { ID: nid, selected: true }]);
// Doesn't work 2: selectionState.push({ ID: nid, selected: true }); setSelectionState(selectionState);
// Works: selectionState.push({ ID: nid, selected: true }); setSelectionState([...selectionState]);
// problem is that state changes are asynchronous

const handleToggle = (toggleState, nid, action) => {
  // maintain an array of state changes, plus a mode. The default mode is selectNone or selectAll and the remainder of the array is exceptions to the default.
  // so if selectNone and an item is in the array with selected===true, it's selected; if selectAll and item is found with selected===true, it's not selected
  // this code might be hard to understand at first, but think through the selectNone case and realize that selectAll case is the exact opposite/mirror image
  // assume IDs are unique, will not work correctly if there are dups. To facilitate, use low IDs for UI elements, IDs > 100 for data

  // make sure nid is a string, not a number
  if (action === getSelectionState || action === toggleSelectionState || action === tickSelectionState || action === clearSelectionState || action === replaceSelectionState) {
    if (typeof nid !== "string" && nid != null) {
      nid = nid?.toString();
    }
  }

  const [selectionState, setSelectionState] = toggleState;

  // lookup item based on nid
  const foundItem = nid != null ? selectionState.find((item) => item.ID === nid) : null; // if nid === 0, don't bother to look up

  // determine default mode
  const selectNoneMode = selectionState.length === 0 || !selectionState[0]?.mode;

  // console.debug(`SelectionState: ${selectionState}, length: ${selectionState.length}, selectNoneMode: ${selectNoneMode}`);

  switch (action) {
    case toggleSelectionState:
      if (!foundItem) {
        selectionState.push({ ID: nid, selected: true });
        setSelectionState([...selectionState]);
        return selectNoneMode; // so, we toggle and item not found: if selectNoneMode mode then it's found, if !selectNoneMode mode then it's now not found
      }
      const currentState = foundItem.selected;
      foundItem.selected = !currentState;
      setSelectionState([...selectionState]);
      // console.debug(`State toggle: nid: ${nid}, selectionState: ${JSON.stringify(selectionState)}`);
      return selectNoneMode ? !currentState : currentState;
    case replaceSelectionState:
      // console.debug(`Replace state nid: ${nid}, selectionState: ${JSON.stringify(selectionState)}`);
      selectionState.length = 0;
      selectionState.push({ ID: nid, selected: true });
      setSelectionState([...selectionState]);
      return selectNoneMode;
    case tickSelectionState:
      if (!foundItem) {
        if (selectNoneMode) {
          selectionState.push({ ID: nid, selected: true });
          setSelectionState([...selectionState]);
        }
        return selectNoneMode;
      }
      foundItem.selected = selectNoneMode;
      setSelectionState([...selectionState]);
      // console.debug(`Set state nid: ${nid}, selectionState: ${JSON.stringify(selectionState)}`);
      return selectNoneMode;
    case clearSelectionState:
      if (!foundItem) {
        if (!selectNoneMode) {
          selectionState.push({ ID: nid, selected: true });
          setSelectionState([...selectionState]);
        }
        return !selectNoneMode;
      }
      foundItem.selected = !selectNoneMode;
      setSelectionState([...selectionState]);
      // console.debug(`Clear state nid: ${nid}, selectionState: ${JSON.stringify(selectionState)}`);
      return !selectNoneMode;
    case clearAllState:
      // console.table(selectionState);
      selectionState.length = 0;
      selectionState.push({ mode: 0 });
      setSelectionState([...selectionState]);
      return;
    case selectAllState:
      selectionState.length = 0;
      selectionState.push({ mode: 1 });
      setSelectionState([...selectionState]);
      return;
    case getSelectionMode:
      return !selectNoneMode;
    case countSelected:
      // const selectedFound = selectionState.reduce((currentTotal, item) => currentTotal + (item.selected) ? 1 : 0, 0);
      // note: if !selectNoneMode, value returned is number not selected, so selected == totalNuumber - countSelected. Caller will need to adjust since total number of items is unknown here.
      const selectedFound = selectionState.reduce((currentTotal, item) => currentTotal + (item?.selected ?? 0), 0);
      return selectedFound;
    case getSelectionState:
      if (!foundItem) {
        // console.debug(`State status: nid: ${nid}, foundItem: ${foundItem}, value: ${false}`);
        return !selectNoneMode;
      }
      // console.debug(`State status: nid: ${nid}, foundItem: ${foundItem}, value: ${foundItem.selected}`);
      return selectNoneMode ? foundItem.selected : !foundItem.selected;
    default:
      return;
  }
};

// for saving any value, such as changed ratings, scores, grades, approvals
const saveState = (savedState, nid, type, value, action) => {
  // maintain an array of state. if nid is not in array, assume state is 0 or original value
  // assume IDs are unique, will not work correctly if there are dups
  // to facilitate, use low IDs for UI elements, IDs > 100 for data

  // make sure nid is a string, not a number
  if (typeof nid !== "string" && nid != null) {
    nid = nid?.toString();
  }

  const [theState, setTheState] = savedState;
  // console.debug(`SaveState: theState: ${theState}, setTheState: ${setTheState)`;
  if (type < firstHive || type > lastHive) console.debug(`saveState invalid type: ID: ${nid} type: ${type}`);

  // lookup item based on nid
  const foundItem = theState.find((item) => item.ID === nid && item.type === type);

  switch (action) {
    case setState:
      if (!foundItem) {
        theState.push({ ID: nid, type: type, value: value });
        setTheState(theState);
        return;
      }
      foundItem.value = value;
      setTheState([...theState]);
      // console.debug(`Set state nid: ${nid}, selectionState: ${JSON.stringify(selectionState)}`);
      return;
    case removeState:
      const newState = [...theState];
      const item = newState.findIndex((item) => item.ID === nid && item.type === type);
      if (item >= 0) {
        newState.splice(item, 1);
        setTheState([...newState]);
      } else setTheState([...theState]); // set state to force update
      return;
    case getState:
      if (!foundItem) {
        return null;
      }
      // console.debug(`State status: nid: ${nid}, foundItem: ${foundItem}, value: ${foundItem.selected}`);
      return foundItem.value;
    default:
      console.debug(`saveState invalid action: ID: ${nid} type: ${type}`);
      break;
  }
};

export { handleToggle, getSelectionState, toggleSelectionState, tickSelectionState, clearSelectionState, replaceSelectionState, clearAllState, selectAllState, countSelected, getSelectionMode, saveState, setState, getState, removeState };
