import React, { useState } from "react";
import { liftTheme, changeDetailsView, signOut } from "./App";
import { placeholder, classesTab, learnersTab, projectsTab, viewHelp } from "./utilitiesConstants"
import { globalHandleClick } from "./utilitiesEvents"
import { LiftOptionsDialog } from "./utilitiesOptionsDialogs"
import { handleBack, canGoBack, backContentPageTT, handleUndo, handleRedo, getUndoTT, canUndo, getUndoMessage, clearUndoMessage } from "./utilitiesBackUndo"
import { lookupLingo, TermClass, TermProject, TermLearner } from "./customizable";
import { isClassID, isProjectID, isStudentID } from "./data"
import logo from "./assets/LiFT-white.png";
import { AppBar, Toolbar, Button, IconButton, Link, Typography, Tooltip, Container, Menu, MenuItem, Divider, Snackbar, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// bug: resize transitions don't work for some reason, review

// use makeStyles to map component element styles to LiFT theme plus other non-theme styles
// orchestration: small elements first (shorter), large elements like logo and teacher name second (short), overall bar height last (standard)
// not especially obvious if react is respecting the timings
const useStyles = makeStyles((theme) => ({
    appBarStyle: {
        display: "flex",
        justifyContent: "flex-start",
        height: 107,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflow: "hidden",
        userSelect: "none",
        backgroundColor: theme.palette.backgrounds.titleBar.main,
        transition: "height " + theme.transitions.duration.standard + "ms ease,",
    },
    appBarStyleSmall: {
        height: 64,
        paddingTop: 2,
        paddingBottom: 2,
    },
    titleBarRHSStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.down(1280)]: { width: 200 },
        transition: "height " + theme.transitions.duration.shorter + "ms ease"
    },
    iconStyle: {
        color: theme.palette.icons.titleBar.main,
        fontSize: theme.sizing.icons.titleBar,
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease"
    },
    iconStyleSmall: {
        color: theme.palette.icons.titleBar.main,
        fontSize: theme.sizing.icons.titleBarSmall,
    },
    iconStyle2: {
        color: theme.palette.icons.titleBar.main,
        fontSize: theme.sizing.icons.titleBar2,
    },
    iconStyle2Small: {
        color: theme.palette.icons.titleBar.main,
        fontSize: theme.sizing.icons.titleBar2Small,
    },
    iconStyleDisabled: {
        color: theme.palette.icons.titleBarDisabled.main,
        fontSize: theme.sizing.icons.titleBar,
    },
    iconStyleDisabledSmall: {
        color: theme.palette.icons.titleBarDisabled.main,
        fontSize: theme.sizing.icons.titleBarSmall,
    },
    iconStyleDisabled2: {
        color: theme.palette.icons.titleBarDisabled.main,
        fontSize: theme.sizing.icons.titleBar2,
    },
    iconStyleDisabled2Small: {
        color: theme.palette.icons.titleBarDisabled.main,
        fontSize: theme.sizing.icons.titleBar2Small,
    },
    logoStyle: {
        width: 127,
        height: 75,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(4),
        transition: "height " + theme.transitions.duration.short + "ms ease"
    },
    logoStyleSmall: {
        marginTop: -4,      // hack: check logo image padding at top
        height: 40,
    },
    teacherNameStyle: {
        marginLeft: theme.spacing(2),
        minWidth: 200,
        maxWidth: 800,
        width: 800,
        lineHeight: "95%",
        fontWeight: "bold",
        transition: "font-size " + theme.transitions.duration.short + "ms ease",
        fontSize: theme.sizing.text.titleBarTeacherName,
        [theme.breakpoints.down(1825)]: { fontSize: theme.sizing.text.titleBarTeacherName2, },
        [theme.breakpoints.down(960)]: { fontSize: theme.sizing.text.titleBarTeacherName3, },
        [theme.breakpoints.down(850)]: { display: "none" },
    },
    dropListStyle: {
        fontSize: theme.sizing.text.titleBarList,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,

        margin: theme.spacing(2),
        width: 360,
        height: 40,
        [theme.breakpoints.down(1650)]: { width: 300 },
        [theme.breakpoints.down(1500)]: { width: 260 },
        [theme.breakpoints.down(1400)]: { width: 200 },
        [theme.breakpoints.down(1280)]: { width: 0, display: "none" },
        transition: "width " + theme.transitions.duration.shorter + "ms ease"
    },
    dropListStyleSmall: {
        height: 30,
    },
    signoutStyle: {
        fontSize: theme.sizing.text.titleBarText,
        textDecoration: "none",
        margin: theme.spacing(1),
        width: 100,
        lineHeight: "95%",
        textAlign: "center",
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease"
    },
    notificationStyle: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.backgrounds.headerBar.main
    },
}));

const getStudentInfo = studentObj => {
    if (!studentObj) console.debug("getStudentInfo: missing or invalid parameters");

    const keyID = studentObj.ID.toString();

    if (studentObj.reviewsRequested && studentObj.commentsUnresolved)
        return <option key={keyID} value={studentObj.ID.toString()}>{studentObj.name}&nbsp;&#x2691;&nbsp;&#x263B;&nbsp;</option>;
    else if (studentObj.reviewsRequested)
        return <option key={keyID} value={studentObj.ID.toString()}>{studentObj.name}&nbsp;&#x2691;&nbsp;</option>;
    else if (studentObj.commentsUnresolved)
        return <option key={keyID} value={studentObj.ID.toString()}>{studentObj.name}&nbsp;&#x263B;&nbsp;</option>;
    else
        return <option key={keyID} value={studentObj.ID.toString()}>{studentObj.name}</option>;
};

const getClassInfo = classObj => {
    if (!classObj) console.debug("getClassInfo: missing or invalid parameters");

    const keyID = classObj.ID.toString();

    if (classObj.name)
        return <option key={keyID} value={classObj.ID}>{classObj.name}</option>;
    else if (classObj.projectName)
        return <option key={keyID} value={classObj.ID}>&nbsp;&nbsp;&nbsp;&nbsp;{classObj.projectName}</option>;
};

const TitleBar = ({ teacherName, classData, appStateData, darkState, ...props }) => {
    if (!teacherName || !classData || !appStateData || !darkState) console.debug(`TitleBar: missing or invalid parameters: teacherName: ${teacherName}, classData: ${classData}, appState: ${appStateData}, darkState: ${darkState}`);
    const classes = useStyles(liftTheme);

    // state hooks--the one source of truth
    const [smallMode] = appStateData.smallHeaderState;
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    if (!classData || !appStateData) return <></>;

    // click handlers
    const openOptionsDialog = () => {
        setAnchorEl(null);      // close menu in case one was open
        setDialogOpen(true);
    };
    const closeOptionsDialog = () => {
        setDialogOpen(false);
    };
    const handleMenuClose = () => { setAnchorEl(null); }
    const handleNotificationClose = () => { clearUndoMessage(); setShowNotification(false); }
    const handleClick = (ev, objectID, otherID) => {
        // console.debug(`TitleBar handleClick, Ev value: ${ev.target}, typeof: ${typeof ev.target}, objectID: ${objectID}, otherID: ${otherID}`);

        // remap target.value to more normal handleClick parameters
        const commandID = Number(ev.target.value);
        let cmdID = commandID;
        if (isClassID(commandID)) {
            cmdID = "link:" + classesTab.toString()
        } else if (isStudentID(commandID)) {
            cmdID = "link:" + learnersTab.toString()
        } else if (isProjectID(commandID)) {
            cmdID = "link:" + projectsTab.toString()
        }
        // console.debug(`TitleBar remapped commands: cmdID: ${cmdID}, otherID: ${commandID}`);
        if (globalHandleClick(ev, cmdID, commandID)) return;

        // local click handling (use placeholder for "Jump to...")
        if (objectID !== 0 && commandID !== placeholder)
            setAnchorEl(ev.currentTarget);      // must be a menu
    }

    // if there is an undo message, change the state to update
    if (!showNotification && getUndoMessage()) {
        setShowNotification(true);
    }

    // set dynamic button styles (based on state, smallMode)
    // consider: use classes instead of style (as done in content panes)
    let hmStyle, backButtonStyle, undoButtonStyle;
    if (smallMode) {
        hmStyle = classes.iconStyleSmall;
        if (canGoBack())
            backButtonStyle = classes.iconStyleSmall;
        else
            backButtonStyle = classes.iconStyleDisabledSmall;
        if (canUndo())
            undoButtonStyle = classes.iconStyle2Small;
        else
            undoButtonStyle = classes.iconStyleDisabled2Small;
    } else {
        hmStyle = classes.iconStyle;
        if (canGoBack())
            backButtonStyle = classes.iconStyle;
        else
            backButtonStyle = classes.iconStyleDisabled;
        if (canUndo())
            undoButtonStyle = classes.iconStyle2;
        else
            undoButtonStyle = classes.iconStyleDisabled2;
    }

    const redo = <Button color="primary" size="small" onClick={handleRedo}>Redo</Button>;

    return (
        <AppBar key="tb" className={smallMode ? classes.appBarStyle + " " + classes.appBarStyleSmall : classes.appBarStyle}>
            <Toolbar>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={showNotification} onClose={handleNotificationClose} autoHideDuration={1500}>
                    <SnackbarContent className={classes.notificationStyle} message={getUndoMessage()} action={redo} />
                </Snackbar>
                <img src={logo} alt="LiFT Learning" className={smallMode ? classes.logoStyle + " " + classes.logoStyleSmall : classes.logoStyle} />
                <Typography className={classes.teacherNameStyle}>{teacherName}</Typography>
                <Container className={classes.titleBarRHSStyle}>
                    <select name="titleBarClassPicker" id="titleBarClassPicker" ref={appStateData.projectListRef} defaultValue="0"
                         className={smallMode ? classes.dropListStyle + " " + classes.dropListStyleSmall : classes.dropListStyle} aria-label="Select Term">
                         <option key="jumpProject" value="na">Fall Term</option>
                         <option key="jumpProject" value="na">Spring Term (Archived)</option>
                     </select>
                    <Tooltip title="LiFT help"><IconButton className={classes.iconStyle2} onClick={() => changeDetailsView(viewHelp)} aria-label="LiFT help"><FontAwesomeIcon icon="question-circle" /></IconButton></Tooltip>
                    <Tooltip title="Settings"><IconButton className={hmStyle} onClick={openOptionsDialog} aria-label="Settings"><FontAwesomeIcon icon="cog" /></IconButton></Tooltip>
                    <Tooltip title="Sign out"><IconButton className={hmStyle} onClick={() => { handleMenuClose(); signOut(appStateData.signInState); }} aria-label="Sign out"><FontAwesomeIcon icon="sign-out-alt" /></IconButton></Tooltip>
                </Container>
                <LiftOptionsDialog appStateData={appStateData} darkState={darkState} smallHeaderState={appStateData.smallHeaderState} autoCloseState={appStateData.autoCloseState}
                    autoOpenPortfoliosState={appStateData.autoOpenPortfoliosState} open={isDialogOpen} handleOnClose={closeOptionsDialog} />
            </Toolbar>
        </AppBar>
    );
}

export default TitleBar;
