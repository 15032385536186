import React from "react"
import { liftTheme, liftColorSet } from "./App"
import { Typography, Tooltip, MenuItem, IconButton, Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { CircleOutline, CircleSlice2, CircleSlice4, CircleSlice6, CircleSlice8 } from 'mdi-material-ui'
import { buildColorSet, mutedColor } from "./utilitiesColor"
import { maxScoreLevels, maxRatingLevels, maxStatusLevels, maxProgressLevels, maxApprovalLevels } from "./utilitiesConstants.js"
import { rd, dateText } from "./utilitiesResponsiveData"
import { Active, Extracurricular } from "./data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MoreVertIcon from '@material-ui/icons/MoreVert';

// functions and components for customizable text, colors, icons go here
// consider: split out customColors.js, customText.js, customIcons.js?

// while these status constants are customizable, they are used throughout LiFT and therefore need to be exposted outside this module. 
// to customize without problem; 1) these core status values are optional and don't have to be used. 2) other status values can be used, but should be assigned values outside this range (recommend starting with 10)
// so, assuming the values below are immutable for customized code
// todo: based on all this, move to constants.js
const statusLocked = -1, statusNotAssigned = 0, statusNotStarted = 1, statusInProgress = 2, statusSubmitted = 3, statusReviewRequested = 4,
    statusUnderReview = 5, statusRevisionRequested = 6, statusCompleted = 7, statusInPortfolio = 8;

const highestRating = 5, lowestRating = 1, highestGrade = 100, lowestGrade = 0;

const TermApproval = 1,
    TermApprove = 2,
    TermApproved = 3,
    TermChat = 4,
    TermChild = 5,
    TermClass = 6,
    TermComplete = 29,
    TermCompleted = 7,
    TermEvidence = 8,
    TermExtracurricular = 9,
    TermFramework = 10,
    TermGrade = 11,
    TermGradebook = 12,
    TermGroup = 13,
    TermInactive = 14,
    TermLearner = 15,
    TermNote = 16,
    TermParent = 17,
    TermPortfolio = 18,
    TermProject = 19,
    TermRate = 20,
    TermRating = 21,
    TermRubric = 22,
    TermScore = 23,
    TermSkill = 24,
    TermStatus = 25,
    TermStep = 26,
    TermSubject = 27,
    TermTerm = 28;

// use makeStyles to map component element styles to LiFT theme plus other non-theme styles
const useStyles = makeStyles(theme => ({
    ratingStyle: {
        fontSize: theme.sizing.text.level2Text,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.background.paper,
        marginLeft: 0,
        paddingLeft: 0,
        "&:focus": {    // don't flip in combobox
            backgroundColor: theme.palette.common.white,
        },
    },
    ratingStyleCell: {
        fontSize: theme.sizing.text.level2Text,
        color: theme.palette.common.white,
        backgroundColor: "transparent",
        "&:focus": {    // flip in combobox
            color: theme.palette.common.black,
            backgroundColor: theme.palette.common.white,
        },
    },
    ratingStackTable: {
        tableLayout: "fixed",
        width: "100%",
        marginRight: theme.spacing(4),
    },
    ratingStackRow: {
        margin: 0,
        padding: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    ratingStackCell: {
        fontSize: theme.sizing.text.level2Text,
        margin: 0,
        padding: 1,
        paddingRight: theme.spacing(1),
        lineHeight: "100%",
        border: "none",
        zIndex: theme.zIndex.appBar,
    },
    ratingStackCell2: {
        fontSize: theme.sizing.text.smallText,
        margin: 0,
        padding: 1,
        paddingRight: theme.spacing(1),
        lineHeight: "100%",
        border: "none",
        zIndex: theme.zIndex.appBar,
    },
    tooltipHeading: {
        fontSize: theme.sizing.text.level1Text,
        fontWeight: "bold"
    },
    tooltipText: {
        fontSize: theme.sizing.text.dataText,
    },
}));

//
// custom color schemes
//

const buildColorInfo = () => {
    return [{ sat: -1, lum: 32 },
    { low: "#05b54e", high: "#1c41b5", levels: maxScoreLevels },
    { low: "#05b54e", high: "#1c41b5", levels: maxRatingLevels },
    { low: "#05b54e", high: "#1c41b5", levels: maxStatusLevels },
    { low: "#05b54e", high: "#1c41b5", levels: maxProgressLevels },
    { low: "#05b54e", high: "#efeff0", levels: maxApprovalLevels }];
}

const buildCustomColors = (colorInfo) => {
    const sat = colorInfo[0].sat;
    const lum = colorInfo[0].lum;

    const scoreColors = buildColorSet(colorInfo[1].low, colorInfo[1].high, colorInfo[1].levels);
    const ratingColors = buildColorSet(colorInfo[2].low, colorInfo[2].high, colorInfo[2].levels);
    const mutedRatingColors = buildColorSet(mutedColor(colorInfo[2].low, sat, lum), mutedColor(colorInfo[2].high, sat, lum), colorInfo[2].levels);
    const statusColors = buildColorSet(colorInfo[3].low, colorInfo[3].high, colorInfo[3].levels);
    const progressColors = buildColorSet(colorInfo[4].low, colorInfo[4].high, colorInfo[4].levels);
    const approvedColors = buildColorSet(colorInfo[5].low, colorInfo[5].high, colorInfo[5].levels);

    return {
        scoreColors: scoreColors,
        ratingColors: ratingColors,
        mutedRatingColors: mutedRatingColors,
        statusColors: statusColors,
        progressColors: progressColors,
        approvedColors: approvedColors,
    };
}

// access functions to reduce coupling
const getScoreColors = () => liftColorSet.scoreColors;
const getRatingColors = () => liftColorSet.ratingColors;
const getMutedRatingColors = () => liftColorSet.mutedRatingColors;
const getStatusColors = () => liftColorSet.statusColors;
const getProgressColors = () => liftColorSet.progressColors;

const getScoreColor = (score) => {
    if (score >= 1 && score <= maxScoreLevels)
        return liftColorSet.scoreColors[score - 1];
    return "#ffffff";   // fallback color
}

const getRatingColor = (rating) => {
    if (rating >= 1 && rating <= maxRatingLevels)
        return liftColorSet.ratingColors[rating - 1];
    return "#ffffff";   // fallback color
}

const getMutedRatingColor = (rating) => {
    if (rating >= 1 && rating <= maxRatingLevels)
        return liftColorSet.mutedRatingColors[rating - 1];
    return "#ffffff";   // fallback color
}

const getStatusColor = (status) => {
    if (status >= 1 && status <= maxStatusLevels)
        return liftColorSet.statusColors[status - 1];
    return "#ffffff";   // fallback color
}

const getProgressColor = (progress) => {
    if (progress >= 1 && progress <= maxProgressLevels)
        return liftColorSet.progressColors[progress - 1];
    return "#ffffff";   // fallback color
}

const getApprovedColor = (background) => {
    if (background >= 0 && background < 2)
        return liftColorSet.approvedColors[background];
    return "#ffffff";   // fallback color
}

const getRatingFromScore = (score) => {
    if (score >= 90) return 4;
    if (score >= 80) return 3;
    if (score >= 70) return 2;
    if (score >= 60) return 1;
    return 1;
}

// 
// custom rating, status, grade, etc. components
//

// bug: rendering the dll is a performance hit? Is there a way to replace static text with a DDL on click?
// consider: is the customization just language, colors, and levels? if so, can pull all that out and move this to utilityComponents
// still used by gradebook and portfolio tab
const Rating = React.forwardRef(function Rating({ rating, score = null, approved = false, editMode = false, handleClick = null, itemID, showApproved = false, approvalInfo = null, cellStyle = false, highestRating = 4, ...props }, ref) {
    if (isNaN(rating)) console.debug("Rating: missing or invalid parameters");
    const classes = useStyles(liftTheme);

    // get scoreLabel
    const scoreLabel = (score) ? `(${score})` : "";
    const ratingClass = (cellStyle) ? classes.ratingStyleCell : classes.ratingStyle;
    const ratingStyle = (cellStyle) ? { borderStyle: "none", width: 150, fontWeight: 500 } : { borderStyle: "none", width: 150, color: getRatingColor(rating), fontWeight: 500 };

    const levels = [];
    for (let i = 1; i <= highestRating; i++) levels.push(i);

    let ratingSetting;
    if (editMode && handleClick) {
        ratingSetting = <select name="editRating" id="editRating" value={rating.toString()} onChange={ev => { handleClick(ev, itemID); handleClick(ev, "updateRubric", itemID); }}
            onClick={ev => { handleClick(ev, "updateRubric", itemID) }} className={ratingClass} style={ratingStyle}>
            {levels.map(level => <option key={level.toString()} value={level.toString()} style={{ color: getRatingColor(level) }}>{getRatingLabel(level)}</option>)}
        </select >
    } else {
        ratingSetting = getRatingLabel(rating);
    }

    // todo: use span or margin instead of nbsp?
    if (showApproved)
        return <span ref={ref} className={ratingClass} aria-label="skill rating"><ApprovalIcon approved={approved} showTooltip={true} approvalInfo={approvalInfo} cellStyle={cellStyle} />&nbsp;&nbsp;&nbsp;{ratingSetting} {scoreLabel}</span>;     // tooltip must be around a span
    return <span ref={ref} className={ratingClass} aria-label="skill rating">{ratingSetting} {scoreLabel}</span>;
})

// try without forwardRef, confirm that is really necessary--don't need without a tooltip, right?
// todo: optional tooltip to give full skill description or specific rubric (useful only if rubrics aren't displayed)
// todo: add approved, showApproved, approvalInfo--don't think this one makes sense with cellStyle because of selection
const Ratings = ({ skillNames, skillIDs, skillRatings, skillScores, editMode = false, handleClick = null, highestRating = 4, ...props }) => {
    if (!skillNames || !skillIDs || !skillRatings || !skillScores) console.debug("Ratings: missing or invalid parameters");
    const classes = useStyles(liftTheme);

    // sanity check: length of all arrays should be same, ow return (if not same could work but something is wrong with the data)
    const ratingCount = skillNames.length;
    if (ratingCount === 0)
        return <></>;
    if (ratingCount !== skillIDs.length || ratingCount !== skillRatings.length || ratingCount !== skillScores.length) {
        console.debug("Ratings: inconsistent array parameter lengths");
        return <></>;
    }

    // set style, use smaller font if more than 2
    const cellStyle = (ratingCount > 2) ? classes.ratingStackCell2 : classes.ratingStackCell;

    // if more than 3, have a more button
    let moreButton;
    // if (ratingCount > 3)
    //     moreButton = <div><IconButton aria-label="command menu" aria-haspopup={true} onClick={ev => handleClick(ev)}><MoreVertIcon /></IconButton></div>;

    // build skillStack, cap at 3
    if (!editMode) {
        const skillStack = <Table size="small" className={classes.ratingStackTable}>
            <colgroup><col style={{ width: 100 }} /><col style={{ width: 100 }} /><col style={{ width: 20 }} /></colgroup>
            <TableBody>
                {skillRatings.map((rating, i) => (i < 3) ? <TableRow key={skillIDs[i]} size="small" className={classes.ratingStackRow} aria-label="evidence skill rating">
                    <TableCell size="small" className={cellStyle} align="right" >{skillNames[i]}</TableCell>
                    <TableCell size="small" className={cellStyle} style={{ color: getRatingColor(rating), fontWeight: 500 }}>{getRatingLabel(rating)}</TableCell>
                    <TableCell size="small" className={cellStyle}>{skillScores[i] ? `(${skillScores[i]})` : ""}</TableCell></TableRow> : <></>)}</TableBody></Table>;
        return <>{skillStack}{moreButton}</>;
    } else {
        const levels = [];
        for (let i = 1; i <= highestRating; i++) levels.push(i);

        const skillStack = <Table size="small" className={classes.ratingStackTable}>
            <colgroup><col style={{ width: 100 }} /><col style={{ width: 100 }} /><col style={{ width: 20 }} /></colgroup>
            <TableBody>
                {skillRatings.map((rating, i) => (i < 3) ? <TableRow key={skillIDs[i]} size="small" className={classes.ratingStackRow} aria-label="evidence skill rating">
                    <TableCell size="small" className={cellStyle} align="right" >{skillNames[i]}</TableCell>
                    <TableCell size="small" className={cellStyle}>
                        <select name="editRating" id="editRating" value={rating.toString()} style={{ borderStyle: "none" }} onChange={ev => { handleClick(ev, skillIDs[i]); handleClick(ev, "updateRubric", skillIDs[i]) }} onClick={ev => { handleClick(ev, "updateRubric", skillIDs[i]) }}
                            className={cellStyle} style={{ borderStyle: "none", color: getRatingColor(rating), fontWeight: 500, width: 150 }}>
                            {levels.map(level => <option key={level.toString()} value={level.toString()} style={{ color: getRatingColor(level) }}>{getRatingLabel(level)}</option>)}
                        </select >
                    </TableCell>
                    <TableCell size="small" className={cellStyle}>{skillScores[i] ? `(${skillScores[i]})` : ""}</TableCell></TableRow> : <></>)}</TableBody></Table>;
        return <>{skillStack}{moreButton}</>;
    }
}

// todo: use this above for label text, should have only copy of these labels
// todo: need React.forwardRef ?
const RatingLabel = ({ rating, score = null, policyScore = null, cellStyle = false, handleClick = null, itemID = 0, ...props }) => {
    if (isNaN(rating)) console.debug(`RatingLabel: missing or invalid parameters: ${rating}`);

    // get the ratingLabel
    const ratingLabel = getRatingLabel(rating);

    // the style
    const ratingStyle = (cellStyle) ? { fontWeight: 400 } : { color: getRatingColor(rating), fontWeight: 400 };

    // get the scoreLabel
    let scoreLabel;
    if (!policyScore)
        scoreLabel = (score) ? `(${score})` : "";
    else if (score)
        scoreLabel = `(${score}/${policyScore})`;

    if (handleClick && itemID)
        return <span style={ratingStyle} onClick={ev => { handleClick(ev, "updateRubric", itemID) }}>{ratingLabel} {scoreLabel}</span>;      // todo: use a theme value
    else
        return <span style={ratingStyle}>{ratingLabel} {scoreLabel}</span>;      // todo: use a theme value
}

const RatingMenu = React.forwardRef(function RatingMenu({ handleClick, handleClose, useGroup = false, ...props }, ref) {
    const command = useGroup ? "groupRubric:" : "rubric:";

    const levels = [];
    for (let i = 1; i <= highestRating; i++) levels.push(i);

    return <div ref={ref}>
        {levels.map(level => <MenuItem key={level.toString()} onClick={ev => { handleClose(); handleClick(ev, command + "1"); }} >{getRatingLabel(level)}</MenuItem>)}
    </div>;
})

// got a runtime error about tooltip so wrapped in a forward-ref which made no difference. Postpone fixing because no longer using this tooltip anyway.
const StatusIcon = React.forwardRef(function StatusIcon({ status, showTooltip = false, cellStyle = true, fontSize = 30, ...props }, ref) {
    if (isNaN(status)) {
        console.debug("StatusIcon: missing or invalid parameters");
    }
    const [icon, iconColor, tt] = getStatusIconInfo(status, cellStyle);
    if (showTooltip)
        return <Tooltip title={tt} enterDelay={2000}><span ref={ref}><FontAwesomeIcon icon={icon} style={{ color: iconColor, fontSize: fontSize }} /></span></Tooltip>;
    else
        return <FontAwesomeIcon icon={icon} style={{ color: iconColor, fontSize: fontSize }} />;
})

// todo: replaced with statusIcon, can delete
const CompletionIcon = ({ status, assignedState = Active, showTooltip = true, cellStyle = false, fontSize = 26, ...props }) => {
    if (isNaN(status)) {
        console.debug("CompletionIcon: missing or invalid parameters");
    }
    // console.debug(`StatusIcon: status: ${status}, levels: ${levels}, assignedState: ${assignedState}`);
    const levels = maxStatusLevels;   // not a variable, but implemented variations below to show how it could be done (only levels === 5 case fully implemented)

    // believe it or no, have to wrap icon in <i> tap to apply the style to center
    if (assignedState === Extracurricular) {
        return <Tooltip title={lookupLingo(TermExtracurricular, true)}><i style={{ verticalAlign: 'middle' }}><FontAwesomeIcon icon="plus-circle" style={{ color: getApprovedColor(cellStyle) }} /></i></Tooltip>;
    } else if (levels === 2) {
        switch (status) {
            case 1: return <Tooltip title="Not started"><i style={{ verticalAlign: 'middle' }}><CircleOutline style={{ color: getStatusColor(1) }} /></i></Tooltip>;
            case 2: return <Tooltip title="Complete"><i style={{ verticalAlign: 'middle' }}><CircleSlice8 style={{ color: getStatusColor(5) }} /></i></Tooltip>;
            default: return <></>;
        }
    }
    else if (levels === 3) {
        switch (status) {
            case 1: return <Tooltip title="Not started"><i style={{ verticalAlign: 'middle' }}><CircleOutline style={{ color: getStatusColor(1) }} /></i></Tooltip>;
            case 2: return <Tooltip title="Developing"><i style={{ verticalAlign: 'middle' }}><CircleSlice4 style={{ color: getStatusColor(3) }} /></i></Tooltip>;
            case 3: return <Tooltip title="Complete"><i style={{ verticalAlign: 'middle' }}><CircleSlice8 style={{ color: getStatusColor(5) }} /></i></Tooltip>;
            default: return <></>;
        }
    }
    else if (levels === 5) {
        const iconColor = (cellStyle) ? "#eeeeee" : getStatusColor(status);
        if (showTooltip) {
            switch (status) {
                case 1: return <Tooltip title="Not started"><i style={{ verticalAlign: 'middle' }}><CircleOutline style={{ color: iconColor, fontSize: fontSize }} /></i></Tooltip>;
                case 2: return <Tooltip title="Emerging"><i style={{ verticalAlign: 'middle' }}><CircleSlice2 style={{ color: iconColor, fontSize: fontSize }} /></i></Tooltip>;
                case 3: return <Tooltip title="Developing"><i style={{ verticalAlign: 'middle' }}><CircleSlice4 style={{ color: iconColor, fontSize: fontSize }} /></i></Tooltip>;
                case 4: return <Tooltip title="Mastery"><i style={{ verticalAlign: 'middle' }}><CircleSlice6 style={{ color: iconColor, fontSize: fontSize }} /></i></Tooltip>;
                case 5: return <Tooltip title="Complete"><i style={{ verticalAlign: 'middle' }}><CircleSlice8 style={{ color: iconColor, fontSize: fontSize }} /></i></Tooltip>;
                default: return <></>;
            }
        } else {
            switch (status) {
                case 1: return <i style={{ verticalAlign: 'middle' }}><CircleOutline style={{ color: iconColor, fontSize: fontSize }} /></i>;
                case 2: return <i style={{ verticalAlign: 'middle' }}><CircleSlice2 style={{ color: iconColor, fontSize: fontSize }} /></i>;
                case 3: return <i style={{ verticalAlign: 'middle' }}><CircleSlice4 style={{ color: iconColor, fontSize: fontSize }} /></i>;
                case 4: return <i style={{ verticalAlign: 'middle' }}><CircleSlice6 style={{ color: iconColor, fontSize: fontSize }} /></i>;
                case 5: return <i style={{ verticalAlign: 'middle' }}><CircleSlice8 style={{ color: iconColor, fontSize: fontSize }} /></i>;
                default: return <></>;
            }
        }
    } else
        return <></>;
}

// consider: not really customizable, move to utilityComponents?
// todo: not recording approvalTeacher, approvedClass, approvedDate, will need to replace approvalInfo with completely new object with this info
const ApprovalIcon = ({ approved, showTooltip = false, approvalInfo = null, cellStyle = false, ...props }) => {
    if (isNaN(approved)) console.debug("ApprovalIcon: missing or invalid parameters");
    const classes = useStyles(liftTheme);

    if (!approved)
        return <span style={{ marginLeft: 20 }}> </span>;   // so that unapproved columns line up with approved

    if (showTooltip && approvalInfo) {
        // note: while used within tooltips, returned elements are all mui components, so don't need forwareRef wrapper here
        let tt = <span><Typography className={classes.tooltipHeading}>Approved by: {approvalInfo.approvedTeacher}</Typography>
            <Typography className={classes.tooltipText}>{approvalInfo.approvedClass}</Typography>
            <Typography className={classes.tooltipText}>{rd(approvalInfo.approvedDate, dateText, false)}</Typography></span>;
        return <Tooltip title={tt}><span><FontAwesomeIcon icon="check" style={{ color: getApprovedColor(cellStyle) }} /></span></Tooltip>;
    }
    return <FontAwesomeIcon icon="check" style={{ color: getApprovedColor(cellStyle) }} />;
}

//
// custom text (mostly)
//

const getRatingsCount = () => { return 5 }

// alt names: Emerging, Developing, Proficient, Mastery, Exemplary
const getRatingLabel1 = (rating) => {
    let ratingLabel;
    switch (rating) {
        case 2: ratingLabel = "Developing"; break;
        case 3: ratingLabel = "Approaching"; break;
        case 4: ratingLabel = "C&C ready"; break;
        case 5: ratingLabel = "Advanced"; break;
        default:
        case 1: ratingLabel = "Entering"; break;
    }
    return ratingLabel;
}

// todo: need a way to make this data driven
const getRatingLabel = (rating) => {
    let ratingLabel;
    switch (rating) {
        case 2: ratingLabel = "Progressing"; break;
        case 3: ratingLabel = "Proficient"; break;
        case 4: ratingLabel = "Distinguished"; break;
        case 5: ratingLabel = "El Duderino"; break;
        default:
        case 1: ratingLabel = "Getting Started"; break;
    }
    return ratingLabel;
}

const getRatingLabel2 = (rating) => {
    let ratingLabel;
    switch (rating) {
        case 2: ratingLabel = "You can do better"; break;
        case 3: ratingLabel = "Good work"; break;
        case 4: ratingLabel = "Outstanding"; break;
        default:
        case 1: ratingLabel = "Incomplete"; break;
    }
    return ratingLabel;
}

const getStatusIconInfo = (status, cellStyle = true) => {
    let icon, iconColor, tt;

    // todo: put colors in theme
    if (status === statusLocked)
        iconColor = "#b78e38";
    else
        iconColor = (cellStyle) ? "#eeeeee" : getStatusColor(status);

    // using special for non-normal status, specifically anything not assigned or started
    switch (status) {
        case statusLocked: icon = "lock"; tt = "Locked"; break;
        case statusNotAssigned: icon = "ban"; tt = "Not assigned"; break;
        case statusNotStarted: icon = "asterisk"; tt = "Assigned but not started"; break;
        case statusInProgress: icon = "plus-circle"; tt = "In progress"; break;     // also, sign-in
        case statusSubmitted: icon = "flag"; tt = "Submitted"; break;
        case statusReviewRequested: icon = "exclamation"; tt = "Submitted for review"; break;
        case statusUnderReview: icon = "search"; tt = "Under review"; break;
        case statusRevisionRequested: icon = "exclamation-circle"; tt = "Revision requested"; break;
        case statusCompleted: icon = "check"; tt = lookupLingo(TermCompleted, true); break;
        case statusInPortfolio: icon = "briefcase"; tt = "In " + lookupLingo(TermPortfolio); break;
        default: break;
    }

    return [icon, iconColor, tt];
}

// return a list of ID, name pairs for all status. Intended to populate status filter list
const getStatusList = () => {
    let statusList = [];
    for (let i = statusInPortfolio; i >= statusLocked; i--) {
        const [, , name] = getStatusIconInfo(i);
        statusList.push({ ID: i, name: name });
    }
    return statusList;
}
const statusOptionsList = getStatusList(); // use variable to only render list once (since it's not changing)

function lookupLingo(wordID, useUppercase = false, usePlural = false) {
    let root, useEs = false;

    switch (wordID) {
        case TermApproval:
            root = "approval"; break;
        case TermApprove:
            root = "approve"; break;
        case TermApproved:
            root = "approved"; break;
        case TermChat:
            root = "message"; break;
        case TermChild:
            if (usePlural)
                root = "children";
            else
                root = "child";
            usePlural = false;
            break;
        case TermClass:
            useEs = true;
            root = "class"; break;
        case TermComplete:
            root = "complete"; break;
        case TermCompleted:
            root = "completed"; break;
        case TermEvidence:
            usePlural = false;
            root = "evidence"; break;
        // root = "deliverable"; break;
        case TermExtracurricular:
            root = "extracurricular"; break;
        case TermFramework:
            root = "framework"; break;
        case TermGrade:
            root = "grade"; break;
        case TermGradebook:
            root = "gradebook"; break;
        case TermGroup:
            root = "group"; break;
        case TermInactive:
            root = "complete"; break;
        case TermLearner:
            root = "learner"; break;
        case TermNote:
            root = "note"; break;
        case TermParent:
            root = "parent"; break;
        case TermPortfolio:
            root = "portfolio"; break;
        case TermProject:
            root = "project"; break;
        case TermRate:
            root = "rate"; break;
        case TermRating:
            root = "rating"; break;
        case TermRubric:
            root = "rubric"; break;
        case TermScore:
            root = "score"; break;
        case TermSkill:
            root = "skill"; break;
        case TermStatus:
            root = "status";
            usePlural = false;
            break;
        case TermStep:
            root = "step"; break;
        case TermSubject:
            root = "subject"; break;
        case TermTerm:
            root = "term"; break;
        default:
            console.debug("lookupLingo: invalid parameter");
            return "";
    }

    if (useUppercase)
        root = root.charAt(0).toUpperCase() + root.slice(1);
    if (usePlural) {
        if (useEs)
            root += "es";
        else
            root += "s";
    }
    return root;
}

export {
    Rating, Ratings, RatingLabel, RatingMenu, StatusIcon, CompletionIcon, ApprovalIcon, getRatingsCount, getRatingFromScore, getRatingLabel, getStatusIconInfo, lookupLingo,
    buildColorInfo, buildCustomColors, getScoreColors, getScoreColor, getRatingColors, getRatingColor, getMutedRatingColors, getMutedRatingColor, getStatusColors, getStatusColor, getProgressColors, getProgressColor, getApprovedColor,
    TermApproval, TermApprove, TermApproved, TermChat, TermChild, TermClass, TermComplete, TermCompleted, TermEvidence, TermExtracurricular, TermFramework, TermGrade, TermGradebook, TermGroup, TermInactive, TermLearner,
    TermNote, TermParent, TermPortfolio, TermProject, TermRate, TermRating, TermRubric, TermScore, TermSkill, TermStatus, TermStep, TermSubject, TermTerm,
    statusOptionsList, statusLocked, statusNotAssigned, statusNotStarted, statusInProgress, statusSubmitted, statusReviewRequested, statusUnderReview, statusRevisionRequested, statusCompleted, statusInPortfolio,
    highestRating, lowestRating, highestGrade, lowestGrade,
}

    // for (let i = 0; i < ratingCount; i++) {
    //     const ratingLevel = getRatingLabel(skillRatings[i]);
    //     ratingStack += `${skillNames[i]}:${ratingLevel} (${skillScores[i]})::${skillIDs[i]}`;
    // }

