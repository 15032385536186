import { useRef, useEffect } from "react";
import { changeContentPage, appStateData, componentStateData } from "./App";
import { getPageNameID } from "./utilities";
import { ratingHive, scoreHive, approvalHive, maxRatingLevels, classesTab, projectsTab } from "./utilitiesConstants";
import { isClearableFilter } from "./utilitiesFiltering";
import { handleToggle, getSelectionState, tickSelectionState, clearSelectionState, toggleSelectionState, saveState, setState, getState, getSelectionMode, countSelected, clearAllState, selectAllState } from "./utilitiesState";
import { saveUndoState } from "./utilitiesBackUndo";
import { getRatingFromScore, lookupLingo, TermApproval, TermRating, TermScore } from "./customizable";
import { isClassID, isProjectID, getFirstClass, getFirstProject, getPrimaryIDfromItemID, getSecondaryIDfromItemID, getClassIDfromItemID, getFirstProjectInClass } from "./data";

let lastCellID = 0; // here because js doesn't support static variables
let scoreChange = false;
let lastDigit = null;

// todo: confirm that the fud calls below are still necessary
// globalHandleClick to consolidate handling of these events:
//  select, selectStudent, selectColumn clicks
//  link clicks --links in content pages
//  filter clicks--same as link, but with a class filter
//  rubric clicks
//  nav clicks  --the arrows on status bar
//  handle local events with handleClick ( ...handle local event ... globalHandleClick (for anything else))
//  returns true if event is handled (so, can call global handler first, then handle remainder)
const globalHandleClick = (ev, objectID, otherID, isValueID = 0, getValueID = 0, getValue = 0, contextTerm = 0) => {
  console.debug(`globalHandleClick, ev target: ${ev.target}, typeof: ${typeof ev.target}, objectID: ${objectID}, otherID: ${otherID}`);
  if (ev) ev.stopPropagation();

  if (typeof objectID === "string") {
    if (objectID.substring(0, 7) === "select:") {
      // select clicked on item
      const [contentPage] = appStateData.contentState;
      const [, setSelectedItems] = componentStateData[contentPage].singleSelectState;
      const nextSelectedItemID = objectID.substring(7); // object IDs are strings
      const other = otherID.split(",");
      const level1ID = other[0];
      const groupID = other[1];
      setSelectedItems([nextSelectedItemID, level1ID, groupID]);
      return true;
    } else if (objectID.substring(0, 14) === "selectStudent:") {
      // loop through pageMap, select all items with this student ID
      const studentID = otherID;
      const [contentPage] = appStateData.contentState;
      const stateData = componentStateData[contentPage];
      let firstMatch = false;
      let firstMatchSense = false;
      stateData.selectionInfo.pageMap?.forEach((item) => {
        const itemID = item.ID;
        const thisStudentID = getPrimaryIDfromItemID(itemID);
        if (!item.group && studentID === thisStudentID) {
          // determine sense of change based on firstMatch
          if (!firstMatch) {
            firstMatch = true;
            firstMatchSense = handleToggle(stateData.selectionState, itemID, getSelectionState);
          }
          handleToggle(stateData.selectionState, itemID, firstMatchSense ? clearSelectionState : tickSelectionState);
        }
      });
      return true;
    } else if (objectID.substring(0, 13) === "selectColumn:") {
      // loop through pageMap, select all items with this secondary (skill/evidence) ID
      const [contentPage] = appStateData.contentState;
      const stateData = componentStateData[contentPage];
      let firstMatch = false;
      let firstMatchSense = false;
      stateData.selectionInfo.pageMap?.forEach((item) => {
        const itemID = item.ID;
        const thisOtherID = getSecondaryIDfromItemID(itemID);
        if (!item.group && otherID === thisOtherID) {
          // determine sense of change based on firstMatch
          if (!firstMatch) {
            firstMatch = true;
            firstMatchSense = handleToggle(stateData.selectionState, itemID, getSelectionState);
          }
          handleToggle(stateData.selectionState, itemID, firstMatchSense ? clearSelectionState : tickSelectionState);
        }
      });
      return true;
    } else if (objectID.substring(0, 12) === "selectGroup:") {
      // loop through pageMap, select all items within this group
      const groupLevel = objectID.substring(12);
      const [contentPage] = appStateData.contentState;
      const stateData = componentStateData[contentPage];
      const groupID = otherID;
      let groupFound = false;
      let groupSense = false;
      const matchGroupLevel = !!(groupLevel === "2");
      let done = false;
      // todo: first attempt is to get l1s to work, for groups will need to match level (not stop on first group found)
      stateData.selectionInfo.pageMap?.forEach((item) => {
        const itemID = item.ID;
        if (!done) {
          if (groupFound) {
            if (item.group && (!matchGroupLevel || item.group === 2)) {
              // so, any group ends a level1 selection, group selection stops at next group (where group === 2)
              done = true;
            } else {
              handleToggle(stateData.selectionState, itemID, groupSense ? clearSelectionState : tickSelectionState);
            }
          } else if (item.group) {
            if (itemID === groupID) {
              groupFound = true;
              groupSense = handleToggle(stateData.selectionState, itemID, getSelectionState);
              handleToggle(stateData.selectionState, itemID, groupSense ? clearSelectionState : tickSelectionState);
            }
          }
        }
      });
      return true;
    } else if (objectID.substring(0, 5) === "link:") {
      // handle link: commands to change content pages
      // call format: objectID: "link:" + page.type.toString(), otherID: itemID (to set selection state)
      const pageType = Number(objectID.substring(5));
      let pageID = otherID;
      if (pageType === classesTab && !isClassID(pageID)) pageID = getFirstClass();
      else if (pageType === projectsTab && !isProjectID(pageID)) pageID = getFirstProject();

      // clear filter, if any
      const [contentPage] = appStateData.contentState;
      const stateData = componentStateData[contentPage];
      const [, setFilterState] = stateData.filterState;
      setFilterState([]);

      // nav to page
      appStateData.navigationObject = pageID;
      changeContentPage(pageType);
      return true;
    } else if (objectID.substring(0, 7) === "filter:") {
      // handle filter: commands to change content pages AND filter by a class (or perhaps other future object)
      // call format: objectID = "filter:" + page.type.toString(), otherID = getItemID(pageID, classFilterID) (to set selection state AND filter)
      // consider: consolidate with link: (eliminate filter:) by adding a null filter to link calls.
      const pageType = Number(objectID.substring(7));
      let classID = getClassIDfromItemID(otherID);

      const [contentPage] = appStateData.contentState;
      const stateData = componentStateData[contentPage];
      const [, setFilterState] = stateData.filterState;

      if (pageType === classesTab) {
        // not a filter but a link
        classID = otherID;
        console.debug(`Convert filter to link for class: ${classID}`);
        setFilterState([classID]); // only show this class--should have no effect in classesPane
      } else {
        // apply class filter--need both classState and filterState to filter
        const [, setClass] = appStateData.classState;
        const [, setProject] = appStateData.projectState;
        if (classID) {
          // consider: handle in changeContentPage?? (handled for classes and projects hives, but not for others now--does this make sense?)
          setClass(classID);
          setProject(getFirstProjectInClass(classID));
          setFilterState([classID]); // only show this class--should have no effect in classesPane
          console.debug(`Filter: filter to: ${classID}`);
        } else {
          // clear filter, show all classes
          classID = getFirstClass();
          setClass(classID);
          setProject(getFirstProjectInClass(classID));
          setFilterState([]);
          console.debug(`Remove filter: set class to: ${classID}`);
        }
      }

      // nav to page
      appStateData.navigationObject = otherID; // note not pageID, need the more specific nav item
      changeContentPage(pageType);
      return true;
    } else if (objectID.substring(0, 7) === "rubric:" || objectID.substring(0, 12) === "groupRubric:" || objectID === "approval:" || objectID === "groupApproval:" || objectID === "toggleApproval:") {
      // consider: retire this one and call globalHandleRubric directly--more efficient and can provide all the contextual parameters
      console.debug("Rubric/approve click");
      const [contentPage] = appStateData.contentState;
      const stateData = componentStateData[contentPage];
      if (!contextTerm) contextTerm = getPageNameID(contentPage); // could be fixed in globalHandleRubric but this is where the problem occurs (direct calls will provide)
      globalHandleRubric(stateData, objectID, null, contextTerm);
      return true;
    } else if (objectID.substring(0, 4) === "nav:") {
      // handle commands, remainder is buttonType (ArrowUp, ArrowDown, ArrowLeft, ArrowRight)
      const commandID = objectID.substring(4).trim();
      const event = { key: commandID, fake: true, defaultPrevented: false, returnValue: true }; // synthesize event
      handleKeyDown(event);
      return true;
    } else if (objectID === "toggleDetails") {
      const [showDetailsPane, updateShowDetailsPane] = appStateData.showDetails;
      updateShowDetailsPane(!showDetailsPane);
      return true;
    } else if (objectID === "togglePreview") {
      const [showPreviewPane, updateShowPreviewPane] = appStateData.showPreview;
      updateShowPreviewPane(!showPreviewPane);
      return true;
    } else if (objectID === "clearFilters") {
      // reset all "clearable/short term" filters (filter by active is a long term filter--just leave it!)
      if (isClearableFilter(appStateData.projectFilterState)) {
        const [, setProjectFilter] = appStateData.projectFilterState;
        setProjectFilter(["all"]);
      }
      if (isClearableFilter(appStateData.evidenceFilterState)) {
        const [, setEvidenceFilter] = appStateData.evidenceFilterState;
        setEvidenceFilter(["all"]);
      }
      if (isClearableFilter(appStateData.skillFilterState)) {
        const [, setSkillFilter] = appStateData.skillFilterState;
        setSkillFilter(["all"]);
      }
      if (isClearableFilter(appStateData.statusFilterState)) {
        const [, setStatusFilter] = appStateData.statusFilterState;
        setStatusFilter(["all"]);
      }
      return true;
    }
  }

  // user changed a rating (not in bulkMode) or clicked a cell (in bulkMode)
  if (isValueID && getValueID && getValue && objectID && otherID) {
    const [contentPage] = appStateData.contentState;
    const stateData = componentStateData[contentPage];
    const [bulkMode] = stateData.bulkModeState;

    const commandID = Number(ev.target.value);
    const cellID = getValueID(objectID, otherID); // here, objectID is studentID, otherID is skillID
    if (bulkMode && !isValueID(commandID)) {
      // if bulkMode, toggle selection state
      if (ev.shiftKey) {
        // if shift select, loop through pageMap, select all items between cellID/lastCellID and cellID/lastCellID
        let firstMatch = false;
        let secondMatch = false;
        let foundSecondMatch = false;
        let firstMatchSense = false;
        stateData.selectionInfo.pageMap?.forEach((item) => {
          const itemID = item.ID;
          if (!item.group && (itemID === cellID || itemID === lastCellID)) {
            // determine sense of change based on firstMatch
            if (!firstMatch) {
              firstMatch = true;
              firstMatchSense = handleToggle(stateData.selectionState, itemID, getSelectionState);
            } else foundSecondMatch = true;
          }
          if (firstMatch && !secondMatch) {
            handleToggle(stateData.selectionState, itemID, firstMatchSense ? tickSelectionState : clearSelectionState);
          }
          if (foundSecondMatch) secondMatch = true; // set this afterward handleToggle, otherwise will miss last time
        });
      } else {
        handleToggle(stateData.selectionState, cellID, toggleSelectionState);
        lastCellID = cellID;
      }
    } else {
      // if not bulkMode, change rating for skill
      if (isValueID(commandID)) {
        // console.debug(`Classes change rating ID: ${commandID}, objectID: ${objectID}, otherID: ${otherID}, cellID: ${cellID}, new rating: ${newRating}`);
        const newRating = getValue(commandID);
        //        const oldRating = saveState(stateData.gradeState, cellID, ratingHive, 0, getState);
        const [oldRating] = getCurrentResults(stateData, cellID);
        const tt = contextTerm ? `Undo ${lookupLingo(TermRating)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo ${lookupLingo(TermRating)} change`;
        saveState(stateData.gradeState, cellID, ratingHive, newRating, setState);
        saveUndoState(stateData.gradeState, cellID, ratingHive, newRating, oldRating, tt);

        // force app to update
        // const [FUD, setFUD] = fud;
        // setFUD(FUD + 1);
      }
    }
    return true;
  }

  return false;
};

// consider: consolidate into rubric/approval, etc. and determine if group within function? (not sure of downside to this if always based on bulkModeState)
const globalHandleRubric = (stateData, changeInfo, itemID = null, contextTerm = 0, bulkMarkMode = 0, bulkMarkFilter = null) => {
  if (typeof changeInfo !== "string") return false;

  const [changedGrades] = stateData.gradeState;
  if (changeInfo === "updateRubric") {
    // extract skillID, set rubric
    const skillID = getSecondaryIDfromItemID(itemID);
    const targetLevel = Number(contextTerm); // borrowed this param, it's actually ev.target.value--use to scroll to the relevant rubric

    // hack: convert to new skillIDs
    let UUID;
    switch (skillID % 4) {
      case 1:
        UUID = "e140836d-22cf-45ef-a8eb-4e4422ec8d8c";
        break;
      case 2:
        UUID = "af9a3c62-e0bf-4fb2-9d6d-218423afaee7";
        break;
      case 3:
        UUID = "353b5c59-66fc-4726-9fac-639df91ca00a";
        break;
      default:
        UUID = "a847b383-4eab-4273-9fa7-93302875a65f";
        break;
    }

    const [, setRubric] = stateData.appState.rubricState;
    setRubric({ set: UUID, currentLevel: targetLevel });
    return true; // todo: see if fud is necessary
  } else if (changeInfo.substring(0, 7) === "rubric:") {
    // for this version, the selected item is determined by singleSelectState
    const newRating = Number(changeInfo.substring(7));
    const [selectedItem] = stateData.singleSelectState;
    if (!selectedItem[0]) return true;
    const tt = contextTerm ? `Undo ${lookupLingo(TermRating)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo ${lookupLingo(TermRating)} change`;
    // console.debug(`Rubric, new rating ${newRating} selected item: ${selectedItem[0]} changed grades: ${changedGrades.length}`);

    // update rating state
    const [oldRating] = getCurrentResults(stateData, selectedItem[0]);
    saveState(stateData.gradeState, selectedItem[0], ratingHive, newRating, setState);
    saveUndoState(stateData.gradeState, selectedItem[0], ratingHive, newRating, oldRating, tt);
  } else if (changeInfo.substring(0, 9) === "rubricID:") {
    // for this version, the selected item is itemID
    const newRating = Number(changeInfo.substring(9));
    if (!itemID) return true;
    const tt = contextTerm ? `Undo ${lookupLingo(TermRating)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo ${lookupLingo(TermRating)} change`;
    // console.debug(`Rubric, new rating ${newRating} selected item: ${selectedItem[0]} changed grades: ${changedGrades.length}`);

    // update rating state
    const [oldRating] = getCurrentResults(stateData, itemID);
    saveState(stateData.gradeState, itemID, ratingHive, newRating, setState);
    saveUndoState(stateData.gradeState, itemID, ratingHive, newRating, oldRating, tt);
  } else if (changeInfo.substring(0, 12) === "groupRubric:") {
    const newRating = Number(changeInfo.substring(12));
    const [selectedItems] = stateData.selectionState;
    // console.debug(`Group rubric, new rating ${newRating} selected items: ${selectedItems.length} changed grades: ${changedGrades.length}`);
    const tt = contextTerm ? `Undo ${lookupLingo(TermRating)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo ${lookupLingo(TermRating)} change`;

    // set iterator based on selectionMode
    // see utilitiesState for details, but if getSelectionMode returns 0, only items in selectionState need to be processed. If 1, all items need to be processed so use pageMap and skip groups
    const selectAllMode = handleToggle(stateData.selectionState, 0, getSelectionMode);
    const iterator = !selectAllMode ? selectedItems : stateData.selectionInfo.pageMap;

    // loop through selected items, skipping groups
    iterator?.forEach((item) => {
      const itemID = item.ID;
      if (!selectAllMode || !item.group) {
        // check if rated item
        if (handleToggle(stateData.selectionState, itemID, getSelectionState)) {
          // update rating state, but only if changed
          //          const oldRating = saveState(stateData.gradeState, ratingID, ratingHive, 0, getState);
          const [oldRating] = getCurrentResults(stateData, itemID);
          if (newRating !== oldRating) {
            saveState(stateData.gradeState, itemID, ratingHive, newRating, setState);
            saveUndoState(stateData.gradeState, itemID, ratingHive, newRating, oldRating, tt);
          }
        }
      }
    });
    console.debug(`Group rubric 2, new rating ${newRating} selected items: ${selectedItems.length} changed grades: ${changedGrades.length}`);
  } else if (changeInfo.substring(0, 6) === "score:") {
    const newScore = Number(changeInfo.substring(6));
    const [selectedItem] = stateData.singleSelectState;
    if (!selectedItem[0]) return true;
    const tt = contextTerm ? `Undo ${lookupLingo(TermScore)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo ${lookupLingo(TermScore)} change`;

    // update score state
    const [, oldScore] = getCurrentResults(stateData, selectedItem[0]);
    saveState(stateData.gradeState, selectedItem[0], scoreHive, newScore, setState);
    saveUndoState(stateData.gradeState, selectedItem[0], scoreHive, newScore, oldScore, tt);
  } else if (changeInfo.substring(0, 11) === "groupScore:") {
    const newScore = Number(changeInfo.substring(11));
    const [selectedItems] = stateData.selectionState;
    // console.debug(`Group score, new score ${newScore} selected items: ${selectedItems.length} changed grades: ${changedGrades.length}`);
    const tt = contextTerm ? `Undo ${lookupLingo(TermScore)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo ${lookupLingo(TermScore)} change`;

    // set iterator based on selectionMode
    // see utilitiesState for details, but if getSelectionMode returns 0, only items in selectionState need to be processed. If 1, all items need to be processed so use pageMap and skip groups
    const selectAllMode = handleToggle(stateData.selectionState, 0, getSelectionMode);
    const iterator = !selectAllMode ? selectedItems : stateData.selectionInfo.pageMap;

    // loop through selected items, skipping groups
    iterator?.forEach((item) => {
      const itemID = item.ID;
      if (!selectAllMode || !item.group) {
        // check if rated item
        if (handleToggle(stateData.selectionState, itemID, getSelectionState)) {
          // update rating state, but only if changed
          //          const oldRating = saveState(stateData.gradeState, ratingID, ratingHive, 0, getState);
          const [, oldScore] = getCurrentResults(stateData, itemID);
          if (newScore !== oldScore) {
            saveState(stateData.gradeState, itemID, scoreHive, newScore, setState);
            saveUndoState(stateData.gradeState, itemID, scoreHive, newScore, oldScore, tt);
          }
        }
      }
    });
    // console.debug(`Group score 2, new score ${newScore} selected items: ${selectedItems.length} changed grades: ${changedGrades.length}`);
  } else if (changeInfo === "approval:" || changeInfo === "toggleApproval:") {
    const [selectedItem] = stateData.singleSelectState;
    console.debug(`Rubric, approve`);
    if (!selectedItem[0]) return true;
    const tt = contextTerm ? `Undo ${lookupLingo(TermApproval)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo approval change`;

    // Approve
    let [, , oldApproval] = getCurrentResults(stateData, selectedItem[0]);
    oldApproval = oldApproval ?? false;

    const newApproval = changeInfo === "approval:" ? true : !oldApproval;
    if (newApproval !== oldApproval) {
      saveState(stateData.gradeState, selectedItem[0], approvalHive, newApproval, setState);
      saveUndoState(stateData.gradeState, selectedItem[0], approvalHive, newApproval, oldApproval, tt);
    }
  } else if (changeInfo === "groupApproval:") {
    const [selectedItems] = stateData.selectionState;
    // console.debug(`Group rubric, approve`);
    const tt = contextTerm ? `Undo ${lookupLingo(TermApproval)} change from ${lookupLingo(contextTerm, true, true)}` : `Undo approval change`;

    // set iterator based on selectionMode
    // see utilitiesState for details, but if getSelectionMode returns 0, only items in selectionState need to be processed. If 1, all items need to be processed so use pageMap and skip groups
    const selectAllMode = handleToggle(stateData.selectionState, 0, getSelectionMode);
    const iterator = !selectAllMode ? selectedItems : stateData.selectionInfo.pageMap;

    // loop through selected items, skipping groups
    iterator?.forEach((item) => {
      const itemID = item.ID;
      // const newApproval = selectAllMode ? true : handleToggle(stateData.selectionState, itemID, getSelectionState);
      const isSelected = handleToggle(stateData.selectionState, itemID, getSelectionState);

      // if not selectAll mode, continue based on selection state, skip if not selected. If selectAll mode, skip if a group
      if (isSelected && (!selectAllMode || !item.group)) {
        let filter = false,
          oldApproval;
        if (bulkMarkMode && bulkMarkFilter) [filter, oldApproval] = bulkMarkFilter(stateData, itemID, bulkMarkMode);
        else [, , oldApproval] = getCurrentResults(stateData, itemID);

        if (!oldApproval && !filter) {
          saveState(stateData.gradeState, itemID, approvalHive, true, setState);
          saveUndoState(stateData.gradeState, itemID, approvalHive, true, oldApproval, tt);
        }
      }
    });
  } else return false;

  return true;
};

const handleKeyDown = (ev) => {
  // this is a app-wide global handler so filter out keyboard events not aimed at content panes: return if in a Mui control
  // consider: capture input for content and navigation components...possible?
  if (!ev || ev.defaultPrevented || !ev.returnValue || ev.path[0]?.className?.substring(0, 8) === "MuiInput" || ev.path[0]?.className?.substring(0, 9) === "MuiButton") return;

  // return immediately if shift or control key (with a few exceptions)
  if ((ev?.shiftKey || ev?.ctrlKey) && ev.key !== "A" && ev.key !== "a") return;

  // messes with accessibility--will need to handle by moving focus instead of handling spacebar
  //   switch (ev.code) {
  //     case "Space":
  //       ev.preventDefault();
  //       toggleSelection();
  //       return;
  //   }

  const [contentPage] = appStateData.contentState;
  const stateData = componentStateData[contentPage];
  const [bulkMode] = stateData.bulkModeState;

  // handle score change shortcut
  // todo: currently doesn't handle a score of 100 (3 digits) - if 10, could wait, special case next digit
  if (ev.key === "/") {
    scoreChange = true;
    lastDigit = null;
    return;
  } else if (scoreChange) {
    if (isNaN(ev.key)) {
      scoreChange = false;
    } else if (!lastDigit) {
      lastDigit = Number(ev.key);
      return;
    } else {
      // score change made
      const newScore = lastDigit * 10 + Number(ev.key);
      if (newScore >= 50 && newScore <= 100) {
        // for now, assume mistake if outside this range (confirm with David)
        ev.preventDefault();
        const newRating = getRatingFromScore(newScore);

        globalHandleRubric(stateData, (bulkMode ? "groupScore:" : "score:") + newScore.toString());
        if (newRating != null) {
          globalHandleRubric(stateData, (bulkMode ? "groupRubric:" : "rubric:") + newRating.toString());
        }
      }

      // clear keyboard globals
      scoreChange = false;
      lastDigit = null;
      return;
    }
  }

  switch (ev.key) {
    case "ArrowLeft":
    case "ArrowRight":
    case "ArrowUp":
    case "ArrowDown":
    case "Home":
    case "End":
    case "PageUp":
    case "PageDown":
      if (!ev.fake) ev.preventDefault();
      changeSelectionUsingPageMap(ev.key);
      return;
    case "*":
      ev.preventDefault();
      globalHandleRubric(stateData, bulkMode ? "groupApproval:" : "toggleApproval:");
      return;
    case "1":
    case "2":
    case "3":
    case "4":
    case "5": {
      ev.preventDefault();
      const newRating = Number(ev.key);
      globalHandleRubric(stateData, (bulkMode ? "groupRubric:" : "rubric:") + newRating.toString());
      return;
    }
    case "+":
    case "-":
    case "0": {
      // change or reset rating
      // rubrics are 0 based, ratings are 1 based, hence the need for -1
      ev.preventDefault();
      const [selectedItem] = stateData.singleSelectState;
      if (selectedItem[0]) {
        let [oldRating] = getCurrentResults(stateData, selectedItem[0], ev.key === "0");
        oldRating--; // -1 for rubric

        let newRating;
        if (ev.key === "+") newRating = oldRating >= maxRatingLevels ? maxRatingLevels : oldRating + 1;
        else if (ev.key === "-") newRating = oldRating ? oldRating : 1;
        else newRating = oldRating;
        globalHandleRubric(stateData, "rubric:" + newRating.toString());
      }
      return;
    }
    case "a":
    case "A":
      if (ev.ctrlKey) {
        ev.preventDefault();
        const anySelected = handleToggle(stateData.selectionState, 0, countSelected);
        const selectAllMode = handleToggle(stateData.selectionState, 0, getSelectionMode);
        handleToggle(stateData.selectionState, 0, anySelected || selectAllMode ? clearAllState : selectAllState);
      }
      return;
    default:
      return;
  }
};

// consider: specific which results are wanted, otherwise might waste time finding unneeded results
const getCurrentResults = (stateData, itemID, useOriginal = false) => {
  if (!stateData || !itemID) {
    console.debug("getCurrentResults: missing or invalid parameters");
    return [null, null, null, null];
  }

  // look for results changes
  let currentRating, currentScore, currentApproval;
  if (!useOriginal) {
    currentRating = saveState(stateData.gradeState, itemID, ratingHive, 0, getState);
    currentScore = saveState(stateData.gradeState, itemID, scoreHive, 0, getState);
    currentApproval = saveState(stateData.gradeState, itemID, approvalHive, 0, getState);

    // if all are found, return now
    // todo: for now, assume currentApproval is the status
    if (currentRating && currentScore && currentApproval) return [currentRating, currentScore, currentApproval, null];
  }

  // otherwise, find missing by looking up original in pageMap (ok if null)
  const pageMap = stateData.selectionInfo.pageMap;
  for (let i = 0; i < pageMap.length; i++) {
    if (pageMap[i].ID === itemID) {
      return [currentRating ?? pageMap[i]?.rating, currentScore ?? pageMap[i]?.score, currentApproval ?? pageMap[i]?.approval, pageMap[i]?.status];
    }
  }

  return [null, null, null, null];
};

// using a pageMap of IDs to determine the effect of keyboard navigation. Need to have complete separation between the keyboard logic and the underlying data, and the map does this
// pageMap requires this format: group (group == 2), level1 (group == 1), items (group == 0), every item must have a preceeding group and level1
// note that hives can have dups, so setSelectedItems needs to save all three and all three need to be checked for a match (at least for trees)
// possible bug: code might assume each group has content (vs. empty group and l1 headers). Might not work if any groups are empty. (do later, complex enough already.)
const changeSelectionUsingPageMap = (keyCode) => {
  const [contentPage] = appStateData.contentState;
  const [autoClose] = appStateData.autoCloseState;
  const [lastSelectedItem, setSelectedItems] = componentStateData[contentPage].singleSelectState;
  const pageMap = componentStateData[contentPage].selectionInfo.pageMap;
  const rows = componentStateData[contentPage].selectionInfo.rows;
  const columns = componentStateData[contentPage].selectionInfo.columns;
  const isExpandable = componentStateData[contentPage].selectionInfo.isExpandable;
  if (!pageMap || pageMap.length === 0) return; // can't do anything here without the pageMap

  let nextSelectedID = [null, null, null], // 0 = item, 1 == level1, 2 == group
    currentItemIndex = -1,
    groupFound = false,
    level1Found = false,
    changedGroups = false;

  if (isExpandable) {
    // handle expandible trees--have group and level1, all three need to match

    // find lastSelectedItems, set currentItemIndex to matching index
    if (lastSelectedItem[0] > 0) {
      for (let i = 0; i < pageMap.length; i++) {
        if (pageMap[i].group === 2 && pageMap[i].ID === lastSelectedItem[2]) {
          groupFound = true;
        } else if (groupFound && pageMap[i].group === 1 && pageMap[i].ID === lastSelectedItem[1]) {
          level1Found = true;
        } else if (level1Found && pageMap[i].ID === lastSelectedItem[0]) {
          currentItemIndex = i;
          break;
        }
      }
    }

    // if no selection case, map to Home
    if (currentItemIndex === -1 || lastSelectedItem[0] == null) keyCode = "Home";

    // handle absolute cases
    switch (keyCode) {
      case "Home":
        for (let i = 0; i < pageMap.length; i++) {
          if (pageMap[i].group) {
            nextSelectedID[pageMap[i].group] = pageMap[i].ID;
          } else {
            nextSelectedID[0] = pageMap[i].ID; // set to first item that's not in a group
            if (pageMap[i].group === 1 && (nextSelectedID[1] !== lastSelectedItem[1] || nextSelectedID[2] !== lastSelectedItem[2])) {
              changedGroups = true;
            }
            break;
          }
        }
        break;
      case "End":
        for (let i = pageMap.length - 1; i >= 0; i--) {
          if (pageMap[i].group) {
            if (nextSelectedID[0]) {
              if (!nextSelectedID[pageMap[i].group]) nextSelectedID[pageMap[i].group] = pageMap[i].ID;
              if (pageMap[i].group === 2) {
                if (pageMap[i].group === 1 && (nextSelectedID[1] !== lastSelectedItem[1] || nextSelectedID[2] !== lastSelectedItem[2])) {
                  changedGroups = true;
                }
                break; // break once containing group IDs are found
              }
            }
          } else if (!nextSelectedID[0]) {
            nextSelectedID[0] = pageMap[i].ID; // set to last item that's not in a group, but don't break until next group IDs are found (above)
          }
        }
        break;
      default:
        break;
    }

    // handle relative down keys
    if (nextSelectedID[0] == null) {
      switch (keyCode) {
        case "ArrowDown":
          for (let i = currentItemIndex + 1; i < pageMap.length; i++) {
            if (pageMap[i].group) {
              nextSelectedID[pageMap[i].group] = pageMap[i].ID;
              changedGroups = true;
            } else {
              nextSelectedID[0] = pageMap[i].ID; // set to first item after current
              break;
            }
          }
          break;
        case "ArrowRight":
          // tbd
          break;
        case "PageDown":
          for (let i = currentItemIndex + 1; i < pageMap.length; i++) {
            if (pageMap[i].group) {
              nextSelectedID[pageMap[i].group] = pageMap[i].ID;
              changedGroups = true;
            } else {
              if (nextSelectedID[1]) {
                nextSelectedID[0] = pageMap[i].ID; // set to first item after next L1 group
                break;
              }
            }
          }
          break;
        default:
          break;
      }
    }

    // handle relative up keys (up is harder because if to scan all way up to groups to know what to open)
    // changedGroups only used here
    if (nextSelectedID[0] == null) {
      switch (keyCode) {
        case "ArrowUp":
          // if already at top, start by skipping over groups
          if (pageMap[currentItemIndex - 1].group) {
            currentItemIndex--;
            changedGroups = true;
          }
          if (pageMap[currentItemIndex - 1].group) {
            currentItemIndex--;
            changedGroups = true;
          } // do twice if necessary to skip over both headers
          for (let i = currentItemIndex - 1; i >= 0; i--) {
            if (pageMap[i].group) {
              if (!nextSelectedID[pageMap[i].group]) nextSelectedID[pageMap[i].group] = pageMap[i].ID;
              if (nextSelectedID[0] && nextSelectedID[2]) break;
            } else if (!nextSelectedID[0]) {
              nextSelectedID[0] = pageMap[i].ID; // set to first item before current
              if (!changedGroups) break;
            }
          }
          break;
        case "ArrowLeft":
          // tbd
          break;
        case "PageUp":
          // if already at top, start by skipping over groups
          if (pageMap[currentItemIndex - 1].group) {
            currentItemIndex--;
            changedGroups = true;
          }
          if (pageMap[currentItemIndex - 1].group) {
            currentItemIndex--;
            changedGroups = true;
          } // do twice if necessary to skip over both headers
          for (let i = currentItemIndex - 1; i >= 0; i--) {
            if (pageMap[i].group) {
              if (!nextSelectedID[pageMap[i].group]) nextSelectedID[pageMap[i].group] = pageMap[i].ID;
              if (nextSelectedID[0] && nextSelectedID[2]) break;
            } else {
              if (!nextSelectedID[1]) nextSelectedID[0] = pageMap[i].ID; // ride the item up to the previous group head
              if (pageMap[i - 1].group && !changedGroups) break;
            }
          }
          break;
        default:
          break;
      }
    }
  } else {
    // handle grids (no groups here, so code is much simpler)

    // find lastSelectedItems, set currentItemIndex to matching index
    if (lastSelectedItem[0] > 0) {
      for (let i = 0; i < pageMap.length; i++) {
        if (pageMap[i].ID === lastSelectedItem[0]) {
          currentItemIndex = i;
          break;
        }
      }
    }

    // if no selection case, map to Home
    if (currentItemIndex === -1 || lastSelectedItem[0] == null) keyCode = "Home";

    const len = pageMap.length;
    const currentColumn = currentItemIndex % columns;
    let nextCell;
    switch (keyCode) {
      case "Home":
        nextSelectedID[0] = pageMap[0].ID;
        break;
      case "End":
        nextSelectedID[0] = pageMap[len - 1].ID;
        break;
      case "ArrowDown":
        if (currentItemIndex + columns <= len - 1) nextSelectedID[0] = pageMap[currentItemIndex + columns].ID;
        else if (currentColumn < columns - 1) nextSelectedID[0] = pageMap[currentColumn + 1].ID;
        break;
      case "ArrowUp":
        if (currentItemIndex - columns >= 0) nextSelectedID[0] = pageMap[currentItemIndex - columns].ID;
        else if (currentItemIndex > 0) {
          nextCell = (rows - 1) * columns + (currentColumn - 1);
          nextSelectedID[0] = pageMap[nextCell].ID;
        }
        break;
      case "ArrowRight":
        if (currentItemIndex < len - 1) nextSelectedID[0] = pageMap[currentItemIndex + 1].ID;
        break;
      case "ArrowLeft":
        if (currentItemIndex - 1 >= 0) nextSelectedID[0] = pageMap[currentItemIndex - 1].ID;
        break;
      case "PageDown":
        nextCell = (rows - 1) * columns + currentColumn;
        nextSelectedID[0] = pageMap[nextCell].ID;
        break;
      case "PageUp":
        nextSelectedID[0] = pageMap[currentColumn].ID;
        break;
      default:
        break;
    }
  }

  // return if nothing to do
  if (nextSelectedID[0] == null || nextSelectedID[0] === lastSelectedItem[0]) return;

  // fill in missing--new might not be set if same as last
  if (nextSelectedID[1] == null) nextSelectedID[1] = lastSelectedItem[1];
  if (nextSelectedID[2] == null) nextSelectedID[2] = lastSelectedItem[2];

  if (isExpandable) {
    // if changed groups, close hives (if user selected that option)
    if (autoClose) {
      if (lastSelectedItem[2] !== nextSelectedID[2]) handleToggle(componentStateData[contentPage].expansionState, lastSelectedItem[2], clearSelectionState);
      if (lastSelectedItem[1] !== nextSelectedID[1]) handleToggle(componentStateData[contentPage].expansionState, lastSelectedItem[1], clearSelectionState);
    }

    // open new hives
    handleToggle(componentStateData[contentPage].expansionState, nextSelectedID[2], tickSelectionState);
    handleToggle(componentStateData[contentPage].expansionState, nextSelectedID[1], tickSelectionState);
  }

  // select new item
  setSelectedItems(nextSelectedID);
};

// keyboard listener hook
function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export { globalHandleClick, globalHandleRubric, useEventListener, handleKeyDown, getCurrentResults };

// currently unused
// const toggleSelection = () => {
//   const [contentPage] = appStateData.contentState;
//   const [selectedItemID] = componentStateData[contentPage].singleSelectState;

//   if (selectedItemID) handleToggle(componentStateData[contentPage].selectionState, selectedItemID, toggleSelectionState);
// };

// } else if (objectID.substring(0, 4) === "nav:") {
//   // handle commands, remainder is buttonType (up, down, left, right)
//   const commandID = objectID.substring(4).trim();
//   const [currentPage] = appStateData.contentState;
//   const [currentClass] = appStateData.classState;
//   const [currentProject] = appStateData.projectState;
//   console.debug(`globalHandleClick: nav: page: ${currentPage} class: ${currentClass} is first: ${isFirstClass(currentClass)} is last: ${isLastClass(currentClass)}`);

//   // handle commandID
//   if (commandID === "right") {
//     if (currentPage === classesTab) {
//       if (!isLastClass(currentClass)) {
//         appStateData.navigationObject = getNextClass(currentClass);
//         changeContentPage(currentPage);
//       }
//     } else if (currentPage === projectsTab) {
//       if (!isLastProjectInClass(currentClass, currentProject)) {
//         appStateData.navigationObject = getNextProjectInClass(currentClass, currentProject);
//         changeContentPage(currentPage);
//       }
//     }
//   } else if (commandID === "left") {
//     if (currentPage === classesTab) {
//       if (!isFirstClass(currentClass)) {
//         appStateData.navigationObject = getPreviousClass(currentClass);
//         changeContentPage(currentPage);
//       }
//     } else if (currentPage === projectsTab) {
//       if (!isFirstProjectInClass(currentClass, currentProject)) {
//         appStateData.navigationObject = getPreviousProjectInClass(currentClass, currentProject);
//         changeContentPage(currentPage);
//       }
//     }
//   }
//   return true;

// let change = rows - 1 - (currentItemIndex % rows);
// if (currentItemIndex + change < len - 1) nextSelectedID[0] = pageMap[currentItemIndex + change].ID;
