import React, { lazy, Suspense, useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { liftThemeData } from "./theme";
import TitleBar from "./titleBar";
import StatusBar from "./statusBar";
import { NavigatorPane, getNavObjectIDByType, openNavHive } from "./navigatorPane";
import { setAppState, getClassListOffset, setComponentState, setDetailsPaneState } from "./utilities";
import { useEventListener, handleKeyDown } from "./utilitiesEvents";
import { backStack } from "./utilitiesBackUndo";
import { handleToggle, getSelectionState, replaceSelectionState } from "./utilitiesState";
import { buildColorInfo, buildCustomColors, lookupLingo, TermChat, TermClass, TermEvidence, TermSkill, TermSubject } from "./customizable";
import { CssBaseline, useMediaQuery, createMuiTheme } from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { classData, teacherInfo, getClassIDfromProjectID, getFirstClass, getFirstProjectInClass, isProjectID } from "./data";
import { separatorTab, dashboardTab, calendarTab, messagesTab, learnersTab, groupsTab, classExplorerTab, classesTab, subjectsTab, projectsTab, evidenceTab, skillsTab, portfoliosTab, progressTab, trashTab } from "./utilitiesConstants";

// Font Awesome icons
import {
  faBars,
  faArrowCircleLeft,
  faUndo,
  faQuestionCircle,
  faExclamationCircle,
  faExclamation,
  faCommentDots,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faFlag,
  faSearch,
  faThumbtack,
  faStar,
  faLock,
  faGraduationCap,
  faCheck,
  faBriefcase,
  faAsterisk,
  faTachometerAlt,
  faCalendar,
  faUsers,
  faUser,
  faSearchPlus,
  faTh,
  faThList,
  faThLarge,
  faCubes,
  faCube,
  faEye,
  faCheckCircle,
  faChartLine,
  faTrash,
  faSuitcase,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faBan,
  faSave,
  faSignOutAlt,
  faCog
} from "@fortawesome/free-solid-svg-icons";

// build a library of Font Awesome icons
library.add(
  faBars,
  faArrowCircleLeft,
  faUndo,
  faQuestionCircle,
  faExclamationCircle,
  faExclamation,
  faCommentDots,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faFlag,
  faSearch,
  faThumbtack,
  faStar,
  faLock,
  faGraduationCap,
  faCheck,
  faBriefcase,
  faAsterisk,
  faTachometerAlt,
  faCalendar,
  faUsers,
  faUser,
  faSearchPlus,
  faTh,
  faThList,
  faThLarge,
  faCubes,
  faCube,
  faEye,
  faCheckCircle,
  faChartLine,
  faTrash,
  faSuitcase,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faBan,
  faSave,
  faSignOutAlt,
  faCog
);

const ContentPane = lazy(() => import("./contentPane"));
const DetailsPane = lazy(() => import("./detailsPane"));
const PreviewPane = lazy(() => import("./previewPane"));
const PortfolioPane = lazy(() => import("./portfolioPane"));
const EvidencePane = lazy(() => import("./evidencePane"));
const ClassesPane = lazy(() => import("./classesPane"));
const ProjectsPane = lazy(() => import("./projectsPane"));
const SignIn = lazy(() => import("./signIn"));
const renderLoader = () => <p>Loading...</p>; // <progress />; // progress hurts performance metrics!

const useStyles = makeStyles((theme) => ({
  bodyStyle: {
    marginTop: 136, // used to make room for titleBar and statusBar
    display: "block",
    // overflowY: "hidden", // prevent scrollbars from flashing during pane changes (removed: seems to make worse by having a scrollbar)
    transition: "margin " + theme.transitions.duration.complex + "ms ease",
  },
}));

// app globals (not sure if these need to be global, but using global to avoid reloads in App())
let liftTheme; // declare liftTheme here to set, update, and export (apparently no way to change in theme.js, after createMuiTheme is called)
let appStateData;
const componentStateData = [];
let detailsViewState;
let searchTermState;
let liftColorSet;
let fud; // use to force updates for refresh problems or non-state changes (like color schemes)

// global handler to change content page (need one or the other argument, not neither or both)
// newPageType: page type of new page to nav to
// oldPageState: restoring a previous page state from the Back stack
// consider: it's a bit of magic that simply returning the new page works--is there a better way to improve performance? (by reducing number of calls)
// consider: changed default values in utiliites from 0 to null, which broke this code. Review and fix later (null is slightly more accurate than 0)
const changeContentPage = (nextPageType, oldPageState = null) => {
  if (isNaN(nextPageType) || (nextPageType === 0 && !oldPageState)) {
    console.debug(`changeContentPage: missing or invalid parameters: newPageType: ${nextPageType}, oldPageState: ${oldPageState}`);
    return;
  }

  const [contentPage, setContentPage] = appStateData.contentState;
  let nextPageState = null;
  let pageID; // ID of new page
  if (oldPageState) {
    // change is from Back button--newPageType ignored, type is obtained from oldPageState
    nextPageState = oldPageState;
    nextPageType = nextPageState.componentType;
    pageID = getNavObjectIDByType(nextPageType); // navigator ID for new page

    // update state to match old page
    setContentPage(nextPageType);
  } else if (nextPageType) {
    // change is from drop-down lists, nav buttons, links

    // unpack state to get currentClass, currentProject, and navigationObj (and reset it)
    const [currentClass, setClass] = appStateData.classState;
    const [currentProject, setProject] = appStateData.projectState;
    pageID = appStateData.navigationObject; // appStateData.navigationObject = pageID;
    appStateData.navigationObject = 0; // immediately clear once used to prevent React repetition
    const lastPageType = contentPage;
    // console.debug(`changeContentPage: newPageType: ${newPageType}, contentPage: ${contentPage}, currentClass: ${currentClass}, navObj: ${navObj}`);

    // change content page?
    if (nextPageType !== contentPage) {
      console.debug("Change page");
      setContentPage(nextPageType);
    }

    if (nextPageType === classesTab && pageID !== 0 && currentClass !== pageID) {
      const classID = pageID; // to make easier to read
      console.debug("Change class");
      setClass(classID);
      setProject(getFirstProjectInClass(classID));

      // handle class change using references
      if (appStateData.projectListRef?.current) appStateData.projectListRef.current.selectedIndex = getClassListOffset(classData.classList, classID);
    } else if (nextPageType === projectsTab) {
      // class might have changed too
      const projectID = pageID; // to make easier to read
      // projectID *might be* (if in range) the new project ID--ignore if not really a project (could be a class or step)
      if (isProjectID(projectID) && currentProject !== projectID) {
        const classID = getClassIDfromProjectID(projectID);
        setClass(classID);
        console.debug(`Change class: ${classID} and project: ${projectID}`);
        setProject(projectID);

        // handle project changes using references
        if (appStateData.projectListRef?.current) appStateData.projectListRef.current.selectedIndex = getClassListOffset(classData.classList, projectID);
      }
    }

    // set next state
    nextPageState = componentStateData[nextPageType]; // this could be null for page types that aren't supported, but if so won't be consumed below

    // if page type has changed, push next state on backStack
    if (nextPageState && (backStack.length === 0 || lastPageType !== nextPageType)) {
      console.debug(`changeContentPage: backStack size: ${backStack.length} pushing state for ${nextPageType}`);
      backStack.push(nextPageState);
    }
  } else return;

  // update navSelectionState to match new page
  if (pageID && !handleToggle(appStateData.navSelectState, pageID, getSelectionState)) {
    console.debug(`changeContentPage: updating navigator selection to: ${pageID}`);
    if (nextPageType === classesTab) openNavHive(appStateData.navExpandState, classesTab); // if classesTab, open hive first, don't need to do for other hives since they are filters
    handleToggle(appStateData.navSelectState, pageID, replaceSelectionState);
  }

  // return new contentPage
  switch (nextPageType) {
    // todo: fill these in as we go
    case separatorTab:
    case dashboardTab:
    case calendarTab:
    case messagesTab:
    case learnersTab:
    case groupsTab:
    case classExplorerTab:
    case subjectsTab:
    case skillsTab:
    case progressTab:
    case trashTab:
      return (
        <Suspense fallback={renderLoader()}>
          <ContentPane />
        </Suspense>
      );
    case classesTab:
      return (
        <Suspense fallback={renderLoader()}>
          <ClassesPane classData={classData} stateData={nextPageState} />
        </Suspense>
      );
    case projectsTab:
      return (
        <Suspense fallback={renderLoader()}>
          <ProjectsPane classData={classData} stateData={nextPageState} />
        </Suspense>
      );
    case evidenceTab:
      return (
        <Suspense fallback={renderLoader()}>
          <EvidencePane classData={classData} stateData={nextPageState} />
        </Suspense>
      );
    case portfoliosTab:
      return (
        <Suspense fallback={renderLoader()}>
          <PortfolioPane classData={classData} stateData={nextPageState} />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={renderLoader()}>
          <ContentPane />
        </Suspense>
      );
  }
};

// for statusBar browse buttons: set left/right arrow toolips to indicate effect of command (up/down is always prev/next learner)
// todo: Need to actually implement the browser command, perhaps using a similar approach
const getContentPageNavTT = () => {
  const [contentPage] = appStateData.contentState;
  const [activeTab] = componentStateData[contentPage].activeTabState; // for some pages, next/prev object depends on active tab

  switch (contentPage) {
    // todo: finish
    // dashboardTab,
    // calendarTab,
    // learnersTab,
    // classExplorerTab,
    // progressTab,
    // trashTab,

    case messagesTab:
      return lookupLingo(TermChat);
    case classesTab:
      if (activeTab === 2)
        // hw for now--export constants?
        return lookupLingo(TermSkill);
      return lookupLingo(TermClass);
    case evidenceTab:
      return lookupLingo(TermEvidence);
    case subjectsTab:
      return lookupLingo(TermSubject);
    case projectsTab:
      //    if (activeTab === 2) // hw for now--export constants?
      return lookupLingo(TermEvidence);
    //      return lookupLingo(TermProject);
    case skillsTab:
    case portfoliosTab:
      return lookupLingo(TermSkill);
    default:
      // console.debug("getObjectName: invalid parameter");
      return "object";
  }
};

// todo: doesn't work..., need to work thorugh list, return true if something selected, rethink, redo--will need alot of functions like this to base buttons on state
// consider: a cleanup function if list gets too big (remove n/a values) -- consider monitoring the sizes of these lists to determine if there is a problem
const canBulkAction = () => {
  const [currentPage] = appStateData.contentState;
  if (componentStateData[currentPage] && componentStateData[currentPage].selectionState) return componentStateData[currentPage].selectionState.length > 0;
  return false;
};

const adjustSizesAndButtons = (bp1, bp2, doVertical = true) => {
  const [showDetailsPane] = appStateData.showDetails;
  const [showPreviewPane] = appStateData.showPreview;

  const detailsButton = showDetailsPane ? "Hide details" : "Show details";
  const previewButton = showPreviewPane ? "Hide preview" : "Show preview";

  let paneStyle = {};
  if (showDetailsPane) {
    // console.debug(`Content pane breakpoints: bp1: ${bp1} bp2: ${bp2}`);

    if (!bp1) paneStyle = { width: "calc(100vw - 680px)" };
    else if (!bp2) paneStyle = { width: "calc(100vw - 740px)" };
    else paneStyle = { width: "calc(100vw - 790px)" };
  }
  if (doVertical) {
    const [smallMode] = appStateData.smallHeaderState;
    if (smallMode && showPreviewPane) paneStyle = Object.assign(paneStyle, { height: "calc(100vh - 374px)" });
    else if (smallMode) paneStyle = Object.assign(paneStyle, { height: "calc(100vh - 165px)" });
    else if (showPreviewPane) paneStyle = Object.assign(paneStyle, { height: "calc(100vh - 429px)" });
  }

  return [paneStyle, detailsButton, previewButton];
};

// change detailsView externally to component. Top cases: Send message, view help
const changeDetailsView = (newView) => {
  const [, setViewMode] = detailsViewState;
  const [, updateShowDetailsPane] = appStateData.showDetails;
  setViewMode(newView);
  updateShowDetailsPane(true); // open if not already
};

const refreshData = (refreshCount) => {
  if (!refreshCount) return;

  axios.get("https://liftstaging.mylift.io/api/v1/user").then(
    (response) => {
      Object.assign(teacherInfo, response.data);
      // console.debug(`TeacherInfo loaded: ${JSON.stringify(teacherInfo)}`);
    },
    (error) => {
      console.debug(`LiFT Teacher API request failed: ${error}`);
    }
  );
  axios.get("https://liftstaging.mylift.io/api/v1/skills").then(
    (response) => {
      Object.assign(classData.realSkillList, response.data);
      console.debug(`SkillList loaded: ${JSON.stringify(classData.realSkillList)}`);

      // while we could make all teacher and class data state data, the added complexity has little beneft. Instead, just use global varialbes and forceUpdate when updated
      // force app to update
      const [FUD, setFUD] = fud;
      setFUD(FUD + 1);
    },
    (error) => {
      console.debug(`LiFT Skill API request failed: ${error}`);
    }
  );
};

const signOut = (signInState) => {
  localStorage.setItem("token", 0);
  const [, setSignedIn] = signInState;
  setSignedIn(false);
};

export default function App() {
  // handle darkMode first (must do before liftTheme is created), liftTheme, and classes
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const darkState = useState(prefersDarkMode);
  const [darkMode] = darkState;
  liftTheme = useMemo(() => createMuiTheme({ palette: { type: darkMode ? "dark" : "light" } }, liftThemeData), [darkMode]);
  const classes = useStyles(liftTheme);

  // LiFT color options
  const colorInfoState = useState(buildColorInfo());
  const [colorInfo] = colorInfoState;
  // let colorSet = useMemo(() => buildCustomColors(colorInfo), [colorInfo]);
  const colorState = useState(buildCustomColors(colorInfo));
  const [colorSet] = colorState;
  liftColorSet = colorSet;

  // create appStateData elements
  const contentState = useState(classesTab);
  const [currentPage] = contentState;
  const classState = useState(getFirstClass());
  const [currentClass] = classState;
  const projectState = useState(0); // getFirstProjectInClass(getFirstClass()));
  const [currentProject] = projectState;
  const rubricState = useState({ set: 0, currentLevel: 0 });
  const signInState = useState(true); // todo: use true for now--only have to sign in after signing out
  const [signedIn] = signInState;
  const navSelectState = useState([{ ID: getNavObjectIDByType(classesTab), selected: true }]); // set initial navigator highlight
  const navExpandState = useState([]); // use to open a nav hive on navigation
  const showDetails = useState(false);
  const [showDetailsPane] = showDetails;
  const showPreview = useState(false);
  const [showPreviewPane] = showPreview;
  const smallHeaderState = useState(false);
  const autoCloseState = useState(true);
  const autoOpenPortfoliosState = useState(true);
  const projectFilterState = useState(["active"]);
  const evidenceFilterState = useState(["all"]);
  const skillFilterState = useState(["all"]);
  const statusFilterState = useState(["all"]);
  const [smallMode] = smallHeaderState;
  const projectListRef = useRef(null); // to keep title bar in synch
  const studentListRef = useRef(null); // ditto

  // trying to refresh data on an as needed schedule
  const d = new Date(),
    today = d.getDate();
  const [refreshDaily, setRefreshDaily] = useState(today); // change to force a daily refresh
  if (refreshDaily !== today) setRefreshDaily(today); // refreshDaily changes as day changes

  const searchState = useState(null);
  searchTermState = searchState;

  // https://reactjs.org/docs/hooks-faq.html recommends useReducer for this, but useState has worked perfectly so far--don't see any problems
  const forceUpdateState = useState(0); // hack to force updates at the app level--just use hack--actually better than passing around
  fud = forceUpdateState; // double hack: access when needed as a secret global variable. Seems to work! (consider removing from setAppState since didn't work using that approach)

  // gradeChangeState is for undo stack--currently shared, but decided that each page should have it's own stack
  const gradeChangeState = useState([]); // use to track grade changes (real app should commit changes periodically)

  // consolidate appStateData for easy parameter passing
  appStateData = setAppState(
    contentState,
    classState,
    projectState,
    rubricState,
    navSelectState,
    navExpandState,
    searchState,
    showDetails,
    showPreview,
    projectListRef,
    studentListRef,
    smallHeaderState,
    autoCloseState,
    autoOpenPortfoliosState,
    colorInfoState,
    colorState,
    projectFilterState,
    evidenceFilterState,
    skillFilterState,
    statusFilterState,
    signInState
  );
  console.debug(`App: Content page: ${currentPage} currentClass: ${currentClass} currentProject: ${currentProject}`);

  // this state is used to make contentPage components stateless by holding state at top app level
  // todo: could eliminate some of these states because not every component uses every state (pins, for example) [better to do later, systematically]
  // can't do in a loop or function, React is very particular about when and how useState is called (see https://reactjs.org/docs/hooks-rules.html)
  // Caution: React doesn't like it when a component changes a parent's state, so OK to pass appStateData if read only (confirm that no change state functions are unrolled)

  componentStateData[dashboardTab] = setComponentState(
    dashboardTab,
    appStateData,
    useState([]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[calendarTab] = setComponentState(
    calendarTab,
    appStateData,
    useState([]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[messagesTab] = setComponentState(
    messagesTab,
    appStateData,
    useState([]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[learnersTab] = setComponentState(
    learnersTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[groupsTab] = setComponentState(
    groupsTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[classExplorerTab] = setComponentState(
    classExplorerTab,
    appStateData,
    useState([]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[classesTab] = setComponentState(
    classesTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[subjectsTab] = setComponentState(
    subjectsTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[projectsTab] = setComponentState(
    projectsTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[evidenceTab] = setComponentState(
    evidenceTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[skillsTab] = setComponentState(
    skillsTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[portfoliosTab] = setComponentState(
    portfoliosTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[progressTab] = setComponentState(
    progressTab,
    appStateData,
    useState([{ ID: "init", selected: true }]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );
  componentStateData[trashTab] = setComponentState(
    trashTab,
    appStateData,
    useState([]),
    useState([]),
    useState([]),
    gradeChangeState,
    useState([]),
    useState(0),
    useState(0),
    useState([]),
    useState(false),
    useState({ pageMap: [], isExpandable: false, rows: 0, columns: 0 })
  );

  // set details state
  // todo: don't hardwire these values, set real defaults
  const detailsStateData = setDetailsPaneState(appStateData, gradeChangeState, useState(0), useState(0), useState(402), useState(0));
  detailsViewState = detailsStateData.viewModeState;

  // useEffect(() => {
  //   fetch("https://liftstaging.mylift.io/api/v1/login", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       email: "gerardo.aragon+tp01@edify.cr",
  //       password: "Test123*",
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         // set the httpOnly cookie here that will hold token that gets appended to each API call
  //         // something like:
  //         localStorage.setItem("token", result.auth_token);
  //       },
  //       (error) => {
  //         console.debug(`LiFT signin failed: ${error}`);
  //       }
  //     );
  // }, []);

  // useEffect(() => {
  //   axios
  //     .post("https://liftstaging.mylift.io/api/v1/login", {
  //       email: "gerardo.aragon+tp01@edify.cr",
  //       password: "Test123*",
  //     })
  //     .then((response) => {
  //       localStorage.setItem("token", response.data.auth_token);
  //     });
  // }, []);

  // request data from server
  useEffect(() => {
    refreshData(1);
  }, []); // [refreshDaily]

  // handle keyboard events
  useEventListener("keydown", handleKeyDown);

  // show/hide with details and preview panes
  // console.debug(`App redraw: ${showDetailsPane}`);
  const detailsPane = showDetailsPane ? (
    <Suspense fallback={renderLoader()}>
      <DetailsPane classData={classData} detailsState={detailsStateData} />
    </Suspense>
  ) : (
    <></>
  );
  const previewPane = showPreviewPane ? (
    <Suspense fallback={renderLoader()}>
      <PreviewPane classData={classData} appStateData={appStateData} />
    </Suspense>
  ) : (
    <></>
  );

  // set style, make room for smallMode titleBar and statusBar
  let contentStyle = { display: "flex", flexWrap: "nowrap", alignItems: "flexStart" };
  if (smallMode) contentStyle = Object.assign(contentStyle, { marginTop: 80 });
  // else contentStyle = Object.assign(contentStyle, { marginTop: 136 });

  // the body
  let theBody;
  if (!signedIn) {
    theBody = (
      <div className="App">
        <main>
          <Suspense fallback={renderLoader()}>
            <SignIn signInState={signInState} />
          </Suspense>
        </main>
      </div>
    );
  } else {
    // using header, nav, main, and aside tags for accessibility
    theBody = (
      <div className="App">
        <header>
          <TitleBar teacherName={teacherInfo.name} classData={classData} appStateData={appStateData} darkState={darkState} />
          <StatusBar classData={classData} appStateData={appStateData} useSmall={smallMode} />
        </header>
        <div className={classes.bodyStyle} style={contentStyle}>
          <nav>
            <NavigatorPane classData={classData} appStateData={appStateData} />
          </nav>
          <main style={{ display: "flex", flexDirection: "column" }}>
            {changeContentPage(currentPage)}
            <section>{previewPane}</section>
          </main>
          <aside>{detailsPane}</aside>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={liftTheme}>
      <CssBaseline /> {theBody}
    </ThemeProvider>
  );
}

// try to limit import of appStateData and componentStateData to utilitiesEvents.js and detailsPane (which needs to know bulkMode)
export { liftTheme, liftColorSet, appStateData, searchTermState, signOut, fud, componentStateData, changeContentPage, changeDetailsView, getContentPageNavTT, adjustSizesAndButtons, canBulkAction };
