import React, { useState } from "react"
import { liftTheme, getContentPageNavTT } from "./App";
import { classesTab } from "./utilitiesConstants";
import { globalHandleClick } from "./utilitiesEvents"
import { getClassTT } from "./utilitiesComponents"
import { rd, classNameText, rdPreferShort, rdDefaultWidth } from "./utilitiesResponsiveData"
import { lookupLingo, TermChat, TermClass, TermLearner, TermPortfolio } from "./customizable"
import { getGroupIDfromClass } from "./data"
import { Toolbar, IconButton, Tooltip, Button, Typography, Container, Menu, MenuItem, Divider, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CONFIG } from './env/featureFlags'

// use makeStyles to map component element styles to LiFT theme plus other non-theme styles
// orchestration: small elements first (shorter), large elements like logo and teacher name second (short), overall bar height last (standard)
// not especially obvious if react is respecting the timings
const useStyles = makeStyles(theme => ({
    statusBarStyle: {
        display: "flex",
        position: "fixed",
        top: 108,
        height: 84,
        backgroundColor: theme.palette.background.default,
        // backgroundColor: theme.palette.common.black,     // for testing only
        margin: 0,
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[2],    // 1 is too sharp
        userSelect: "none",
        alignItems: "center",
        justifyContent: "flex-start",
        overflow: "hidden",
        zIndex: theme.zIndex.appBar,
        transition: "height " + theme.transitions.duration.complex + "ms ease,",
    },
    statusBarStyleSmall: {
        top: 64,
        height: 72,
    },
    statusBarLHSStyle: {
        display: "flex",
        position: "fixed",
        alignItems: "center",
        width: "calc(100% - 120px)",
    },
    statusBarRHSStyle: {
        position: "absolute",
        right: theme.spacing(2),
        width: 80,
        height: 80,
        transition: "width " + theme.transitions.duration.shorter + "ms ease",
        [theme.breakpoints.down(650)]: { display: "none" },
    },
    statusBarRHSStyleSmall: {
        width: 64,
        height: 64,
    },
    statusBarMenuGroup: {
        display: "flex",
        width: "75%",
        [theme.breakpoints.down(1620)]: { width: "min(100% - 250px, 80%)" },
        [theme.breakpoints.down(1200)]: { width: "90%" },
        [theme.breakpoints.down(1000)]: { width: "100%" },
        transition: "width " + theme.transitions.duration.shorter + "ms ease",
    },
    classButtonGroupStyle: {
        marginTop: 14,
        height: 82,
        transition: "width " + theme.transitions.duration.shorter + "ms ease",
    },
    classButtonGroupStyleSmall: {
        marginTop: -28,     // not sure why this is needed, suggests a style is wrong
        height: 38,
    },
    classButtonStyle: {
        position: "relative",
        width: "94%",
        height: 45,
        border: "none",
        outline: "none",
        borderRadius: 0,
        color: theme.palette.text.primary, // theme.palette.common.black,
        backgroundColor: theme.palette.action.disabledBackground, // theme.palette.grey[300],
        marginRight: theme.spacing(2),
        fontSize: theme.sizing.text.statusBarClassButton,
        lineHeight: "90%",
        [theme.breakpoints.down(1340)]: { fontSize: theme.sizing.text.statusBarClassButton2 },
        [theme.breakpoints.down(1225)]: { fontSize: theme.sizing.text.statusBarClassButton3 },
        transition: "font-size " + theme.transitions.duration.short + "ms ease, width " + theme.transitions.duration.short + "ms ease",
    },
    classButtonStyleSmall: {
        height: 23,
    },
    classDropdownButtonStyle: {
        position: "relative",
        width: "94%",
        height: 22,
        border: "none",
        outline: "none",
        borderRadius: 0,
        color: theme.palette.text.primary, // theme.palette.common.black,
        backgroundColor: theme.palette.action.disabledBackground, // theme.palette.grey[300],
        marginRight: theme.spacing(2),
        marginTop: 2,
        fontSize: theme.sizing.text.statusBarClassDropdownButton,
        transition: "width " + theme.transitions.duration.shorter + "ms ease",
    },
    classDropdownButtonStyleSmall: {
        height: 18,
    },
    statusBarUrgentStyle: {
        width: "25%",
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down(1620)]: { width: "max(250px, 20%)" },
        [theme.breakpoints.down(1200)]: { width: "10%" },
        [theme.breakpoints.down(1000)]: { display: "none" },
        transition: "width " + theme.transitions.duration.shorter + "ms ease",
    },
    statusBarUrgentTextStyle: {
        fontSize: theme.sizing.text.statusBarUrgentFont,
        lineHeight: "1.2",
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease"
    },
    statusBarUrgentTextStyleSmall: {
        fontSize: theme.sizing.text.statusBarUrgentSmallFont,
        lineHeight: "1.0",
    },
    statusBarNavUpStyle: {
        position: "absolute",
        border: 0,
        margin: 1,
        padding: 0,
        background: "transparent",
        color: theme.palette.icons.statusBar.main,
        fontSize: theme.sizing.icons.statusBar,
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease",
        top: 2,
        left: 24
    },
    statusBarNavUpStyleSmall: {
        fontSize: theme.sizing.icons.statusBarSmall,
        top: -2,
        left: 16
    },
    statusBarNavDownStyle: {
        position: "absolute",
        border: 0,
        margin: 1,
        padding: 0,
        background: "transparent",
        color: theme.palette.icons.statusBar.main,
        fontSize: theme.sizing.icons.statusBar,
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease",
        top: 46,
        left: 24
    },
    statusBarNavDownStyleSmall: {
        fontSize: theme.sizing.icons.statusBarSmall,
        top: 30,
        left: 16
    },
    statusBarNavLeftStyle: {
        position: "absolute",
        border: 0,
        margin: 1,
        padding: 0,
        background: "transparent",
        color: theme.palette.icons.statusBar.main,
        fontSize: theme.sizing.icons.statusBar,
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease",
        top: 24,
        left: 2
    },
    statusBarNavLeftStyleSmall: {
        fontSize: theme.sizing.icons.statusBarSmall,
        top: 13,
        left: -1
    },
    statusBarNavRightStyle: {
        position: "absolute",
        border: 0,
        margin: 1,
        padding: 0,
        background: "transparent",
        color: theme.palette.icons.statusBar.main,
        fontSize: theme.sizing.icons.statusBar,
        transition: "font-size " + theme.transitions.duration.shorter + "ms ease",
        top: 24,
        right: 3
    },
    statusBarNavRightStyleSmall: {
        fontSize: theme.sizing.icons.statusBarSmall,
        top: 13,
        right: 3
    },
    tileStyle: {
        position: "absolute",
        width: 400,
        minHeight: 200,
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        textAlign: "left",
        boxShadow: theme.shadows[4],
    },
    tileNameStyle: {
        fontSize: theme.sizing.text.classTileClassName,
        fontWeight: "bold"
    },
    tileDataStyle: {
        fontSize: theme.sizing.text.classTileData
    },
    tileData2Style: {
        fontSize: theme.sizing.text.classTileData2
    },
    urgentStyle: {
        color: theme.palette.urgent.main
    },
    importantStyle: {
        color: theme.palette.important.main
    },
    recentStyle: {
        color: theme.palette.recent.main
    },
}));

const newPortfolioSubmissions = classList => classList.reduce((currentTotal, classObj) => currentTotal + ((classObj?.newPortfolioSubmissions ?? 0)), 0);
const totalPortfolioSubmissions = classList => classList.reduce((currentTotal, classObj) => currentTotal + ((classObj?.portfolioSubmissions ?? 0)), 0);
const newRequests = classList => classList.reduce((currentTotal, classObj) => currentTotal + ((classObj?.newReviewsRequested ?? 0)), 0);
const totalRequests = classList => classList.reduce((currentTotal, classObj) => currentTotal + ((classObj?.reviewsRequested ?? 0)), 0);
const unreadComments = classList => classList.reduce((currentTotal, classObj) => currentTotal + ((classObj?.commentsUnread) ?? 0), 0);
const totalComments = classList => classList.reduce((currentTotal, classObj) => currentTotal + ((classObj?.commentsUnresolved) ?? 0), 0);

// build responsive urgent text
const GetUrgentText = (line, classList) => {
    if (!classList || isNaN(line)) console.debug("GetUrgentText: missing or invalid parameters");

    const bp1 = useMediaQuery("(max-width:1620px)");
    const bp2 = useMediaQuery("(max-width:1200px)");
    let v1 = 0, v2 = 0;

    switch (line) {
        case 0:
            v1 = newPortfolioSubmissions(classList);
            v2 = totalPortfolioSubmissions(classList);
            if (bp2) return <span> {v1}, {v2}</span>;
            if (bp1) return <span> New: {v1}, Total: {v2}</span>;
            return <span><b> Portfolio submissions:</b> New: {v1}, Total: {v2}</span>;
        case 1:
            v1 = newRequests(classList);
            v2 = totalRequests(classList);
            if (bp2) return <span> {v1}, {v2}</span>;
            if (bp1) return <span> New: {v1}, Total: {v2}</span>;
            return <span> <b>Review requests:</b> New: {v1}, Total: {v2}</span>;
        case 2:
            v1 = unreadComments(classList);
            v2 = totalComments(classList);
            if (bp2) return <span> {v1}, {v2}</span>;
            if (bp1) return <span> Unread: {v1}, Unresolved: {v2}</span>;
            return <span><b> Comments:</b> Unread: {v1}, Unresolved: {v2}</span>;

        default:
            return <></>;
    }
}

const ClassButton = ({ classObj, classes, handleClick, useSmall, ...props }) => {
    if (!classObj || !classes || !handleClick) console.debug("ClassButton: missing or invalid parameters");

    // build the ddl menu button label
    let reviewsRequested, commentsUnresolved, portfolioSubmissions;
    if (classObj.reviewsRequested)
        reviewsRequested = <Tooltip title="Reviews requested"><span><FontAwesomeIcon icon="exclamation-circle" className={classes.urgentStyle} /></span></Tooltip>;
    if (classObj.commentsUnresolved)
        commentsUnresolved = <Tooltip title={`Unresolved ${lookupLingo(TermChat, false, true)}`}><span><FontAwesomeIcon icon="comment-dots" className={classes.importantStyle} /></span></Tooltip>;
    if (classObj.portfolioSubmissions)
        portfolioSubmissions = <Tooltip title={`${lookupLingo(TermPortfolio, true)} submissions`}><span><FontAwesomeIcon icon="flag" className={classes.recentStyle} /></span></Tooltip>;
    const ddButtons = <div>{reviewsRequested} {commentsUnresolved} {portfolioSubmissions} <FontAwesomeIcon icon="caret-down" /></div>;

    const classID = getGroupIDfromClass(classObj.ID);

    // build the tooltip class tile
    const tooltipTile = getClassTT(classObj, classes);

    if (!classObj.name) return <></>;

    return <Container disableGutters={true}
        className={useSmall ? classes.classButtonGroupStyle + " " + classes.classButtonGroupStyleSmall : classes.classButtonGroupStyle}
        key={"classButtonGroup" + classID}>
        <Tooltip title={tooltipTile} enterDelay={1500}><Button className={classes.classButtonStyle} key={"classButton" + classID}
            onClick={ev => handleClick(ev, "link:" + classesTab.toString(), classObj.ID)} aria-label={`Click to jump to ${lookupLingo(TermClass)}`}>
            {rd(classObj, classNameText, false, rdDefaultWidth, rdPreferShort)}</Button></Tooltip>
        <Button key={"classDDLbutton" + classID} onClick={ev => handleClick(ev, "menu:", classID)} aria-haspopup={true} aria-label="Shortcut to urgent commands"
            className={useSmall ? classes.classDropdownButtonStyle + " " + classes.classDropdownButtonStyleSmall : classes.classDropdownButtonStyle} >
            {ddButtons}
        </Button >
    </Container >;
}

const StatusBar = ({ classData, appStateData, useSmall, ...props }) => {
    if (!classData || !appStateData || isNaN(useSmall)) console.debug("StatusBar: missing or invalid parameters");
    const classes = useStyles(liftTheme);
    const classList = classData.classList;

    // state hooks--the one source of truth
    const [anchorEl, setAnchorEl] = useState(null);
    if (!classData || !appStateData) return <></>;

    // click handlers
    const handleMenuClose = () => { setAnchorEl(null); }
    const handleClick = (ev, objectID, otherID) => {
        // console.debug("StatusBar handleClick, Ev value: ${ev.target}, typeof: ${typeof ev.target}, objectID: ${objectID}, otherID: ${otherID);

        // try global handler first
        if (globalHandleClick(ev, objectID, otherID)) return;

        // local handlers
        if (typeof objectID === "undefined") {
            setAnchorEl(ev.currentTarget);  // must be a menu
        }
    }

    const objectName = getContentPageNavTT();

    // todo: button menu is placeholder data, connect to real data based on class clicked
    return (
        <Toolbar disableGutters={true}>
            <Container className={useSmall ? classes.statusBarStyle + " " + classes.statusBarStyleSmall : classes.statusBarStyle} maxWidth={false}>
                <Container className={classes.statusBarLHSStyle} maxWidth={false}>
                    <div className={classes.statusBarMenuGroup}>
                        {classList.map((classObj, index) => <ClassButton key={classObj.ID.toString()} classObj={classObj} classes={classes} handleClick={handleClick} useSmall={useSmall} />)}
                        <Menu id="classDDLmenu" variant="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem key="1" onClick={handleMenuClose}>Check review requests</MenuItem>
                            <MenuItem key="2" onClick={handleMenuClose}>View {lookupLingo(TermChat, false, true)}</MenuItem>
                            <Divider key="3" />
                            <MenuItem key="4" onClick={handleMenuClose}>Getting started</MenuItem>
                            <MenuItem key="5" onClick={handleMenuClose}>First exploration</MenuItem>
                            <MenuItem key="6" onClick={handleMenuClose}>Group {lookupLingo(TermChat)}</MenuItem>
                        </Menu>
                    </div>
                    <Container className={classes.statusBarUrgentStyle}>
                        <Typography className={useSmall ? classes.statusBarUrgentTextStyle + " " + classes.statusBarUrgentTextStyleSmall : classes.statusBarUrgentTextStyle} >
                            <Tooltip title="Reviews requested"><span><FontAwesomeIcon icon="exclamation-circle" className={classes.urgentStyle} /></span></Tooltip>{GetUrgentText(1, classList)}</Typography>
                        <Typography className={useSmall ? classes.statusBarUrgentTextStyle + " " + classes.statusBarUrgentTextStyleSmall : classes.statusBarUrgentTextStyle} >
                            <Tooltip title={`Unresolved ${lookupLingo(TermChat, false, true)}`}><span><FontAwesomeIcon icon="comment-dots" className={classes.importantStyle} /></span></Tooltip>{GetUrgentText(2, classList)}</Typography>
                        <Typography className={useSmall ? classes.statusBarUrgentTextStyle + " " + classes.statusBarUrgentTextStyleSmall : classes.statusBarUrgentTextStyle} >
                            <Tooltip title={`${lookupLingo(TermPortfolio, true)} submissions in last week`}><span><FontAwesomeIcon icon="flag" className={classes.recentStyle} /></span></Tooltip>{GetUrgentText(0, classList)}</Typography>
                    </Container>
                </Container>
                { CONFIG.showArrows ? <Container className={useSmall ? classes.statusBarRHSStyle + " " + classes.statusBarRHSStyleSmall : classes.statusBarRHSStyle}>
                    <Tooltip title={`Previous ${lookupLingo(TermLearner)} (up arrow)`}><IconButton onClick={ev => handleClick(ev, "nav:ArrowUp")}
                        className={useSmall ? classes.statusBarNavUpStyle + " " + classes.statusBarNavUpStyleSmall : classes.statusBarNavUpStyle}
                        aria-label={`Previous ${lookupLingo(TermLearner)}`}><FontAwesomeIcon icon="arrow-up" /></IconButton></Tooltip>
                    <Tooltip title={`Next ${lookupLingo(TermLearner)} (down arrow)`}><IconButton onClick={ev => handleClick(ev, "nav:ArrowDown")}
                        className={useSmall ? classes.statusBarNavDownStyle + " " + classes.statusBarNavDownStyleSmall : classes.statusBarNavDownStyle}
                        aria-label={`Next ${lookupLingo(TermLearner)}`}><FontAwesomeIcon icon="arrow-down" /></IconButton></Tooltip>
                    <Tooltip title={`Prevous ${objectName} (left arrow)`}><IconButton onClick={ev => handleClick(ev, "nav:ArrowLeft")}
                        className={useSmall ? classes.statusBarNavLeftStyle + " " + classes.statusBarNavLeftStyleSmall : classes.statusBarNavLeftStyle}
                        aria-label={`Prevous ${objectName}`}><FontAwesomeIcon icon="arrow-left" /></IconButton></Tooltip>
                    <Tooltip title={`Next ${objectName} (right arrow)`}><IconButton onClick={ev => handleClick(ev, "nav:ArrowRight")}
                        className={useSmall ? classes.statusBarNavRightStyle + " " + classes.statusBarNavRightStyleSmall : classes.statusBarNavRightStyle}
                        aria-label={`Next ${objectName}`}><FontAwesomeIcon icon="arrow-right" /></IconButton></Tooltip>
                </Container > : <></> }
            </Container >
        </Toolbar >
    );
}

export default StatusBar;
