// Builds out navigation pane data

import { CONFIG } from './env/featureFlags'
import {
    separatorTab, learnersTab, groupsTab, classesTab, subjectsTab, projectsTab, evidenceTab,
    skillsTab, portfoliosTab, progressTab
} from "./utilitiesConstants"
import { classList, studentList } from "./data"

const navigatorPaneData = [];

function addNav(configValue, data) {
    if (configValue) { navigatorPaneData.push(data); }
}

addNav(CONFIG.showLearnersTab, {
    name: "Learners",
    type: learnersTab,
    ID: 5,
    icon: "users",
    childIcon: "user",
    data: studentList,
});
addNav(CONFIG.showGroupsTab, {
    name: "Groups",
    type: groupsTab,
    ID: 17,
    icon: "users",
    childIcon: "users",
});

// Handle separator
addNav(CONFIG.showLearnersTab || CONFIG.showGroupsTab, {
    type: separatorTab,
    ID: 6
})

addNav(true, {
    name: "Classes",
    type: classesTab,
    ID: 8,
    icon: "th",
    childIcon: "th-large",
    canEdit: CONFIG.showClassesAddEditButton,
    data: classList,
});
addNav(CONFIG.showSubjectsTab, {
    name: "Subjects",
    type: subjectsTab,
    ID: 9,
    icon: "th-list",
});
addNav(CONFIG.showProjectsTab, {
    name: "Projects",
    type: projectsTab,
    ID: 10,
    icon: "cubes",
    canEdit: true,
    data: classList,
});
addNav(CONFIG.showEvidencesTab, {
    name: "Evidence",
    type: evidenceTab,
    ID: 11,
    icon: "eye",
    data: classList,
});
addNav(CONFIG.showSkillsTab, {
    name: "Skills",
    type: skillsTab,
    ID: 12,
    icon: "check-circle",
})
addNav(CONFIG.showPortfoliosTab, {
    name: "Portfolios",
    type: portfoliosTab,
    ID: 13,
    icon: "suitcase",
    data: classList,
});
addNav(CONFIG.showProgressTab, {
    name: "Progress",
    type: progressTab,
    ID: 14,
    icon: "chart-line",
});

export { navigatorPaneData };
