// "David" comment means that it was explicitly mentioned by Davids "scenario" doc

// abstracted interface "contract" for the test data to reduce coupling of UI with test data and ID assignment scheme
// note that (nearly) every object has an ID and name. To test the code for robustness, easiest way is to remove all the IDs and names--should at least not crash
// consider: might be better to freeze these--treating as immutable, but made a few mistakes (changing grades) which has lead to problems

// assignment status
const Unassigned = 0,
  Active = 1,
  Complete = 2,
  Extracurricular = 3;

// types of access functions
// getItemID - use to ID objects, have unique map values
// isAnObject - boolean, based on name or ID
// isObjectInAnotherObject - boolean for containment/ownership
// getObject - get based on id, first object in a set, etc.
// findObject - same as get, but seaching is required
// getFirstObject, getNextObject - for iteration or defaults

// consolidated getID functions--initally each component had it's own but better to make consistent across all components since ID schemee is used by globalHandleClick
const getItemID = (studentID, itemID) => (studentID * 1000 + itemID).toString(); // first ID doesn't have to be studentID but usually is
const getItem3ID = (studentID, groupID, itemID) => {
  if (isNaN(groupID)) return (studentID * 1000 + itemID).toString();
  else return (studentID * 1000000 + groupID * 1000 + itemID).toString();
};
const getGroup2ID = (parentID, groupID) => "group" + ((parentID ?? 0) * 1000 + groupID).toString();
const getGroupID = (groupID) => "group" + groupID.toString();

// note that classIDs can be negative
const getGroupIDfromClass = (classID) => Math.abs(classID).toString();
const getLevelIDfromClass = (classID, itemID) => (Math.abs(classID) * 1000 + itemID).toString();
const getLevelIDfromClass2 = (itemID, classID) => (itemID * 1000 + Math.abs(classID)).toString();

const getPrimaryIDfromItemID = (itemID) => Math.floor(Number(itemID) / 1000);
const getSecondaryIDfromItemID = (itemID) => Number(itemID) % 1000;
const getClassIDfromItemID = (itemID) => (Number(itemID) % 1000) * -1;

// using the conventions here, we can tell if a classList item is a class, project, or step using either its name or ID
const isClassName = (item) => !!item.name;
const isProjectName = (item) => !!item.projectName; // unlike all other objects, projects and steps don't have a name field
const isStepName = (item) => !!item.stepName;

const isClassID = (itemID) => !!(itemID < 0); // class IDs are negative
const isProjectID = (itemID) => !!(itemID >= getFirstProject() && itemID === Math.floor(itemID)); // project IDs are positive whole numbers
const isStepID = (itemID) => !!(itemID > 0 && itemID !== Math.floor(itemID)); // step IDs are positive decimal numbers
const isStudentID = (itemID) => !!(itemID >= getFirstStudent() && itemID < getFirstStudent() + 99);
const isGradeID = (itemID) => itemID >= getFirstGrade() && itemID < getFirstGrade() + 99;
const isRatingID = (itemID) => itemID >= getFirstRating() && itemID < getFirstRating() + 10;
const isEvidenceID = (itemID) => !!(itemID >= getFirstEvidence() && itemID < getFirstEvidence() + 99);
const isSkillID = (itemID) => !!(itemID >= getFirstSkill() && itemID < getFirstSkill() + 99);

const isProjectInClass = (classID, projectID) => {
  if (!isProjectID(projectID)) return false; // might be a Part
  return Math.abs(classID) === Math.floor(projectID / 10);
}; // project IDs are 10*abs(classID) + project number

const isStepInClassProject = (classID, projectID, stepID) => {
  if (!isProjectInClass(classID, Math.floor(stepID))) return false;
  // console.debug(`isStepInClassProject2: classID ${classID} projectID: ${projectID} stepID: ${stepID}`);
  return projectID === Math.floor(stepID); // steps add decimal numbers to projects
};

const getClassIDfromProjectID = (projectID) => {
  return -Math.floor(projectID / 10);
};

// helpers to find first, last, next, prev objects
const getFirstStudent = () => 101;
const getFirstClass = () => -201;
const getFirstProject = () => 2011;
const getFirstPage = () => 201;
const getFirstEvidence = () => 301;
const getFirstSkill = () => 401;
const getFirstGrade = () => 501;
const getFirstRating = () => 1;

const isFirstClass = (classID) => Number(classID) === getFirstClass();
const isLastClass = (classID) => Number(classID) === -206; // hardwire for now
const getNextClass = (classID) => classID - 1;
const getPreviousClass = (classID) => classID + 1;
const getFirstProjectInClass = (classID) => Number(classID) * -10 + 1;
const isFirstProjectInClass = (classID, projectID) => Number(classID) === getFirstProjectInClass(classID);
const isLastProjectInClass = (classID, projectID) => Number(projectID) === getFirstProjectInClass(classID) + 2; // hardwire for now
const getNextProjectInClass = (classID, projectID) => projectID + 1;
const getPreviousProjectInClass = (classID, projectID) => projectID - 1;

const getGrade = (itemID) => itemID - getFirstGrade() + 1; // because gradeIDs are new grade + getFirstGrade--todo: consider ID encode/decode functions to encapsulate
const getRating = (itemID) => itemID - getFirstRating() + 1;

const isParentSkill = (skillInfo) => !!skillInfo.parentSkill;

// get by ID, taking advantage that IDs are sequential in test data.
const getStudentByID = (studentList, studentID) => studentList[studentID - getFirstStudent()];
const getClassByID = (studentClassList, classID) => studentClassList[Math.abs(classID - getFirstClass())];
const getClassGradesByID = (studentClassList, classID) => studentClassList[Math.abs(classID - getFirstClass())].grades; // class IDs are negative
const getEvidenceByID = (evidenceList, evidenceID) => evidenceList[evidenceID - getFirstEvidence()];
const getSkillByID = (skillList, skillID) => skillList[skillID - getFirstSkill()];

// find by ID, not assuming that IDs are sequential which is the case for classes, steps, and projects. All real code should be find instead of get
// for findClassByProjectID, note the relationships between project IDs and class IDs.
const findClassByID = (classList, classID) => classList.find((classObj) => Number(classObj.ID) === Number(classID));
const findClassByProjectID = (classList, projectID) => {
  const classID = -(projectID / 10);
  return classList.find((classObj) => Number(classObj.ID) === Number(classID));
};
const findStepByID = (classList, stepID) => classList.find((classObj) => Number(classObj.ID) === Number(stepID));
const findProjectByID = (classList, projectID) => classList.find((classObj) => Number(classObj.ID) === Number(projectID));
const findEvidenceByID = (evidenceList, evidenceID) => evidenceList.find((evidenceID) => evidenceID === Number(evidenceID));

// React mapID functions to make unique
const getClassIDfromMapID = (classIDstr) => {
  const classID = Number(classIDstr);
  return -classID; // when using classID as a map ID, using abs, so this just reverses that
};

// set ID = 400
const skillList = [
  {
    name: "Self-awareness",
    ID: 401,
    parentSkill: true,
    graduationRequirement: true,

    // move to student
    studentsApproved: 2,
    studentsAssigned: 3,
    studentsCompleted: 2,
    activityCount: 5,

    // eliminate
    gradesByStudent: [95, 94, 98],
  },
  {
    name: "Reflection",
    ID: 402,
    parentSkill: false,
    studentsApproved: 0,
    studentsAssigned: 3,
    studentsCompleted: 2,
    activityCount: 4,
    gradesByStudent: [88, 87, 89],
  },
  {
    name: "My best self",
    ID: 403,
    parentSkill: false,
    studentsApproved: 1,
    studentsAssigned: 1,
    studentsCompleted: 1,
    activityCount: 2,
    gradesByStudent: [86, 84, 86],
  },
  {
    name: "Project mgmt",
    ID: 404,
    parentSkill: true,
    studentsApproved: 2,
    studentsAssigned: 3,
    studentsCompleted: 0,
    activityCount: 1,
    gradesByStudent: [82, 82, 85],
  },
  {
    name: "Manage work",
    ID: 405,
    parentSkill: false,
    studentsApproved: 3,
    studentsAssigned: 3,
    studentsCompleted: 3,
    gradesByStudent: [78, 79, 78],
  },
  {
    name: "Manage time",
    ID: 406,
    parentSkill: false,
    studentsApproved: 0,
    studentsAssigned: 3,
    studentsCompleted: 1,
    gradesByStudent: [85, 83, 83],
  },
  {
    name: "Motivation",
    ID: 407,
    parentSkill: false,
    studentsApproved: 2,
    studentsAssigned: 3,
    studentsCompleted: 1,
    graduationRequirement: true,
    gradesByStudent: [72, 78, 77],
  },
  {
    name: "Improvement",
    ID: 408,
    parentSkill: true,
    studentsApproved: 2,
    studentsAssigned: 3,
    studentsCompleted: 2,
    graduationRequirement: true,
    gradesByStudent: [73, 77, 75],
  },
  {
    name: "Understanding",
    ID: 409,
    parentSkill: false,
    studentsApproved: 1,
    studentsAssigned: 3,
    studentsCompleted: 2,
  },
  {
    name: "Use feedback",
    ID: 410,
    parentSkill: false,
    studentsApproved: 1,
    studentsAssigned: 3,
    studentsCompleted: 2,
  },
];

// set ID = 300
const evidenceList = [
  {
    name: "Escape room analysis",
    className: "Full class name 1", // does this still make sense? (confirm--might be used (unnecessessarily) in a tooltip)
    projectName: "Full project name 1", // does this still make sense?
    ID: 301,
    assignedState: Complete,
    dueDate: "1/15/21",
    learners: 18,
    learnersCompleted: 18,
    skills: [401, 402], // assigned skills--in sorted order
  },
  {
    name: "Lost mummy reflection",
    className: "Full class name 2",
    projectName: "Full project name 2",
    ID: 302,
    assignedState: Active,
    dueDate: "1/18/21",
    learners: 18,
    learnersCompleted: 3,
    reviewsRequested: 2,
    commentsUnresolved: 5,
    unlockRequests: 2,
    skills: [401],
  },
  {
    name: "Puzzle reflection",
    className: "Full class name 3",
    projectName: "Full project name 3",
    ID: 303,
    assignedState: Active,
    dueDate: "1/21/21",
    learners: 18,
    learnersCompleted: 3,
    reviewsRequested: 2,
    commentsUnresolved: 3,
    unlockRequests: 2,
    skills: [402, 403],
  },
  {
    name: "Escape room creation",
    className: "Full class name 4",
    projectName: "Full project name 4",
    ID: 304,
    assignedState: Unassigned,
    dueDate: "1/24/21",
    learners: 18,
    learnersCompleted: 3,
    reviewsRequested: 2,
    commentsUnresolved: 3,
    unlockRequests: 2,
    skills: [403, 404],
  },
  {
    name: "Escape room comparisions",
    className: "Full class name 5",
    projectName: "Full project name 5",
    ID: 305,
    assignedState: Unassigned,
    skills: [405],
  },
  {
    name: "Bonus album",
    className: "Full class name 5",
    projectName: "Full project name 5",
    ID: 306,
    assignedState: Extracurricular,
    dueDate: "1/21/21",
    learners: 18,
    learnersCompleted: 3,
    reviewsRequested: 2,
    commentsUnresolved: 3,
    unlockRequests: 2,
    skills: [406],
  },
];

// for consistency, keep plural tags here but really singular
// set ID = 100
const studentList = [
  {
    name: "Elizabeth Montgomery",
    firstName: "Elizabeth",
    nickName: "Betty",
    ID: 101,

    evidenceCompleted: 1, // totals computed based on evidence state below
    evidenceAssigned: 4,
    evidenceOverdue: 1,
    skillsCompleted: 5,
    skillsAssigned: 8,
    reviewsRequested: 1,
    commentsUnresolved: 1,
    evidenceList: [302], //, 302, 303, 304, 305], // hack: should really go through skills for tagging, but more trouble than it's worth for test data
    skillRatings: [4, 4, 4, 4, 4, 4, 4, 3, 4, 3, 4, 2, 2, 4, 2, 2], // hack: used for histogram, should be provided by server--these are "real" but the others below are fake (don't match)

    portfolios: [
      {
        // David: portfolio scores and ratings
        ID: 401, // matches skill ID
        evidence: [301, 302, 303], // IDs for evidence in portfolio   // David: portfoilio curation is building this list
      },
      {
        ID: 402,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 403,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 404,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 405,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 406,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 407,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 408,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 409,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
    ],

    projects: [
      {
        ID: 2011,
      },
      {
        ID: 2012,
      },
      {
        ID: 2013,
      },
    ],

    classes: [
      {
        skillsApproved: 6,
        skillsAssigned: 7,
        classGrade: 92,
        grades: [90, 95, 91, 88, 82, 99, 90, 95, 91, 88, 82, 99], // David
      },
      {
        skillsApproved: 6,
        skillsAssigned: 9,
        classGrade: 90,
        grades: [86, 95, 91, 88, 82, 95, 82, 95, 91, 88, 82, 80],
      },
      {
        skillsApproved: 4,
        skillsAssigned: 8,
        classGrade: 89,
        grades: [82, 95, 91, 88, 82, 80, 92, 95, 91, 88, 82, 88],
      },
      {
        skillsApproved: 7,
        skillsAssigned: 8,
        classGrade: 88,
        grades: [95, 95, 91, 88, 82, 92, 90, 95, 91, 88, 82, 99],
      },
      {
        skillsApproved: 1,
        skillsAssigned: 3,
        classGrade: 87,
        grades: [92, 95, 91, 88, 82, 88, 95, 95, 91, 88, 82, 92],
      },
      {
        skillsApproved: 8,
        skillsAssigned: 10,
        classGrade: 85,
        grades: [90, 95, 91, 88, 82, 78, 92, 95, 91, 88, 82, 88],
      },
    ],

    skills: [
      {
        ID: 401,
        rating: 4,
        score: 95,
        approved: true,
        approvedDate: "12/9/20",
        approvedClass: "Class 1 name",
        approvedTeacher: "Charles Smith",
        graduationRequirement: true,
        recentUpdates: 1,
        evidenceInPortfolio: 4,
        evidenceAssigned: 8,
        status: 5,
        addedByStudent: true,
        activityCount: 1,
        evidenceList: [301, 302],
      },
      {
        ID: 402,
        rating: 4,
        score: 88,
        approved: true,
        approvedDate: "11/15/20",
        approvedClass: "Class 1 name",
        approvedTeacher: "Charles Smith",
        recentUpdates: 2,
        evidenceInPortfolio: 3,
        evidenceAssigned: 7,
        status: 5,
        activityCount: 2,
        evidenceList: [301, 303],
      },
      {
        ID: 403,
        rating: 4,
        score: 86,
        approved: false,
        graduationRequirement: true,
        recentUpdates: 0,
        reviewsRequested: 2,
        commentsUnresolved: 1,
        evidenceInPortfolio: 3,
        evidenceAssigned: 6,
        status: 3,
        addedByStudent: true,
        activityCount: 1,
        evidenceList: [302, 303],
      },
      {
        ID: 404,
        rating: 4,
        score: 82,
        reviewsRequested: 2,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 4,
        status: 3,
        evidenceList: [304],
      },
      {
        ID: 405,
        rating: 3,
        score: 78,
        graduationRequirement: true,
        reviewsRequested: 2,
        recentUpdates: 1,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 8,
        status: 3,
        addedByStudent: true,
        evidenceList: [304, 305],
      },
      {
        ID: 406,
        rating: 4,
        score: 85,
        approved: true,
        approvedDate: "11/9/20",
        approvedClass: "Class 2 name",
        approvedTeacher: "Bob Jones",
        graduationRequirement: true,
        reviewsRequested: 0,
        commentsUnresolved: 0,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 5,
        evidenceList: [303],
      },
      {
        ID: 407,
        rating: 2,
        score: 72,
        recentUpdates: 0,
        reviewsRequested: 1,
        commentsUnresolved: 1,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 2,
        addedByStudent: true,
        evidenceList: [302, 305],
      },
      {
        ID: 408,
        rating: 2,
        score: 73,
        recentUpdates: 1,
        reviewsRequested: 0,
        commentsUnresolved: 1,
        evidenceInPortfolio: 1,
        evidenceAssigned: 4,
        status: 2,
        evidenceList: [301, 304, 305],
      },
      {
        ID: 409,
        rating: 2,
        score: 66,
        recentUpdates: 0,
        reviewsRequested: 1,
        commentsUnresolved: 1,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 2,
        addedByStudent: true,
        evidenceList: [304, 305],
      },
    ],

    evidence: [
      {
        ID: 301,
        assignedState: 2,
        lastUpdated: "1/10 12:40 PM",
        status: 8, // David
        score: 94, // David
        rating: 4,

        assignedSkills: [
          {
            ID: 401,
            weight: 0,
            rating: 4, // David
            score: 94, // David
            inPortfolio: true, // need this too, not yet using
          },
          {
            ID: 402,
            weight: 0,
            rating: 4,
            score: 92,
          },
        ],
      },
      {
        ID: 302,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 7,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 401,
            weight: 0,
            rating: 4, // David
            score: 94, // David
          },
        ],
      },
      {
        ID: 303,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 6,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 402,
            weight: 0,
            rating: 4, // David
            score: 92, // David
          },
          {
            ID: 403,
            weight: 0,
            rating: 4,
            score: 90,
          },
        ],
      },
      {
        ID: 304,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 5,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 403,
            weight: 0,
            rating: 4, // David
            score: 90, // David
          },
          {
            ID: 404,
            weight: 0,
            rating: 3,
            score: 89,
          },
        ],
      },
      {
        ID: 305,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 4,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 405,
            weight: 0,
            rating: 3, // David
            score: 89, // David
          },
        ],
      },
      {
        ID: 306,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 3,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 406,
            weight: 0,
            rating: 2, // David
            score: 79, // David
          },
        ],
      },
    ],
    // evidence2: [
    //   {
    //     ID: 301,
    //     assignedState: 2,
    //     lastUpdated: "1/10 12:40 PM",
    //     score: 94,
    //     rating: 4,
    //     status: 5,
    //   },
    //   {
    //     ID: 302,
    //     assignedState: 1,
    //     lastUpdated: "1/13 9:32 AM",
    //     score: 88,
    //     rating: 4,
    //     reviewsRequested: 0,
    //     commentsUnresolved: 1,
    //     status: 7,
    //   },
    //   {
    //     ID: 303,
    //     assignedState: 1,
    //     lastUpdated: "1/16 8:25 AM",
    //     score: 86,
    //     rating: 4,
    //     reviewsRequested: 5,
    //     commentsUnresolved: 0,
    //     status: 6,
    //   },
    //   {
    //     ID: 304,
    //     assignedState: 1,
    //     score: 79,
    //     rating: 3,
    //     lastUpdated: "1/19 2:41 AM",
    //     unlockRequested: 1,
    //     status: 5,
    //   },
    //   {
    //     ID: 305,
    //     assignedState: 1,
    //     score: 79,
    //     rating: 3,
    //     lastUpdated: "1/19 2:41 AM",
    //     unlockRequested: 1,
    //     status: 4,
    //   },
    //   {
    //     ID: 306,
    //     assignedState: 1,
    //     score: 79,
    //     rating: 3,
    //     lastUpdated: "1/19 2:41 AM",
    //     unlockRequested: 1,
    //     status: 3,
    //   },
    // ],
  },
  {
    name: "Frank Ramirez",
    firstName: "Frank",
    nickName: "Frankie",
    ID: 102,

    evidenceCompleted: 1, // computed based on evidence state below
    evidenceAssigned: 4,
    skillsCompleted: 6,
    skillsAssigned: 8,
    reviewsRequested: 1,
    commentsUnresolved: 3,
    skillRatings: [4, 4, 4, 4, 4, 4, 3, 4, 2],
    evidenceList: [302], // , 302, 303, 304, 305, 306], // hack: should really go through skills for tagging, but more trouble than it's worth for test data

    portfolios: [
      {
        ID: 401, // matches skill ID
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 402,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 403,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 404,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 405,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 406,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 407,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 408,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 409,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
    ],

    projects: [
      {
        ID: 2011,
      },
      {
        ID: 2012,
      },
      {
        ID: 2013,
      },
    ],

    classes: [
      {
        skillsApproved: 4,
        skillsAssigned: 7,
        classGrade: 90,
        grades: [92, 84, 86, 78, 82, 91, 92, 84, 86, 78, 82, 91],
      },
      {
        skillsApproved: 4,
        skillsAssigned: 9,
        classGrade: 95,
        grades: [92, 84, 86, 78, 82, 91, 92, 84, 86, 78, 82, 91],
      },
      {
        skillsApproved: 4,
        skillsAssigned: 8,
        classGrade: 89,
        grades: [92, 84, 86, 78, 82, 91, 92, 84, 86, 78, 82, 91],
      },
      {
        skillsApproved: 5,
        skillsAssigned: 8,
        classGrade: 88,
        grades: [92, 84, 86, 78, 82, 91, 92, 84, 86, 78, 82, 91],
      },
      {
        skillsApproved: 0,
        skillsAssigned: 3,
        classGrade: 94,
        grades: [92, 84, 86, 78, 82, 91, 92, 84, 86, 78, 82, 91],
      },
      {
        skillsApproved: 4,
        skillsAssigned: 10,
        classGrade: 90,
        grades: [92, 84, 86, 78, 82, 91, 92, 84, 86, 78, 82, 91],
      },
    ],
    skills: [
      {
        ID: 401,
        rating: 4,
        score: 94,
        approved: true,
        approvedDate: "12/9/20",
        approvedClass: "Class 1 name",
        approvedTeacher: "Charles Smith",
        graduationRequirement: true,
        recentUpdates: 2,
        evidenceInPortfolio: 3,
        evidenceAssigned: 8,
        status: 5,
        activityCount: 1,
        evidenceList: [301, 302],
      },
      {
        ID: 402,
        rating: 4,
        score: 87,
        approvedDate: "11/15/20",
        approvedClass: "Class 1 name",
        approvedTeacher: "Charles Smith",
        recentUpdates: 2,
        evidenceInPortfolio: 2,
        evidenceAssigned: 7,
        status: 5,
        evidenceList: [301, 302],
      },
      {
        ID: 403,
        rating: 4,
        score: 84,
        approved: false,
        graduationRequirement: true,
        reviewsRequested: 2,
        commentsUnresolved: 1,
        evidenceInPortfolio: 2,
        evidenceAssigned: 6,
        status: 3,
        activityCount: 2,
        evidenceList: [301, 302],
      },
      {
        ID: 404,
        rating: 4,
        score: 82,
        reviewsRequested: 2,
        recentUpdates: 0,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 4,
        status: 3,
        activityCount: 1,
        evidenceList: [301, 302],
      },
      {
        ID: 405,
        rating: 3,
        score: 79,
        graduationRequirement: true,
        recentUpdates: 1,
        reviewsRequested: 2,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 8,
        status: 3,
        evidenceList: [301, 302],
      },
      {
        ID: 406,
        rating: 4,
        score: 83,
        approved: true,
        approvedDate: "11/9/20",
        approvedClass: "Class 2 name",
        approvedTeacher: "Bob Jones",
        graduationRequirement: true,
        recentUpdates: 1,
        reviewsRequested: 0,
        commentsUnresolved: 0,
        evidenceInPortfolio: 2,
        evidenceAssigned: 4,
        status: 5,
        activityCount: 3,
        evidenceList: [301, 302],
      },
      {
        ID: 407,
        rating: 2,
        score: 78,
        recentUpdates: 2,
        reviewsRequested: 1,
        commentsUnresolved: 1,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 2,
        evidenceList: [301, 302],
      },
      {
        ID: 408,
        rating: 2,
        score: 77,
        recentUpdates: 0,
        reviewsRequested: 0,
        commentsUnresolved: 1,
        evidenceInPortfolio: 1,
        evidenceAssigned: 4,
        status: 2,
        evidenceList: [301, 302],
      },
      {
        ID: 409,
        rating: 2,
        score: 78,
        recentUpdates: 2,
        reviewsRequested: 1,
        commentsUnresolved: 1,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 2,
        evidenceList: [301, 302],
      },
    ],
    evidence: [
      {
        ID: 301,
        assignedState: 2,
        lastUpdated: "1/10 12:40 PM",
        status: 6, // David
        score: 90, // David
        rating: 4,

        assignedSkills: [
          {
            ID: 401,
            weight: 0,
            rating: 3, // David
            score: 88, // David
          },
          {
            ID: 402,
            weight: 0,
            rating: 3,
            score: 87,
          },
        ],
      },
      {
        ID: 302,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 5,
        score: 82,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 401,
            weight: 0,
            rating: 4, // David
            score: 91, // David
          },
        ],
      },
      {
        ID: 303,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 4,
        score: 85,
        rating: 3,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 402,
            weight: 0,
            rating: 3, // David
            score: 89, // David
          },
          {
            ID: 403,
            weight: 0,
            rating: 4,
            score: 92,
          },
        ],
      },
      {
        ID: 304,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 3,
        score: 83,
        rating: 3,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 403,
            weight: 0,
            rating: 3, // David
            score: 85, // David
          },
          {
            ID: 404,
            weight: 0,
            rating: 3,
            score: 88,
          },
        ],
      },
      {
        ID: 305,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 2,
        score: 90,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 405,
            weight: 0,
            rating: 4, // David
            score: 92, // David
          },
        ],
      },
      {
        ID: 306,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 1,
        score: 88,
        rating: 3,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 406,
            weight: 0,
            rating: 3, // David
            score: 89, // David
          },
        ],
      },
    ],

    // evidence2: [
    //   {
    //     ID: 301,
    //     score: 98,
    //     rating: 4,
    //     assignedState: 2,
    //     lastUpdated: "1/10 9:13 AM",
    //     status: 5,
    //   },
    //   {
    //     ID: 302,
    //     assignedState: 1,
    //     lastUpdated: "1/13 4:10 PM",
    //     score: 91,
    //     rating: 4,
    //     reviewsRequested: 1,
    //     commentsUnresolved: 4,
    //     status: 4,
    //   },
    //   {
    //     ID: 303,
    //     assignedState: 1,
    //     lastUpdated: "1/16 11:12 AM",
    //     score: 82,
    //     rating: 4,
    //     reviewsRequested: 0,
    //     commentsUnresolved: 1,
    //     status: 3,
    //   },
    //   {
    //     ID: 304,
    //     score: 86,
    //     rating: 4,
    //     assignedState: 1,
    //     lastUpdated: "1/19 2:22 PM",
    //     reviewsRequested: 0,
    //     commentsUnresolved: 1,
    //     status: 2,
    //   },
    //   {
    //     ID: 305,
    //     score: 86,
    //     rating: 4,
    //     assignedState: 1,
    //     lastUpdated: "1/19 2:22 PM",
    //     reviewsRequested: 0,
    //     commentsUnresolved: 1,
    //     status: 1,
    //   },
    //   {
    //     ID: 306,
    //     score: 86,
    //     rating: 4,
    //     assignedState: 1,
    //     lastUpdated: "1/19 2:22 PM",
    //     reviewsRequested: 0,
    //     commentsUnresolved: 1,
    //     status: 0,
    //   },
    // ],
  },
  {
    name: "Robert Smith",
    firstName: "Robert",
    nickName: "Bobby",
    ID: 103,

    evidenceCompleted: 0, // computed based on evidence state below
    evidenceAssigned: 4,
    evidenceOverdue: 1,
    skillsCompleted: 3,
    skillsAssigned: 7,
    reviewsRequested: 2,
    commentsUnresolved: 1,
    skillRatings: [4, 3, 4, 4, 3, 4, 2, 2, 2],
    evidenceList: [302], // , 302, 303, 304, 305, 306], // hack: should really go through skills for tagging, but more trouble than it's worth for test data

    portfolios: [
      {
        ID: 401, // matches skill ID
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 402,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 403,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 404,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 405,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 406,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 407,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 408,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
      {
        ID: 409,
        evidence: [301, 302, 303], // IDs for evidence in portfolio
      },
    ],

    projects: [
      {
        ID: 2011,
      },
      {
        ID: 2012,
      },
      {
        ID: 2013,
      },
    ],

    classes: [
      {
        skillsApproved: 5,
        skillsAssigned: 7,
        classGrade: 88,
        grades: [84, 84, 88, 87, 82, 90, 84, 84, 88, 87, 82, 90],
      },
      {
        skillsApproved: 5,
        skillsAssigned: 9,
        classGrade: 89,
        grades: [84, 84, 88, 87, 82, 90, 84, 84, 88, 87, 82, 90],
      },
      {
        skillsApproved: 5,
        skillsAssigned: 8,
        classGrade: 90,
        grades: [84, 84, 88, 87, 82, 90, 84, 84, 88, 87, 82, 90],
      },
      {
        skillsApproved: 6,
        skillsAssigned: 8,
        classGrade: 80,
        grades: [84, 84, 88, 87, 82, 90, 84, 84, 88, 87, 82, 90],
      },
      {
        skillsApproved: 1,
        skillsAssigned: 3,
        classGrade: 83,
        grades: [84, 84, 88, 87, 82, 90, 84, 84, 88, 87, 82, 90],
      },
      {
        skillsApproved: 7,
        skillsAssigned: 10,
        classGrade: 85,
        grades: [84, 84, 88, 87, 82, 90, 84, 84, 88, 87, 82, 90],
      },
    ],

    skills: [
      {
        ID: 401,
        rating: 4,
        score: 98,
        approved: true,
        approvedDate: "12/9/20",
        approvedClass: "Class 1 name",
        approvedTeacher: "Charles Smith",
        graduationRequirement: true,
        recentUpdates: 1,
        evidenceInPortfolio: 5,
        evidenceAssigned: 8,
        status: 5,
        evidenceList: [301, 302],
      },
      {
        ID: 402,
        rating: 4,
        score: 89,
        approved: true,
        approvedDate: "11/15/20",
        approvedClass: "Class 1 name",
        approvedTeacher: "Charles Smith",
        recentUpdates: 2,
        evidenceInPortfolio: 5,
        evidenceAssigned: 7,
        status: 5,
        activityCount: 1,
        evidenceList: [301, 302],
      },
      {
        ID: 403,
        rating: 4,
        score: 86,
        approved: false,
        graduationRequirement: true,
        recentUpdates: 0,
        reviewsRequested: 2,
        commentsUnresolved: 1,
        evidenceInPortfolio: 7,
        evidenceAssigned: 6,
        status: 3,
        evidenceList: [301, 302],
      },
      {
        ID: 404,
        rating: 4,
        score: 85,
        reviewsRequested: 2,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 4,
        status: 3,
        activityCount: 1,
        evidenceList: [301, 302],
      },
      {
        ID: 405,
        rating: 3,
        score: 78,
        graduationRequirement: true,
        recentUpdates: 1,
        reviewsRequested: 2,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 6,
        status: 3,
        activityCount: 2,
        evidenceList: [301, 302],
      },
      {
        ID: 406,
        rating: 4,
        score: 83,
        approved: true,
        approvedDate: "11/9/20",
        approvedClass: "Class 2 name",
        approvedTeacher: "Bob Jones",
        graduationRequirement: true,
        recentUpdates: 2,
        reviewsRequested: 0,
        commentsUnresolved: 0,
        evidenceInPortfolio: 1,
        evidenceAssigned: 4,
        status: 5,
        evidenceList: [301, 302],
      },
      {
        ID: 407,
        rating: 2,
        score: 77,
        recentUpdates: 1,
        reviewsRequested: 1,
        commentsUnresolved: 1,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 2,
        activityCount: 3,
        evidenceList: [301, 302],
      },
      {
        ID: 408,
        rating: 2,
        score: 75,
        reviewsRequested: 0,
        commentsUnresolved: 1,
        evidenceInPortfolio: 2,
        evidenceAssigned: 4,
        status: 2,
        evidenceList: [301, 302],
      },
      {
        ID: 409,
        rating: 2,
        score: 77,
        recentUpdates: 1,
        reviewsRequested: 1,
        commentsUnresolved: 1,
        evidenceInPortfolio: 0,
        evidenceAssigned: 4,
        status: 2,
        activityCount: 3,
        evidenceList: [301, 302],
      },
    ],

    evidence: [
      {
        ID: 301,
        assignedState: 2,
        lastUpdated: "1/10 12:40 PM",
        status: 7, // David
        score: 94, // David
        rating: 4,

        assignedSkills: [
          {
            ID: 401,
            weight: 0,
            rating: 4, // David
            score: 94, // David
          },
          {
            ID: 402,
            weight: 0,
            rating: 4,
            score: 92,
          },
        ],
      },
      {
        ID: 302,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 6,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 401,
            weight: 0,
            rating: 4, // David
            score: 94, // David
          },
        ],
      },
      {
        ID: 303,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 5,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 402,
            weight: 0,
            rating: 4, // David
            score: 92, // David
          },
          {
            ID: 403,
            weight: 0,
            rating: 4,
            score: 90,
          },
        ],
      },
      {
        ID: 304,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 4,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 403,
            weight: 0,
            rating: 4, // David
            score: 90, // David
          },
          {
            ID: 404,
            weight: 0,
            rating: 3,
            score: 89,
          },
        ],
      },
      {
        ID: 305,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 3,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 405,
            weight: 0,
            rating: 3, // David
            score: 89, // David
          },
        ],
      },
      {
        ID: 306,
        assignedState: 1,
        lastUpdated: "1/13 9:32 AM",
        status: 2,
        score: 88,
        rating: 4,
        reviewsRequested: 0,
        commentsUnresolved: 1,

        assignedSkills: [
          {
            ID: 406,
            weight: 0,
            rating: 2, // David
            score: 79, // David
          },
        ],
      },
    ],

    // evidence2: [
    //   {
    //     ID: 301,
    //     assignedState: 2,
    //     lastUpdated: "1/10 11:21 AM",
    //     score: 92,
    //     rating: 4,
    //     status: 6,
    //   },
    //   {
    //     ID: 302,
    //     assignedState: 1,
    //     lastUpdated: "1/13 1:21 PM",
    //     score: 86,
    //     rating: 4,
    //     reviewsRequested: 2,
    //     commentsUnresolved: 2,
    //     status: 5,
    //   },
    //   {
    //     ID: 303,
    //     assignedState: 1,
    //     lastUpdated: "1/16 3:22 PM",
    //     score: 82,
    //     rating: 4,
    //     reviewsRequested: 1,
    //     commentsUnresolved: 0,
    //     status: 4,
    //   },
    //   {
    //     ID: 304,
    //     assignedState: 1,
    //     lastUpdated: "1/19 3:45 PM",
    //     score: 77,
    //     rating: 3,
    //     reviewsRequested: 0,
    //     commentsUnresolved: 0,
    //     status: 3,
    //   },
    //   {
    //     ID: 305,
    //     assignedState: 0,
    //     score: 80,
    //     rating: 4,
    //     status: 2,
    //   },
    //   {
    //     ID: 306,
    //     assignedState: 1,
    //     lastUpdated: "1/20 9:54 AM",
    //     score: 79,
    //     rating: 3,
    //     reviewsRequested: 2,
    //     commentsUnresolved: 2,
    //     status: 1,
    //   },
    // ],
  },
];

// set ID = 200
const classList = [
  {
    name: "Advisory",
    ID: -201,
    students: 23,
    reviewsRequested: 4,
    commentsUnread: 10,
    commentsUnresolved: 7,
    projects: 8,
    projectsAssigned: 3,
    steps: 4,
    stepsAssigned: 2,
    evidence: 4,
    evidenceAssigned: 2,
    skills: 8,
    skillsAssigned: 4,
    portfolioSubmissions: 10,
    newPortfolioSubmissions: 5,
    newReviewsRequested: 2,
    grades: [90, 95, 91, 88, 82, 99, 90, 95, 91, 88, 82, 99], // compile average portfolio grade for each student
    taggedSkills: [301, 302, 303, 304, 305, 306], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
    // taggedSkills: [401, 403, 404, 405, 407, 409],
  },
  {
    projectName: "Escape the workshop",
    ID: 2011,
    assigned: 1,
    taggedSkills: [301, 302, 303], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "What is an escape room?",
    ID: 2011.1,
    assigned: 1,
  },
  {
    stepName: "Running an escape room",
    ID: 2011.2,
    assigned: 1,
  },
  {
    stepName: "Puzzles: Hiding in plain sight",
    ID: 2011.3,
    assigned: 1,
  },
  {
    projectName: "First exploration1",
    shortName: "First exploration1",
    ID: 2012,
    assigned: 1,
    taggedSkills: [302, 303], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define121",
    ID: 2012.1,
    assigned: 1,
  },
  {
    stepName: "Do122",
    ID: 2012.2,
    assigned: 1,
  },
  {
    stepName: "Teach123",
    ID: 2012.3,
    assigned: 1,
  },
  {
    stepName: "Apply124",
    ID: 2012.4,
    assigned: 1,
  },
  {
    projectName: "Group project1",
    shortName: "Group project1",
    ID: 2013,
    assigned: 1,
    taggedSkills: [303, 304], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define131",
    ID: 2013.1,
    assigned: 1,
  },
  {
    stepName: "Do132",
    ID: 2013.2,
    assigned: 1,
  },
  {
    stepName: "Teach313",
    ID: 2013.3,
    assigned: 1,
  },
  {
    stepName: "Apply134",
    ID: 2013.4,
    assigned: 1,
  },
  {
    name: "English 1.1",
    ID: -202,
    students: 21,
    reviewsRequested: 2,
    commentsUnread: 9,
    commentsUnresolved: 6,
    projects: 7,
    projectsAssigned: 2,
    steps: 5,
    stepsAssigned: 3,
    evidence: 9,
    evidenceAssigned: 7,
    skills: 9,
    skillsAssigned: 3,
    portfolioSubmissions: 15,
    newPortfolioSubmissions: 8,
    newReviewsRequested: 1,
    grades: [86, 95, 91, 88, 82, 95, 82, 95, 91, 88, 82, 80],
    taggedSkills: [301, 302, 304, 306], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
    // taggedSkills: [401, 403, 404, 405, 407, 409],
  },
  {
    projectName: "Getting started2",
    shortName: "Getting started2",
    ID: 2021,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define211",
    ID: 2021.1,
    assigned: 1,
  },
  {
    stepName: "Do212",
    ID: 2021.2,
    assigned: 1,
  },
  {
    stepName: "Teach213",
    ID: 2021.3,
    assigned: 1,
  },
  {
    stepName: "Apply214",
    ID: 2021.4,
    assigned: 1,
  },
  {
    projectName: "First exploration2",
    shortName: "First exploration2",
    ID: 2022,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define221",
    ID: 2022.1,
    assigned: 1,
  },
  {
    stepName: "Do222",
    ID: 2022.2,
    assigned: 1,
  },
  {
    stepName: "Teach223",
    ID: 2022.3,
    assigned: 1,
  },
  {
    stepName: "Apply224",
    ID: 2022.4,
    assigned: 1,
  },
  {
    projectName: "Group project2",
    shortName: "Group project2",
    ID: 2023,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define231",
    ID: 2023.1,
    assigned: 1,
  },
  {
    stepName: "Do232",
    ID: 2023.2,
    assigned: 1,
  },
  {
    stepName: "Teach233",
    ID: 2023.3,
    assigned: 1,
  },
  {
    stepName: "Apply234",
    ID: 2023.4,
    assigned: 1,
  },
  {
    name: "English 1.2",
    ID: -203,
    projectsAssigned: 5,
    commentsUnresolved: 3,
    portfolioSubmissions: 8,
    grades: [95, 95, 91, 88, 82, 92, 90, 95, 91, 88, 82, 99],
  },
  {
    projectName: "Getting started3",
    shortName: "Getting started3",
    ID: 2031,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define311",
    ID: 2031.1,
    assigned: 1,
  },
  {
    stepName: "Do12",
    ID: 2031.2,
    assigned: 1,
  },
  {
    stepName: "Teach313",
    ID: 2031.3,
    assigned: 1,
  },
  {
    stepName: "Apply314",
    ID: 2031.4,
    assigned: 1,
  },
  {
    projectName: "First exploration3",
    shortName: "First exploration3",
    ID: 2032,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define321",
    ID: 2032.1,
    assigned: 1,
  },
  {
    stepName: "Do322",
    ID: 2032.2,
    assigned: 1,
  },
  {
    stepName: "Teach323",
    ID: 2032.3,
    assigned: 1,
  },
  {
    stepName: "Apply324",
    ID: 2032.4,
    assigned: 1,
  },
  {
    projectName: "Group project3",
    shortName: "Group project3",
    ID: 2033,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define331",
    ID: 2033.1,
    assigned: 1,
  },
  {
    stepName: "Do332",
    ID: 2033.2,
    assigned: 1,
  },
  {
    stepName: "Teach333",
    ID: 2033.3,
    assigned: 1,
  },
  {
    stepName: "Apply334",
    ID: 2033.4,
    assigned: 1,
  },
  {
    name: "English 1.3",
    ID: -204,
    projectsAssigned: 5,
    portfolioSubmissions: 17,
    newPortfolioSubmissions: 0,
    newReviewsRequested: 0,
    grades: [95, 95, 91, 88, 82, 92, 90, 95, 91, 88, 82, 99],
    taggedSkills: [301, 302, 304, 306], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
    // taggedSkills: [401, 403, 404, 405, 407, 409],
  },
  {
    projectName: "Getting started4",
    shortName: "Getting started4",
    ID: 2041,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define411",
    ID: 2041.1,
    assigned: 1,
  },
  {
    stepName: "Do412",
    ID: 2041.2,
    assigned: 1,
  },
  {
    stepName: "Teach413",
    ID: 2041.3,
    assigned: 1,
  },
  {
    stepName: "Apply414",
    ID: 2041.4,
    assigned: 1,
  },
  {
    projectName: "First exploration4",
    shortName: "First exploration4",
    ID: 2042,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define421",
    ID: 2042.1,
    assigned: 1,
  },
  {
    stepName: "Do422",
    ID: 2042.2,
    assigned: 1,
  },
  {
    stepName: "Teach423",
    ID: 2042.3,
    assigned: 1,
  },
  {
    stepName: "Apply424",
    ID: 2042.4,
    assigned: 1,
  },
  {
    projectName: "Group project4",
    shortName: "Group project4",
    ID: 2043,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define431",
    ID: 2043.1,
    assigned: 1,
  },
  {
    stepName: "Do432",
    ID: 2043.2,
    assigned: 1,
  },
  {
    stepName: "Teach433",
    ID: 2043.3,
    assigned: 1,
  },
  {
    stepName: "Apply434",
    ID: 2043.4,
    assigned: 1,
  },
  {
    name: "English 1.4",
    ID: -205,
    projectsAssigned: 5,
    reviewsRequested: 12,
    commentsUnresolved: 7,
    portfolioSubmissions: 11,
    newPortfolioSubmissions: 4,
    newReviewsRequested: 3,
    grades: [92, 95, 91, 88, 82, 88, 95, 95, 91, 88, 82, 92],
    taggedSkills: [301, 302, 304, 306], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
    // taggedSkills: [401, 403, 404, 405, 407, 409],
  },
  {
    projectName: "Getting started5",
    shortName: "Getting started5",
    ID: 2051,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define511",
    ID: 2051.1,
    assigned: 1,
  },
  {
    stepName: "Do512",
    ID: 2051.2,
    assigned: 1,
  },
  {
    stepName: "Teach513",
    ID: 2051.3,
    assigned: 1,
  },
  {
    stepName: "Apply514",
    ID: 2051.4,
    assigned: 1,
  },
  {
    projectName: "First exploration5",
    shortName: "First exploration5",
    ID: 2052,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define521",
    ID: 2052.1,
    assigned: 1,
  },
  {
    stepName: "Do522",
    ID: 2052.2,
    assigned: 1,
  },
  {
    stepName: "Teach523",
    ID: 2052.3,
    assigned: 1,
  },
  {
    stepName: "Apply524",
    ID: 2052.4,
    assigned: 1,
  },
  {
    projectName: "Group project5",
    shortName: "Group project5",
    ID: 2053,
    taggedSkills: [301, 302], // this is currently an evidence list, not a skill list. need a way to map from class to tagged skill/assigned evidence. This is close enough for now.
  },
  {
    stepName: "Define531",
    ID: 2053.1,
    assigned: 1,
  },
  {
    stepName: "Do532",
    ID: 2053.2,
    assigned: 1,
  },
  {
    stepName: "Teach533",
    ID: 2053.3,
    assigned: 1,
  },
  {
    stepName: "Apply534",
    ID: 2053.4,
    assigned: 1,
  },
];

// todo: replace with a scoped query
const rubricList = [
  [
    {
      id: "1",
      name: "Incomplete",
      description: "* Placeholder for Incomplete rubric. * Criterion 2 * Criterion 3 * Criterion 4",
    },
    {
      id: "2",
      name: "You can do better",
      description: "* Placeholder for You Can Do Better rubric. * Criterion 2 * Criterion 3 * Criterion 4",
    },
    {
      id: "3",
      name: "Good work",
      description: "* Placeholder for Good Work rubric. * Criterion 2 * Criterion 3 * Criterion 4",
    },
    {
      id: "4",
      name: "Outstanding",
      description: "* Placeholder for Outstanding rubric. * Criterion 2 * Criterion 3 * Criterion 4",
    },
  ],
];

const rubricsList1 = [
  {
    id: "1",
    name: "Self awareness",
    description: "The student is aware of self and the perception of others",
  },
  {
    id: "1a",
    name: "Honest reflection",
  },
  {
    id: "1b",
    name: "Pursuing my best self",
  },
  {
    id: "2",
    name: "Project management",
    description: "The student is able to manage projects, including getting the work done and managing limited resources.",
  },
  {
    id: "2a",
    name: "Manage work",
  },
  {
    id: "2b",
    name: "Manage time",
  },
  {
    id: "2c",
    name: "Internal motivation",
  },
];

const rubricsList = [
  {
    id: "e140836d-22cf-45ef-a8eb-4e4422ec8d8c",
    name: "CITIZENSHIP",
    description: "The student positively contributes to the community and is respectful of others",
  },
  {
    id: "af9a3c62-e0bf-4fb2-9d6d-218423afaee7",
    name: "RESPONSIBILITY",
    description: "The student is prepared for learning experiences, completes work and meets deadlines.",
  },
  {
    id: "353b5c59-66fc-4726-9fac-639df91ca00a",
    name: "Collaboration",
  },
  {
    id: "a847b383-4eab-4273-9fa7-93302875a65f",
    name: "Parent Outreach",
  },
];

const teacherInfo = {
  id: 0,
  name: "",
  shortName: "",
  classes: [],
};

let realSkillList = [];

// teacher-based class data struture to faciltate passing data to components
// todo: rename all "real" once test data is gone
const classData = Object.freeze({
  classList: classList,
  studentList: studentList,
  skillList: skillList,
  realSkillList: realSkillList,
  evidenceList: evidenceList,
  rubricsList: rubricsList,
  rubricList: rubricList, // for workshop schools
});

export {
  classData,
  teacherInfo,
  studentList,
  classList,
  evidenceList,
  skillList,
  getItemID,
  getItem3ID,
  getGroupID,
  getGroup2ID,
  getLevelIDfromClass,
  getLevelIDfromClass2,
  getPrimaryIDfromItemID,
  getSecondaryIDfromItemID,
  getClassIDfromItemID,
  isClassName,
  isProjectName,
  isStepName,
  isClassID,
  isProjectID,
  isStepID,
  isStudentID,
  isSkillID,
  isEvidenceID,
  isProjectInClass,
  isStepInClassProject,
  isParentSkill,
  getClassIDfromProjectID,
  getFirstClass,
  getFirstProject,
  getFirstProjectInClass,
  isFirstProjectInClass,
  isLastProjectInClass,
  getNextProjectInClass,
  getPreviousProjectInClass,
  getFirstPage,
  getFirstGrade,
  getFirstRating,
  getStudentByID,
  getClassByID,
  getClassGradesByID,
  getEvidenceByID,
  getSkillByID,
  findClassByID,
  findClassByProjectID,
  findStepByID,
  findProjectByID,
  findEvidenceByID,
  getGroupIDfromClass,
  getClassIDfromMapID,
  isFirstClass,
  isLastClass,
  getNextClass,
  getPreviousClass,
  isGradeID,
  isRatingID,
  getGrade,
  getRating,
  Unassigned,
  Active,
  Complete,
  Extracurricular,
};
