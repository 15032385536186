import React, { useCallback, useState } from "react"
import { liftTheme } from "./App"
import {
    defaultWidth, placeholderTab, separatorTab, dashboardTab, calendarTab, messagesTab, learnersTab, groupsTab, classExplorerTab, classesTab, subjectsTab, projectsTab, evidenceTab,
    skillsTab, portfoliosTab, progressTab, trashTab
} from "./utilitiesConstants"
import { globalHandleClick } from "./utilitiesEvents"
import { handleToggle, getSelectionState, toggleSelectionState, tickSelectionState } from "./utilitiesState"
import { rd, classNameText, rdPreferShort } from "./utilitiesResponsiveData"
import { lookupLingo, TermChat, TermClass, TermEvidence, TermGroup, TermLearner, TermPortfolio, TermProject, TermSkill } from "./customizable"
import { Typography, Button, Container, Tooltip, IconButton, Collapse, OutlinedInput, InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getLevelIDfromClass2, classList, studentList, isClassID } from "./data"    // need direct access to elements for navigationData links todo: pass as attributes
import { setComponentState, updateComponentState } from "./utilities"
import { navigatorPaneData } from "./navigatorData"
import { CONFIG } from './env/featureFlags'

// important notes regarding commands, ID, selection, etc.
// [the code version this explanation is in utilititesEvents, for link: and filter: handlers]
// had to change selection mechanism, event handling, etc. to disabiguate classes in hives other than classes. (Problem in original, all classes had same IDs in all hives)
// level 1
// const level1ID = item.ID.toString();
// stateData.handleClick(ev, "link:" + item.type.toString(), level1ID) 
// so if receive link: event, can get both item type (classesTab) and ID (8)
// level 2
// const level2ID = getLevelIDfromClass2(level1.ID, item.ID);
// stateData.handleClick(ev, "filter:" + parent.type.toString(), level2ID) 
// so if receive filter: have parent item type and ID (top of level2ID) to nav to page, plus class to filter (bottom of level2ID)
// exception: use class.ID for actual classes in Classes hive

const navigatorDataFull = [
    {
        name: "Dashboard",
        type: dashboardTab,
        ID: 1,
        icon: "tachometer-alt",
    },
    {
        name: "Calendar",
        type: calendarTab,
        ID: 2,
        icon: "calendar",
    },
    {
        name: "Messages",
        type: messagesTab,
        ID: 3,
        icon: "comment-dots",
    },
    {
        type: separatorTab,
        ID: 4,  // use for unique keys
    },
    {
        name: "Learners",
        type: learnersTab,
        ID: 5,
        icon: "users",
        childIcon: "user",
        data: studentList,
    },
    {
        name: "Groups",
        type: groupsTab,
        ID: 17,
        icon: "users",
        childIcon: "users",
    },
    {
        type: separatorTab,
        ID: 6,
    },
    {
        name: "Class Explorer",
        type: classExplorerTab,
        ID: 7,
        icon: "search-plus",
    },
    {
        name: "Classes",
        type: classesTab,
        ID: 8,
        icon: "th",
        childIcon: "th-large",
        canEdit: true,
        data: classList,
    },
    {
        name: "Subjects",
        type: subjectsTab,
        ID: 9,
        icon: "th-list",
    },
    {
        name: "Projects",
        type: projectsTab,
        ID: 10,
        icon: "cubes",
        canEdit: true,
    },
    {
        name: "Evidence",
        type: evidenceTab,
        ID: 11,
        icon: "eye",
    },
    {
        name: "Skills",
        type: skillsTab,
        ID: 12,
        icon: "check-circle",
    },
    {
        name: "Portfolios",
        type: portfoliosTab,
        ID: 13,
        icon: "suitcase",
    },
    {
        name: "Progress",
        type: progressTab,
        ID: 14,
        icon: "chart-line",
    },
    {
        type: separatorTab,
        ID: 15,
    },
    {
        name: "Trash",
        type: trashTab,
        ID: 16,
        icon: "trash",
    },
];

// consider: applying multiple styles to handle selection color changes (instead of using separate as now)
// use makeStyles to map component element styles to LiFT theme plus other non-theme styles
const useStyles = makeStyles(theme => ({
    navigatorPaneStyle: {
        width: 390,
        height: "calc(100vh - 220px)",
        margin: theme.spacing(1),
        padding: 0,
        float: "left",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        transition: "width " + theme.transitions.duration.short + "ms, height " + theme.transitions.duration.standard + "ms ease",
        userSelect: "none",
        position: "relative",
        [theme.breakpoints.down(1640)]: { width: 350 }
    },
    navigatorPaneStyleLarge: {
        height: "calc(100vh - 165px)",
    },
    navigatorBody: {
        overflowY: "auto",
        height: "calc(100vh - 270px)",
    },
    navigatorBodyLarge: {
        overflowY: "auto",
        height: "calc(100vh - 220px)",
    },
    navigatorNode: {
        margin: "auto",
        marginLeft: 4,
        marginRight: 4,
        border: "none",
        fontSize: 24,
        minHeight: 38,
        position: "relative",
        transition: "height " + theme.transitions.duration.standard + "ms ease",    // not working!
    },
    navigatorLine: {
        height: 36,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    selectedNavigatorLine: {
        height: 36,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.selection.main,
        "&:hover": {
            backgroundColor: theme.palette.selectionHover.main,
        },
    },
    navigatorChildNode: {
        marginLeft: 35,
        fontSize: 22,
    },
    navigatorSeparator: {
        marginLeft: 4,
        marginRight: 4,
        borderWidth: 1,
        border: "1px solid",
        borderColor: theme.palette.divider,
    },
    navigatorButton: {
        width: 18,
        height: 22,
        right: 10,
        float: "right",
        marginTop: 6,
    },
    navigatorIcon: {
        border: "none",
        fontSize: 22,
        width: 36,
        margin: "0 8px 8px 8px",
        background: "transparent",
        color: theme.palette.icons.navBar.main,
    },
    selectedNavigatorIcon: {
        border: "none",
        fontSize: 22,
        width: 36,
        margin: "0 8px 8px 8px",
        background: "transparent",
        color: theme.palette.common.white,
    },
    expandoIcon: {
        border: "none",
        fontSize: 20,
        position: "absolute",
        left: 0,
        paddingBottom: 2,
        color: theme.palette.icons.navBar.main,
    },
    selectedExpandoIcon: {
        border: "none",
        fontSize: 20,
        position: "absolute",
        left: 0,
        paddingBottom: 2,
        color: theme.palette.common.white,
    },
    navigatorLabel: {
        position: "absolute",
        left: 65,
    },
    navigatorLabel2: {
        left: 65,
    },
    navigatorHeader: {
        fontSize: 22,
        display: "flex",
        width: "100%",
        height: 42,
        padding: 0,
        margin: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.backgrounds.headerBar.main,
    },
    navigatorHeaderCollapse: {
        position: "absolute",
        border: "none",
        margin: "auto",
        fontSize: 26,
        left: 10,
        background: "transparent",
        color: theme.palette.icons.navBar.main,
    },
    navigatorHeaderName: {
        fontSize: 22,
        visibility: "visible",
    },
    navigatorModeClose: {       // not used yet
        position: "absolute",
        visibility: "visible",
        border: "none",
        margin: "auto",
        fontSize: 26,
        right: 8,
        background: "transparent",
        color: theme.palette.icons.navBar.main,
    },
    navigatorModeCloseSmall: {      // not used yet
        visibility: "hidden"
    },
    navigatorHeaderNameSmall: {     // not used yet
        visibility: "hidden",
    },
    inputRoot: {
        position: "absolute",
        border: "none",
        margin: "auto",
        right: theme.spacing(1),
        color: theme.palette.icons.navBar.main,
        background: "transparent",
    },
    inputInput: {
        padding: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 0,
            '&:focus': {
                width: '22ch',
                color: theme.palette.common.black,
                backgroundColor: theme.palette.common.white,
            },
        },
    },
}));

// open hive if not already (necessary for selecting classes to work well, since you don't see the selection if hive is closed)
const openNavHive = (expandoState, tab) => {
    handleToggle(expandoState, getNavObjectIDByType(tab), tickSelectionState);
}

// lookup ID based on navigator object type
const getNavObjectIDByType = evidenceType => {
    const foundNode = navigatorPaneData.find((node) => node.type === evidenceType);
    // console.debug(`getNavObjectIDByType: ${evidenceType}, node: ${foundNode}, ID: ${foundNode.ID}`);
    if (!foundNode) return;
    return foundNode.ID.toString();
}

// used by navigator to use custom terms
const updateNavigatorLingo = (item) => {
    switch (item.type) {
        case messagesTab: item.name = lookupLingo(TermChat, true, true); return;
        case learnersTab: item.name = lookupLingo(TermLearner, true, true); return;
        case groupsTab: item.name = lookupLingo(TermGroup, true, true); return;
        case classesTab: item.name = lookupLingo(TermClass, true, true); return;
        case projectsTab: item.name = lookupLingo(TermProject, true, true); return;
        case evidenceTab: item.name = lookupLingo(TermEvidence, true, true); return;
        case skillsTab: item.name = lookupLingo(TermSkill, true, true); return;
        case portfoliosTab: item.name = lookupLingo(TermPortfolio, true, true); return;
        default: return;
    }
}

const GetLevel2 = ({ item, level1, parent, canEdit, stateData, ...props }) => {
    if (!item || !parent || isNaN(canEdit) || !stateData) console.debug("Navigation GetLevel2: missing or invalid parameters");
    const classes = stateData.classes;
    const [searchState] = stateData.filterState;

    // for IDs, for classes tab use the class ID directly, for all others use a combo of level and item
    const level2ID = (parent.type === classesTab) ? item.ID.toString() : getLevelIDfromClass2(level1.ID, item.ID);   // use instead of getItemID because item.ID might be negative
    const clickPageChange = useCallback(ev => { stateData.handleClick(ev, "filter:" + parent.type.toString(), level2ID) }, [stateData, parent.type, level2ID]);

    // filter non-classes
    if (parent.type !== learnersTab && !isClassID(item.ID)) return <></>;

    // use "responsive" name
    const responsiveName = rd(item, classNameText, true, defaultWidth, rdPreferShort, searchState);
    if (!responsiveName || responsiveName.length === 0) return <></>;

    // filter out if in search mode and not a match
    if (searchState && searchState.length > 0 && typeof responsiveName === "string")
        return <></>;

    // determine selection state style
    const isSelected = handleToggle(stateData.appState.navSelectState, level2ID, getSelectionState);
    const selectionStyle = (isSelected) ? classes.selectedNavigatorLine : classes.navigatorLine;
    // console.debug(`Nav selection 2: ${level2ID}`);

    // todo: need button click handler
    let editButton;
    if (canEdit)
        editButton = <Button variant="contained" color="secondary" className={classes.navigatorButton}>Edit</Button>;

    return <div key={level2ID} className={classes.navigatorChildNode}>
        <div className={selectionStyle} onClick={clickPageChange}>
            <Tooltip title={item.name}>
                <IconButton key={"button" + level2ID} aria-label={item.name} size="small"><FontAwesomeIcon icon={parent.icon} className={classes.navigatorIcon} /></IconButton>
            </Tooltip>
            <span><span className={classes.navigatorLabel2}>{responsiveName}</span>{editButton}</span>
        </div></div >
}

const GetLevel1 = ({ item, stateData, ...props }) => {
    if (!item || !stateData) console.debug("Navigation GetLevel1: missing or invalid parameters");
    const classes = stateData.classes;
    const [searchState] = stateData.filterState;
    const level1ID = item.ID.toString();

    const clickPageChange = useCallback(ev => { stateData.handleClick(ev, "link:" + item.type.toString(), level1ID) }, [stateData, item.type, level1ID]);
    const clickExpando = useCallback(ev => { ev.stopPropagation(); handleToggle(stateData.expansionState, level1ID, toggleSelectionState) }, [stateData.expansionState, level1ID]);

    if (item.type === separatorTab)
        return <hr className={classes.navigatorSeparator} />

    // determine selection state styles
    // consider: applying multiple styles to handle selection color changes (instead of using separate as now)
    const isSelected = handleToggle(stateData.appState.navSelectState, level1ID, getSelectionState);
    let selectionStyle, selectionIconStyle, selectionExpandoStyle;
    if (isSelected) {
        selectionStyle = classes.selectedNavigatorLine;
        selectionIconStyle = classes.selectedNavigatorIcon;
        selectionExpandoStyle = classes.selectedExpandoIcon;
    } else {
        selectionStyle = classes.navigatorLine;
        selectionIconStyle = classes.navigatorIcon;
        selectionExpandoStyle = classes.expandoIcon;
    }
    // console.debug(`Nav selection 1: ${level1ID}`);

    // include add button if editable (top level has add, second level has edit)
    // todo: need click handler
    let addButton;
    let editable = false;
    if (item.canEdit) {
        editable = true; // setting to a boolean to pass parameter check
        addButton = <Button variant="contained" color="secondary" className={classes.navigatorButton}>Add</Button>;
    }

    // determine if node has children, set node
    let itemLine, childNodes, isOpen = false;
    if (item.data && item.data.length > 0) {
        // console.debug("Navigator node has children");
        isOpen = handleToggle(stateData.expansionState, level1ID, getSelectionState);

        // force open if in search mode
        if (searchState && searchState.length > 0) isOpen = true;

        itemLine = <span>
            <IconButton aria-label={"expand " + item.name} size="small" onClick={clickExpando}>
                {isOpen ?
                    <FontAwesomeIcon icon="chevron-down" className={selectionExpandoStyle} /> :
                    <FontAwesomeIcon icon="chevron-right" className={selectionExpandoStyle} />
                }</IconButton>
            <span className={classes.navigatorLabel}>{item.name}</span>{addButton}</span >;

        // build childNodes -- use this approach to render only when visisible
        if (isOpen) {
            childNodes = <>{item.data.map((subItem, index) => <GetLevel2 key={level1ID + index.toString()} item={subItem} level1={item} parent={item} canEdit={editable} stateData={stateData} />)}</>
        }
    } else {
        itemLine = <span><span className={classes.navigatorLabel} >{item.name}</span>{addButton}</span>;
    }

    return <>
        <div key={level1ID + "div"} className={classes.navigatorNode}>
            <div className={selectionStyle} onClick={clickPageChange}>
                <Tooltip title={item.name}><IconButton key={"button" + level1ID} aria-label={item.name} size="small"><FontAwesomeIcon icon={item.icon} className={selectionIconStyle} /></IconButton></Tooltip>
                {itemLine}
            </div>
            <Collapse in={isOpen} timeout="auto" collapsedHeight="0">
                {childNodes}
            </Collapse>
        </div>
    </>
}

const NavigatorPane = ({ classData, appStateData, ...props }) => {
    if (!classData || !appStateData) console.debug("NavigationPane missing or invalid parameters");
    const classes = useStyles(liftTheme);

    // state hooks--the one source of truth
    const [smallMode] = appStateData.smallHeaderState;
    const searchFilterState = useState(null);
    const [, setSearchState] = searchFilterState;

    // update to any custom object names
    navigatorPaneData.map(item => updateNavigatorLingo(item));

    // click handlers
    const handleSearch = ev => { setSearchState(ev.target.value); }
    const handleClick = (ev, itemType, itemID) => {
        // console.debug("NavigationPane: handleClick, Ev value: ${ev.target.value);
        globalHandleClick(ev, itemType, itemID);
    };
    // didn't work: const stateData = useMemo(() => { setComponentState(...
    const stateData = setComponentState(placeholderTab, appStateData, appStateData.navExpandState, 0, 0, 0, 0, 0, 0, searchFilterState, 0, 0);

    updateComponentState(stateData, classes, handleClick);

    // const [selected] = appStateData.navSelectState;
    // console.debug(`Nav selection state: ${JSON.stringify(selected)}`);

    return <Container className={smallMode ? classes.navigatorPaneStyle + " " + classes.navigatorPaneStyleLarge : classes.navigatorPaneStyle}>
        <div className={classes.navigatorHeader}>
            <Tooltip title="Collapse Navigation pane">
                {/* onclick="collapseNavigator()"> */}
                <IconButton key="headerCollapse" className={classes.navigatorHeaderCollapse}><FontAwesomeIcon icon="chevron-left" /></IconButton>
            </Tooltip>
            <Typography className={classes.navigatorHeaderName}>Navigation</Typography>
            { CONFIG.showNavigationPaneSearch ? <Tooltip title="Search Navigation pane">
                <OutlinedInput margin="dense" aria-label="navigation search box" classes={{ root: classes.inputRoot, input: classes.inputInput, }} onChange={handleSearch}
                    endAdornment={<InputAdornment position="end"><FontAwesomeIcon icon="search" aria-label="click to search" /></InputAdornment>} />
            </Tooltip> : <></> }
        </div>
        <div
            className={smallMode ? classes.navigatorBodyLarge : classes.navigatorBody} >
            {navigatorPaneData.map(item => <GetLevel1 key={item.ID.toString()} item={item} stateData={stateData} />)}
        </div>
    </Container>
}

export { NavigatorPane, getNavObjectIDByType, openNavHive }
