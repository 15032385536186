import { highestRating, highestGrade, statusInPortfolio } from "./customizable";

const defaultWidth = 1000; // init large so no breakpoints fire (todo: move to rdDefaultWidth)

// maxLevels shouldn't be hardwired because having a discrepancy between number of levels and colors will crash
const maxScoreLevels = highestRating; // given fixed nomenclature, this should actually be 100
const maxRatingLevels = highestRating;
const maxStatusLevels = statusInPortfolio;
const maxProgressLevels = highestRating;
const maxApprovalLevels = 2; // hardwired
const maxGradeLevels = highestGrade;

const placeholder = -1;

const firstHive = 0;
const ratingHive = 0;
const approvalHive = 1;
const scoreHive = 2;
const lastHive = 2;

const placeholderTab = 0,
  separatorTab = 1,
  dashboardTab = 2,
  calendarTab = 3,
  messagesTab = 4,
  learnersTab = 5,
  groupsTab = 15,
  classExplorerTab = 6,
  classesTab = 7,
  subjectsTab = 8,
  projectsTab = 9,
  evidenceTab = 10,
  skillsTab = 11,
  portfoliosTab = 12,
  progressTab = 13,
  trashTab = 14;

const viewRubrics = 0,
  viewMessages = 1,
  viewNotes = 2,
  viewMetrics = 3,
  viewHelp = 4;

export {
  defaultWidth,
  maxScoreLevels,
  maxRatingLevels,
  maxStatusLevels,
  maxProgressLevels,
  maxGradeLevels,
  maxApprovalLevels,
  placeholder,
  firstHive,
  ratingHive,
  approvalHive,
  scoreHive,
  lastHive,
  placeholderTab,
  separatorTab,
  dashboardTab,
  calendarTab,
  messagesTab,
  learnersTab,
  groupsTab,
  classExplorerTab,
  classesTab,
  subjectsTab,
  projectsTab,
  evidenceTab,
  skillsTab,
  portfoliosTab,
  progressTab,
  trashTab,
  viewRubrics,
  viewMessages,
  viewNotes,
  viewMetrics,
  viewHelp,
};
