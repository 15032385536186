import React from "react"
import { Typography, Container, Tooltip } from "@material-ui/core"
import { averageValue } from "./utilities"
import { rd, dateText } from "./utilitiesResponsiveData"
import {
    getApprovedColor, getStatusIconInfo, getRatingLabel, lookupLingo, TermChat, TermEvidence, TermLearner, TermPortfolio, TermProject,
    TermSkill, statusLocked, statusNotAssigned, statusNotStarted, statusCompleted, statusInPortfolio
} from "./customizable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// consider: split out utilitiesTooltips?

const getClassTT = (classObj, classes) => {
    return <Container className={classes.tileStyle}>
        <Typography className={classes.tileNameStyle}>{classObj.name}</Typography>
        <Typography className={classes.tileDataStyle}><b>{lookupLingo(TermLearner, true, true)}:</b> {classObj.students ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermProject, true, true)}:</b> Assigned: {classObj.projectsAssigned ?? 0}, Total: {classObj.projects ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermEvidence, true, true)}:</b> Assigned: {classObj.evidenceAssigned ?? 0}, Total: {classObj.evidence ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermSkill, true, true)}:</b> Assigned: {classObj.skillsAssigned ?? 0}, Total: {classObj.skills ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>Review requests:</b> {classObj.reviewsRequested ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermChat, true, true)}:</b> Unread: {classObj.commentsUnread ?? 0}, Unresolved: {classObj.commentsUnresolved ?? 0}</Typography>
    </Container>
}

const getProjectTT = (projectObj, classes) => {
    return <Container className={classes.tileStyle}>
        <Typography className={classes.tileNameStyle}>{projectObj.name}</Typography>
        <Typography className={classes.tileDataStyle}><b>{lookupLingo(TermLearner, true, true)}:</b> {projectObj.students ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermProject, true, true)}:</b> Assigned: {projectObj.projectsAssigned ?? 0}, Total: {projectObj.projects ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermEvidence, true, true)}:</b> Assigned: {projectObj.evidenceAssigned ?? 0}, Total: {projectObj.evidence ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermSkill, true, true)}:</b> Assigned: {projectObj.skillsAssigned ?? 0}, Total: {projectObj.skills ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>Review requests:</b> {projectObj.reviewsRequested ?? 0}</Typography>
        <Typography className={classes.tileData2Style}><b>{lookupLingo(TermChat, true, true)}:</b> Unread: {projectObj.commentsUnread ?? 0}, Unresolved: {projectObj.commentsUnresolved ?? 0}</Typography>
    </Container>
}

const getItemTT = (item, studentInfo, classes) => {
    if (!studentInfo)
        return;

    let approvedText, details;
    const [, , statustt] = getStatusIconInfo(studentInfo?.status);
    if (studentInfo?.approved || studentInfo?.status === statusCompleted || studentInfo?.status === statusInPortfolio) {
        if (studentInfo?.status === statusInPortfolio)
            approvedText = <b>Approved, in portfolio</b>;
        else
            approvedText = <b>Approved</b>;
        let approvingTeacher;
        if (studentInfo.approvedTeacher)
            approvingTeacher = <>, By: {studentInfo.approvedTeacher}</>

        // todo: skip missing details
        details = <><Typography className={classes.tileData2Style}><b>Approval:</b> Date: {rd(studentInfo.approvedDate, dateText, false)}{approvingTeacher}</Typography>
            <Typography className={classes.tileData2Style}><b>Approval class:</b> {studentInfo.approvedClass}</Typography>
            <Typography className={classes.tileData2Style}><b>Recent activity</b> Assigned: {studentInfo.evidenceAssigned ?? 0} Updates: {studentInfo.recentUpdates ?? 0}, Count: {studentInfo.activityCount ?? 0}</Typography></>
    } else {
        if (studentInfo?.status === statusLocked || studentInfo?.status === statusNotAssigned || studentInfo?.status === statusNotStarted)
            approvedText = <i>{statustt}</i>;
        else {
            approvedText = statustt;
            details = <Typography className={classes.tileData2Style}><b>Recent activity</b> Assigned: {studentInfo.evidenceAssigned ?? 0} Updates: {studentInfo.recentUpdates ?? 0}, Count: {studentInfo.activityCount ?? 0}</Typography>;
        }
    }

    let graduationRequirement;
    if (studentInfo.graduationRequirement)
        graduationRequirement = <FontAwesomeIcon icon="graduation-cap" className={classes.alertStyle} />;

    // bug: want icon and title to be on same line
    return <Container className={classes.tileStyle}>
        <Container disableGutters={true}>{graduationRequirement} <Typography className={classes.tileNameStyle}>{item.shortName}</Typography></Container>
        {/* <div style={{ display: "flex", justifyContent: "flex-start", flexWrap: "nowrap", alignItems: "center" }}><div style={{ flex: 1 }}>{graduationRequirement}</div><div style={{ flex: 1 }}>{skill.skill}</div></div> */}
        <Typography className={classes.tileShortNameStyle}>{item.name}</Typography>
        <Typography className={classes.tileDataStyle}>{approvedText}</Typography>
        {details}
    </Container>
}

const getEvidenceCompletionTT = (evidenceCompleted, evidenceAssigned) => {
    if (!evidenceAssigned)
        return;

    const text = `${evidenceCompleted} of ${evidenceAssigned}`;
    return <Tooltip title={`${lookupLingo(TermLearner, true, true)} completed this ${lookupLingo(TermEvidence)}`}><span>{text}</span></Tooltip>;       // tooltip must be around a span
}

const getEvidenceOverdueTT = (evidenceOverdue) => {
    if (!evidenceOverdue)
        return;

    let text = `Overdue: ${evidenceOverdue}`;
    return <Tooltip title={`${lookupLingo(TermLearner, true, true)} has this much overdue ${lookupLingo(TermEvidence)}`}><span>{text}</span></Tooltip>;       // tooltip must be around a span
}

const getPortfolioRatingTT = (studentInfo, ratings) => {
    if (ratings?.length === 0) return;

    // just compute the average for now. An override might be in studentInfo
    let average = Math.ceil(averageValue(ratings));
    if (average < 1) average = 1;

    return getRatingLabel(average);
}

const getEvidenceInPortfolioCompletionTT = (evidenceInPortfolio, evidenceAssigned) => {
    if (!evidenceAssigned)
        return;

    let text = `${evidenceInPortfolio} of ${evidenceAssigned}`;
    return <Tooltip title={`${lookupLingo(TermLearner, true)} has this ${lookupLingo(TermEvidence)} in ${lookupLingo(TermPortfolio)}`}><span>{text}</span></Tooltip>;       // tooltip must be around a span
}

const getSkillCompletionTT = (skillsApproved, skillsAssigned) => {
    if (!skillsAssigned)
        return;

    let text = `${skillsApproved} of ${skillsAssigned}`;
    return <Tooltip title={`${lookupLingo(TermLearner, true)} has completed this many ${lookupLingo(TermSkill, false, true)} assigned`}><span>{text}</span></Tooltip>;       // tooltip must be around a span
}

const getSkillApprovalTT = (studentsApproved, studentsAssigned) => {
    if (!studentsAssigned)
        return;

    const text = `${studentsApproved} of ${studentsAssigned}`;
    return <Tooltip title={`${lookupLingo(TermLearner, true, true)} who have approval for this ${lookupLingo(TermSkill)}`}><span>{text}</span></Tooltip>;       // tooltip must be around a span
}

// necessarily because test data has classes and projects in same array
const getClassOption = (classObj, showProjects) => {
    if (!classObj || isNaN(showProjects)) console.debug("getClassInfo: missing or invalid parameters");
    if (!classObj)
        return;

    if (classObj.name)
        return <option key={classObj.ID} value={classObj.ID} aria-label="Class">{classObj.name}</option>;
    else if (showProjects && classObj.project)
        return <option key={classObj.ID} value={classObj.ID} aria-label="Project">    {classObj.project}</option>;
}

// the material-ui LinearProgress component is pretty useless. Easier to create a custom component.
// targetPercent is optional, shows a vertical line where the performance target is.
const ProgressBar = ({ width, height, percent, foregroundColor, backgroundColor, tooltip, targetPercent, ...props }) => {
    if (isNaN(width) || isNaN(height) || isNaN(percent) || !foregroundColor || !backgroundColor) {
        console.debug(`ProgressBar: missing or invalid parameters`);
        console.debug(`Progress bar, width: ${width}, height: ${height}, percent: ${percent}, foreground: ${foregroundColor}, background: ${backgroundColor}`);
    }
    if (width === 0 || height === 0) return <></>;

    // fix params
    let fixedPercent = percent;
    if (percent < 0) fixedPercent = 0;
    if (percent > 100) fixedPercent = 100;

    // calculate widths and heights
    const widthSize = width.toString() + "px";
    const heightSize = height.toString() + "px";
    const barHeightSize = (height - 3).toString() + "px";
    const percentage = fixedPercent + "%";
    // console.debug(`Progress bar: percentage: ${percentage}`)

    // do target bar
    let targetPercentDiv = <div />;
    if (targetPercent && targetPercent > 0 && targetPercent < 100) {
        let offset = (width * targetPercent) / 100;
        // console.debug(`Progress bar target percent: ${targetPercent}, offset: ${offset}`);
        let markerHeight = height + 8;    // intent was to make bigger but effect is that it just fits. 
        targetPercentDiv = <div style={{ position: "absolute", top: -4, bottom: -4, left: offset, height: markerHeight, borderLeft: "1px solid black" }}></div>
    }

    if (tooltip)
        return <Tooltip title={tooltip}><div style={{ borderRadius: "2px", borderColor: backgroundColor, borderStyle: "solid", width: widthSize, height: heightSize, position: "relative" }}>
            <div style={{ positon: "absolute", padding: 0, borderRadius: "2px", top: 0, bottom: 0, height: barHeightSize, left: 0, width: percentage, backgroundColor: foregroundColor }}>&nbsp;</div>
            {/* <div style={{ padding: 0, borderRadius: "2px", backgroundColor: foregroundColor, width: percentage, height: barHeightSize }}>&nbsp;</div> */}
            {targetPercentDiv}</div></Tooltip>;
    else
        return <div style={{ borderRadius: "2px", borderColor: backgroundColor, borderStyle: "solid", width: widthSize, height: heightSize, position: "relative" }}>
            <div style={{ positon: "absolute", padding: 0, borderRadius: "2px", top: 0, bottom: 0, height: barHeightSize, left: 0, width: percentage, backgroundColor: foregroundColor }}>&nbsp;</div>
            {/* <div style={{ padding: 0, borderRadius: "2px", backgroundColor: foregroundColor, width: percentage, height: barHeightSize }}>&nbsp;</div> */}
            {targetPercentDiv}</div>;
}

// targetPercent is optional, shows a horizontal line where the performance target is.
// todo: need delta param to set color ranges exactly?
// todo: need to handle missing values (so if 3 but only one provided, show all three)
const HistogramBar = ({ width, height, topGrade, bottomGrade, grades, foregroundColors, backgroundColor, tooltip = 0, targetPercent, approvals = 0, ...props }) => {
    if (isNaN(width) || isNaN(height) || isNaN(topGrade) || isNaN(bottomGrade) || !grades || !foregroundColors) {   // optional: approvals
        console.debug(`Histogram bar: missing or invalid parameters`);
        console.debug(`Histogram bar, width: ${width}, height: ${height}, topGrade: ${topGrade}, bottomGrade: ${bottomGrade}, background: ${backgroundColor}`);
    }
    if (height === 0 || !grades || grades.length === 0 || topGrade - bottomGrade <= 0) return <></>;

    // calculate widths and heights
    const heightSize = height.toString() + "px";
    const widthSize = width.toString() + "px";
    const cellWidth = width / grades.length - 1;
    // console.debug(`Histogram bar: width: ${widthSize}, height: ${heightSize}, grades: ${grades}, cellWidth: ${cellWidth}`);

    // map grades to heights and colors
    let i, h, color, colors, above, cellHeights = [], cellColors = [];
    colors = foregroundColors.length;
    const range = (topGrade - bottomGrade) / (colors - 1);
    for (i = 0; i < grades.length; i++) {
        // map grade to height
        above = (grades[i] - bottomGrade) / (topGrade - bottomGrade);
        h = Math.ceil(above * height);
        if (h > height) h = height;
        if (h < 0) h = 0;
        cellHeights.push(h);

        // map grade to color offset
        color = Math.floor((grades[i] - bottomGrade) / range + 1);
        color = Math.max(color, 0);
        color = Math.min(color, colors - 1);
        cellColors.push(color);
    }
    // console.debug(`Histogram bar height: ${height}, heights: ${cellHeights}`);
    // console.debug(`Histogram bar colors: ${cellColors}`);

    // do target bar
    let targetPercentDiv = <div />
    if (targetPercent) {
        // map target to height
        above = (targetPercent - bottomGrade) / (topGrade - bottomGrade);
        h = Math.ceil(above * height);
        let topOffset = height - h;
        // console.debug(`Histogram target percent: ${targetPercent}, topGrade: ${topGrade}, bottomGrade: ${bottomGrade}, height: ${height}, offset: ${h}`);

        if (h > 0 && h < height)
            targetPercentDiv = <div style={{ position: "absolute", top: topOffset, bottom: 0, left: 0, right: 0, borderTop: "1px solid black" }}></div>
    }

    // do histogram
    const histogram = <div style={{ height: heightSize, width: widthSize, position: "relative" }}> {
        cellHeights.map((cellHeight, index) =>
            <div key={index} style={{
                display: "inline-block", backgroundColor: (!approvals || approvals[index]) ? foregroundColors[cellColors[index]] : getApprovedColor(1),
                width: cellWidth, height: cellHeight, margin: "0 1px 0 0", border: (!approvals || approvals[index]) ? "" : "3px solid " + foregroundColors[cellColors[index]]
                // display: "inline-block", backgroundColor: (approvals && approvals[index]) ? getApprovedColor(1) : foregroundColors[cellColors[index]],
                // width: cellWidth, height: cellHeight, margin: "0 1px 0 0", border: (approvals && approvals[index]) ? "3px solid " + foregroundColors[cellColors[index]] : ""
            }}></div>)}
        {targetPercentDiv}</div>;

    if (tooltip)
        return (<Tooltip title={tooltip}>{histogram}</Tooltip>);
    return <>{histogram}</>;
}

export {
    ProgressBar, HistogramBar, getClassTT, getProjectTT, getItemTT, getClassOption, getEvidenceCompletionTT, getEvidenceOverdueTT, getEvidenceInPortfolioCompletionTT, getSkillCompletionTT, getSkillApprovalTT, getPortfolioRatingTT,
}


