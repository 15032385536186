import React from "react"
import { liftTheme } from "./App";
import { makeStyles, Divider, Input, InputLabel, MenuItem, FormControl, ListItemText, Select, Checkbox } from "@material-ui/core"
import { choiceCancel, choiceOption, optionSelected, optionUnderReview, optionSubmitted, optionInProgress, optionAssigned } from "./utilitiesDialogs";
import { lookupLingo, statusNotStarted, statusInProgress, statusSubmitted, statusUnderReview } from "./customizable"
import { getCurrentResults } from "./utilitiesEvents"
import { rd, rdPreferShort } from "./utilitiesResponsiveData"
import { defaultWidth } from "./utilitiesConstants";
import { isProjectID, isEvidenceID, isSkillID } from "./data"

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
        maxWidth: 300,
    },
    menuStyle: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
    },
}))

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            color: "#000000",
            backgroundColor: "#ffffff",
            maxHeight: 450,
            maxWidth: 350,
        },
    },
};

// filterOptions: an array of objects with names and IDs, filterState returns ["all"] or an array of selected IDs (so any other IDs are filtered out)
const FilterButton = ({ labelID, filterOptions, filterState, optionFilter = null, indentChildren = true, ...props }) => {
    const classes = useStyles(liftTheme);
    const [itemList, setItemList] = filterState;

    const handleChange = ev => {
        const optionsCount = filterOptions.length;
        const selectionCount = ev.target.value?.length || 0;
        if (itemList.indexOf("all") < 0 && ev.target.value?.indexOf("all") > -1) {
            // all just added, select all
            let selectAll = ["all"];
            filterOptions.forEach(option => selectAll.push(option.ID));
            setItemList(selectAll);
            ev.target.value = selectAll;
        } else if (itemList.indexOf("all") > -1 && ev.target.value?.indexOf("all") < 0) {
            // all just removed, clear all
            setItemList([]);
            ev.target.value = [];
        } else if (ev.target.value?.indexOf("all") > -1 && selectionCount - 1 < optionsCount) {
            // all but not all selected, remove all
            ev.target.value?.shift();
            setItemList(ev.target.value);
        }
        else {
            // do nothing special
            setItemList(ev.target.value);
        }
        // old approach--didn't handle select all except selected scenario
        // if (ev.target.value?.indexOf("all") > -1) {
        //     const len = ev.target.value?.length;
        //     const newValue = ev.target.value[len - 1];    // replace all settings with last one, either 1) replace with "all" or 2) replace "all" with a specific
        //     setItemList([newValue]);
        //     ev.target.value = [newValue];
        // } else
        console.debug(`itemList: ${itemList}`);
    }

    const displayText = (selected, label) => {
        if (selected[0] === "all")
            return <span>{label} (all)</span>;
        if (selected[0] === "active")
            return <span>{label} (active)</span>;
        if (selected[0] === "assigned")
            return <span>{label} (assigned)</span>;
        if (selected[0] === "overdue")
            return <span>{label} (overdue)</span>;
        if (selected.length === 1) {
            const option = filterOptions.find(option => option.ID === selected[0])
            const optionName = rd(option, 0, false, defaultWidth, rdPreferShort);
            return <span>{optionName}</span>;
        }
        return <span>{label} ({selected.length})</span>;
    }

    // const handleChangeMultiple = ev => {
    //     const { options } = ev.target;
    //     const selections = [];
    //     options.forEach(option => {
    //         if (option.selected) selections.push(option.value);     // make value ID
    //     });
    //     setItemList(selections);
    //     console.debug(`itemList: ${itemList}`);
    // };

    // get label variations
    const label = lookupLingo(labelID);
    const Labels = lookupLingo(labelID, true, true);
    const labels = lookupLingo(labelID, false, true);   // plural version

    return <FormControl className={classes.formControl}>
        <InputLabel id={"filterBy" + label}>{"Filter " + labels}</InputLabel>
        <Select labelId={"filterBy" + label} id={"filter" + label} multiple onChange={handleChange} MenuProps={MenuProps} value={itemList} variant="outlined" input={<Input />}
            // renderValue={selected => selected.join(', ')} >
            // renderValue={selected => <span>{Labels} {(selected[0] === "all") ? " (all)" : " (" + selected.length + ")"}</span>} >
            renderValue={selected => displayText(selected, Labels)} >
            <MenuItem key={"all"} value={"all"} dense={true} >
                <Checkbox color="primary" checked={itemList.indexOf('all') > -1} />
                <ListItemText primary={"All " + labels} />
            </MenuItem>
            <MenuItem key={"active"} value={"active"} dense={true} >
                <Checkbox color="primary" checked={itemList.indexOf('active') > -1} />
                <ListItemText primary={"Active " + labels} />
            </MenuItem>
            <MenuItem key={"assigned"} value={"assigned"} dense={true} >
                <Checkbox color="primary" checked={itemList.indexOf('assigned') > -1} />
                <ListItemText primary={"Assigned " + labels} />
            </MenuItem>
            <MenuItem key={"overdue"} value={"overdue"} dense={true} >
                <Checkbox color="primary" checked={itemList.indexOf('overdue') > -1} />
                <ListItemText primary={"Overdue " + labels} />
            </MenuItem>
            <Divider />
            {filterOptions.map(option => ((!optionFilter || optionFilter(option.ID)) ?
                <MenuItem key={option.ID} value={option.ID.toString()} dense={true} >
                    <Checkbox color="primary" checked={itemList.indexOf(option.ID) > -1} />
                    <ListItemText primary={(indentChildren && option?.parentSkill === false ? "...." : "") + rd(option, 0, false, defaultWidth, rdPreferShort)} />
                </MenuItem> : null
            ))}
        </Select>
    </FormControl>
}

// return null if no actual filter ("all" isn't a filter)
const getFilterState = (filterState) => {
    if (!filterState)
        return null;
    const [filter] = filterState;
    if (!filter || filter.length === 0 || filter[0] === "all")
        return null;
    return filter;
}

const isClearableFilter = (filterState) => {
    if (!filterState)
        return false;
    const [filter] = filterState;
    if (!filter || filter.length === 0 || filter[0] === "all" || filter[0] === "active" || filter[1] === "active")
        return false;
    return true;
}

const filterIteratorSingle = (list, filterState) => {
    // return original list if no filter
    const filter = getFilterState(filterState);
    if (!filter)
        return list;

    // build new list
    const newList = list.filter(item => filter.find(filterID => filterID === item.ID));
    console.debug(`Filtered list: ${newList}`);
    return newList
}

const filterIteratorAll = (list, projectFilterState = null, evidenceFilterState = null, skillFilterState = null) => {
    // return original list if no filters--need at least one
    if (!projectFilterState && !evidenceFilterState && !skillFilterState)
        return list;

    // get filters
    const projectFilter = getFilterState(projectFilterState);
    const evidenceFilter = getFilterState(evidenceFilterState);
    const skillFilter = getFilterState(skillFilterState);

    // return if no actual filter
    if (!projectFilter && !evidenceFilter && !skillFilter)
        return list;

    // build new list
    const newList = [];
    for (let i = 0; i < list.length; i++) {
        const item = list[i];

        if (isProjectID(item.ID)) {
            // apply project filter
            if (!projectFilter || projectFilter.find(filterID => filterID === item.ID))
                newList.push(item);
        }
        else if (isEvidenceID(item.ID)) {
            // apply evidence filter
            if (!evidenceFilter || evidenceFilter.find(filterID => filterID === item.ID))
                newList.push(item);
        }
        else if (isSkillID(item.ID)) {
            // apply skill filter
            if (!skillFilter || skillFilter.find(filterID => filterID === item.ID))
                newList.push(item);
        }
    }
    console.debug(`Filtered list (3 way): ${newList}`);
    return newList
}

// if returns true, keep item
const filterByStatus = (item, itemStatus, statusFilterState) => {
    const statusFilter = getFilterState(statusFilterState);
    // return true if no item or filter
    if (!statusFilter)
        return true;

    // apply status filter 
    const retVal = statusFilter?.find(status => status === itemStatus);
    console.debug(`filterByStatus: item: ${item?.name}, status: ${itemStatus}, filters: ${statusFilter}, filter: ${retVal}`);
    return retVal;
}

const filterIteratorAllX = (list, projectFilterState = null, evidenceFilterState = null, skillFilterState = null, statusFilterState = null) => {
    // return original list if no filters--need at least one
    if (!projectFilterState && !evidenceFilterState && !skillFilterState && !statusFilterState)
        return list;

    // get filters
    const projectFilter = getFilterState(projectFilterState);
    const evidenceFilter = getFilterState(evidenceFilterState);
    const skillFilter = getFilterState(skillFilterState);
    const statusFilter = getFilterState(statusFilterState);

    // return if no actual filter
    if (!projectFilter && !evidenceFilter && !skillFilter && !statusFilter)
        return list;

    // build new list
    const newList = [];
    for (let i = 0; i < list.length; i++) {
        const item = list[i];

        // apply status filter first
        if (statusFilter && item.status && !statusFilter.find(status => status === item.status))
            continue;

        if (isProjectID(item.ID)) {
            // apply project filter
            if (!projectFilter || projectFilter.find(filterID => filterID === item.ID))
                newList.push(item);
        }
        else if (isEvidenceID(item.ID)) {
            // apply evidence filter
            if (!evidenceFilter || evidenceFilter.find(filterID => filterID === item.ID))
                newList.push(item);
        }
        else if (isSkillID(item.ID)) {
            // apply skill filter
            if (!skillFilter || skillFilter.find(filterID => filterID === item.ID))
                newList.push(item);
        }
    }
    console.debug(`Filtered list (3 way): ${newList}`);
    return newList
}

// do bulk filter based on current status
const bulkEvidenceFilter = (stateData, itemID, bulkMarkMode) => {
    let filter = false;
    const [, , oldApproval, currentStatus] = getCurrentResults(stateData, itemID);

    if (bulkMarkMode.choice === choiceCancel) {
        filter = true;
        console.debug("bulkFilter received a choiceCancel"); // should never see choiceCancel, but just in case
    } else if (bulkMarkMode.choice === choiceOption && currentStatus) {
        switch (Number(bulkMarkMode.option)) {
            case optionSelected:
                break; // not filtered--forgot why I made this an option
            case optionUnderReview:
                filter = currentStatus < statusUnderReview;
                break;
            case optionSubmitted:
                filter = currentStatus < statusSubmitted;
                break;
            case optionInProgress:
                filter = currentStatus < statusInProgress;
                break;
            case optionAssigned:
            default:
                filter = currentStatus < statusNotStarted;
                break;
        }
    }
    return [filter, oldApproval];  // returning oldApproval for undo
}

const bulkSkillsFilter = (stateData, itemID, bulkMarkMode) => {
    let filter = false;
    const [currentRating, , oldApproval] = getCurrentResults(stateData, itemID);

    if (bulkMarkMode.choice === choiceCancel) {
        filter = true;
        console.debug("bulkFilter received a choiceCancel"); // should never see choiceCancel, but just in case
    } else if (bulkMarkMode.choice === choiceOption && currentRating) {
        filter = currentRating < Number(bulkMarkMode.option);
    }
    return [filter, oldApproval];  // returning oldApproval for undo
}

export { FilterButton, getFilterState, isClearableFilter, filterIteratorSingle, filterIteratorAll, filterByStatus, bulkEvidenceFilter, bulkSkillsFilter }


