// "responsive data" chooses the data format based on the available space
// rd also handles search match highlighting, as all displayed data runs through it
// this function assumes different datatypes have consistent attribute names: namme, shortName, firstName, etc. -- these must be tagged consistently
//  exception: projectName, stepName (instead of just name) in classesList
// note: the breakpoints below are language specific. Japanese would have different brekapoint rules, for example.
// note: also, dates are locale specific, would need to localize outside the US (and Belize!)

import { searchTermState } from "./App"
import { getEvidenceByID, findProjectByID, getSkillByID, skillList, evidenceList, classList } from "./data"

// supported data types and preferences
const rdDefaultWidth = 1000; // init large so no breakpoints fire
const classNameText = 1, studentNameText = 2, evidenceNameText = 3, portfolioNameText = 5, projectNameText = 6, evidenceFromStudentNameText = 7, portfolioFromStudentNameText = 8, projectFromStudentNameText = 9,
    skillNameText = 10, stepNameText = 11, dateText = 12, dateAndTimeText = 13;
const rdNoPrefererence = 0, rdPreferShort = 1, rdPreferShortest = 2, rdPreferMedium = 3, rdPreferLong = 4, rdPreferFull = 5;

function isDigit(c) { return c >= '0' && c <= '9'; }
function isLetter(s) { return s.length === 1 && s.match(/[a-z]/i); }

// responsive data: format based on elementSize, dataType, and sizePreference
// no need to expose breakpoints externally, since this function encapulates the best size based on knowledge of data, rather just expose preference
// consider: add multiline param for cases where more than one line is OK
const rd = (dataObj, dataType, highlightMatches = true, elementSize = rdDefaultWidth, formatPreference = rdPreferFull, customSearchTerm = null) => {
    if (!dataObj || isNaN(dataType)) {
        if (!dataObj) return "";
        console.debug("responsive data: missing or invalid parameters");
    }
    // console.debug(`responsive data: type: ${dataType}, size: ${elementSize}, format preference: ${formatPreference}`);
    let retVal = dataObj.name;

    // dates are a special case because dataObj is the date string itself, not an object
    // expect that actual date value will be an object not a string, which won't require this special case
    if (dataType === dateText || dataType === dateAndTimeText) {
        if (!dataObj) return "";
        return dataObj;    // just return what is given for now

        // todo: see proporal for data handling
        // short: remove year, remove time
        // medium: return original date
        // long: convert to Month day, year
        // full: convert to DayOfWeek, Month day, year
    } else if (formatPreference === rdPreferShort) {
        // handle short preference first, no need to apply rules
        // difference with rdPreferShortest is that shortest version does apply rules
        retVal = dataObj.shortName ?? dataObj.name ?? dataObj.projectName ?? dataObj.stepName;
    } else if (dataType) {
        // todo: copy dataObj to a mutable to eliminate redundant code
        // formatePreference overrides elementSize logic, ex: if rePreferMedium, return medium even if long or full will fit
        let itemID, bp1, bp2, bp3;      // invariant: bp1 > bp2 > bp3 (not checked for now)
        switch (dataType) {
            case classNameText:
                break;
            case evidenceFromStudentNameText:
                // dataObj = student.evidence[i]
                // get evidence ID from student
                itemID = dataObj.ID;
                const evidence = getEvidenceByID(evidenceList, itemID);
                // console.debug(`rd evidenceFromStudent: ID: ${itemID}, evidence: ${evidence}`);
                if (!evidence) return "";
                if (bp1 && elementSize <= bp1 && evidence?.shortName)
                    retVal = evidence?.shortName;
                else
                    retVal = evidence?.name;
                break;
            case evidenceNameText:
                if (bp1 && elementSize <= bp1 && dataObj.shortName)
                    retVal = dataObj.shortName;
                break;
            case skillNameText:
                bp1 = 500; bp2 = 300;   // just guesses, confirm

                if (formatPreference <= rdPreferMedium || (elementSize <= bp2 && dataObj.firstName))
                    retVal = dataObj.shortName;
                else if (formatPreference === rdPreferLong || elementSize <= bp1)
                    retVal = dataObj.name;
                else if (dataObj.shortName)
                    retVal = `${dataObj.shortName} - ${dataObj.name}`;
                break;
            case studentNameText:
                bp1 = 200; bp2 = 150; bp3 = 100;
                if (formatPreference === rdPreferShortest || (elementSize <= bp3 && dataObj.nickName))
                    retVal = dataObj.nickName;
                else if (formatPreference === rdPreferMedium || (elementSize <= bp2 && dataObj.firstName))
                    retVal = dataObj.firstName;
                else if (formatPreference === rdPreferLong || elementSize <= bp1)
                    retVal = dataObj.name;
                else if (dataObj.nickName)
                    retVal = `${dataObj.name} (${dataObj.nickName})`;
                break;
            case dateText:
            case dateAndTimeText:
                // console.debug(`rd: date handling bug`); // should already be handled as a special case above
                return dataObj;
            case portfolioFromStudentNameText:
                // dataObj = student.portfolios[i]
                // get skill ID from student
                itemID = dataObj.ID;
                const skill = getSkillByID(skillList, itemID);
                // console.debug(`rd portfolioFromStudent: ID: ${itemID}, skill: ${skill}`);
                if (!skill) return "";
                else if (bp1 && elementSize <= bp1 && skill?.shortName)
                    retVal = skill?.shortName;
                else
                    retVal = skill?.name;
                break;
            case portfolioNameText:
                break;
            case projectFromStudentNameText:
                // dataObj = student.projects[i]
                // get project ID from student
                itemID = dataObj.ID;
                const project = findProjectByID(classList, itemID);
                // console.debug(`rd projectFromStudent: ID: ${itemID}, project: ${project}`);
                if (!project) return "";
                else if (bp1 && elementSize <= bp1 && project?.shortName)
                    retVal = project?.shortName;
                else
                    retVal = project?.projectName;
                break;
            case projectNameText:
                if (bp1 && elementSize <= bp1 && dataObj.shortName)
                    retVal = dataObj.shortName;
                else
                    retVal = dataObj.projectName;
                break;
            case stepNameText:
                if (bp1 && elementSize <= bp1 && dataObj.shortName)
                    retVal = dataObj.shortName;
                else
                    retVal = dataObj.stepName;
                break;
            default: break;
        }
    }

    // return if not highlighting search matches
    if (!highlightMatches || !retVal)
        return retVal;

    // set searchTerm, return if no search term to match
    // todo: use toLowerCase()?
    const [searchState] = searchTermState;
    const searchTerm = (customSearchTerm) ? customSearchTerm : searchState;
    if (!searchTerm || searchTerm.length === 0)
        return retVal;

    // split search into words
    const rawWords = searchTerm.split(/[-\s/\\()"',;<>~!@#$%^&|+=[\]{}`~?:]/u);
    const words = rawWords.filter(word => word && word.length > 0 && (isLetter(word.charAt(0)) || isDigit(word.charAt(0))));
    if (words.length === 0)
        return retVal;

    // console.debug(`Serach words: ${words}`);

    // handle search match highlighting
    // const retString = retVal.replace(new RegExp(searchTerm, "gi"), (match) => `<mark>${match}</mark>`);  // this does literal search
    let retString = retVal;
    words.forEach(word => { retString = retString.replace(new RegExp(word, "gi"), (match) => `<mark>${match}</mark>`) });   // this does word search

    // if matches, return marked up html otherwise return a string
    if (retString.search("</mark>") >= 0)
        return <span dangerouslySetInnerHTML={{ __html: retString }}></span>
    else
        return retVal;  // use string instead of a span (can use typeof === "string" to differentiate)
}

export {
    rd, rdDefaultWidth, classNameText, studentNameText, evidenceNameText, skillNameText, portfolioNameText, projectNameText, evidenceFromStudentNameText, portfolioFromStudentNameText, projectFromStudentNameText,
    stepNameText, dateText, dateAndTimeText, rdNoPrefererence, rdPreferShort, rdPreferShortest, rdPreferMedium, rdPreferLong, rdPreferFull
}
