import React, { useState } from "react";
import { buildCustomColors } from "./customizable";
import { buildColorSet } from "./utilitiesColor"
import { Button, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Switch, FormControlLabel } from "@material-ui/core";

// todo: add custom grade colors (grades are only for class aggregates), might not need if shared with another color scheme
// todo: add sat and lum for muted colors

const ColorSamples = ({ colorScheme, ...props }) => {
    if (!colorScheme) console.debug(`ColorSamples: missing or invalid parameters`);
    return <div style={{ display: "flex", position: "absolute", left: 120 }}>
        {colorScheme.map((color) => <div style={{ width: 20, height: 20, backgroundColor: color }} />)}
    </div>;
}

const LiftOptionsDialog = ({ appStateData, darkState, autoCloseState, autoOpenPortfoliosState, smallHeaderState, open, handleOnClose, ...props }) => {
    if (!appStateData || !darkState || !autoCloseState || !autoOpenPortfoliosState || !smallHeaderState || isNaN(open) || !handleOnClose) console.debug(`LiftOptionsDialog: missing or invalid parameters`);

    const [colorInfo, setColorInfo] = appStateData.colorInfoState;
    const [colorSet, setColorSet] = appStateData.colorState;

    const colorStyle = { width: 150 };
    const countStyle = { width: 130 };
    const swatchStyle = { width: 34, height: 34, border: 0, marginTop: 24, marginRight: 10, marginleft: 10 };

    // state hooks--the one source of truth
    const [darkMode, setDarkMode] = darkState;
    const [smallMode, setSmallHeader] = smallHeaderState;
    const [autoClose, setAutoClose] = autoCloseState;
    const [autoOpen, setAutoOpen] = autoOpenPortfoliosState;
    const [colorsChanged, setColorsChanged] = useState(false);
    const [scoreColorInfo, setScoreColorInfo] = useState({ ...colorInfo[1] });
    const [ratingColorInfo, setRatingColorInfo] = useState({ ...colorInfo[2] });
    const [statusColorInfo, setStatusColorInfo] = useState({ ...colorInfo[3] });
    const [progressColorInfo, setProgressColorInfo] = useState({ ...colorInfo[4] });
    const [approvedColorInfo, setApprovedColorInfo] = useState({ ...colorInfo[5] });
    const [scoreColors, setScoreColors] = useState([...colorSet.scoreColors]);
    const [ratingColors, setRatingColors] = useState([...colorSet.ratingColors]);
    const [statusColors, setStatusColors] = useState([...colorSet.statusColors]);
    const [progressColors, setProgressColors] = useState([...colorSet.progressColors]);
    const [approvedColors, setApprovedColors] = useState([...colorSet.approvedColors]);

    // given handleChange, handleClose just needs to copy the state back and update the custom colors
    const buildCustomColorInfo = () => {
        const sat = colorInfo[0].sat;
        const lum = colorInfo[0].lum;

        return [{ sat: sat, lum: lum },
        { low: scoreColorInfo.low, high: scoreColorInfo.high, levels: scoreColorInfo.levels },
        { low: ratingColorInfo.low, high: ratingColorInfo.high, levels: ratingColorInfo.levels },
        { low: statusColorInfo.low, high: statusColorInfo.high, levels: statusColorInfo.levels },
        { low: progressColorInfo.low, high: progressColorInfo.high, levels: progressColorInfo.levels },
        { low: approvedColorInfo.low, high: approvedColorInfo.high, levels: approvedColorInfo.levels }];
    }
    const handleClose = (updateValues) => {
        console.debug("LiftOptionsDialog: handleClose: enter");
        if (!colorsChanged) {
            handleOnClose();
            return;
        }

        if (updateValues) {
            // update the colors
            console.debug("LiftOptionsDialog: handleClose: save changes")
            const newColorInfo = buildCustomColorInfo();
            setColorInfo(newColorInfo);
            const newColors = buildCustomColors(newColorInfo);
            setColorSet(newColors);
        } else {
            console.debug("LiftOptionsDialog: handleClose: undo changes")
            // if cancel, copy back to reinit for next time--note that these state variables are never reinitialized because the dialog is only hidden (not reloaded)
            setScoreColorInfo({ ...colorInfo[1] });
            setRatingColorInfo({ ...colorInfo[2] });
            setStatusColorInfo({ ...colorInfo[3] });
            setProgressColorInfo({ ...colorInfo[4] });
            setApprovedColorInfo({ ...colorInfo[5] });

            setScoreColors([...colorSet.scoreColors]);
            setRatingColors([...colorSet.ratingColors]);
            setStatusColors([...colorSet.statusColors]);
            setProgressColors([...colorSet.progressColors]);
            setApprovedColors([...colorSet.approvedColors]);
        }
        console.debug("LiftOptionsDialog: handleClose: exit")
        setColorsChanged(false);
        handleOnClose();
    };

    // this is a very code heavy solution, but it's the style prefered by React, plus gives live previews of the colors as they are selected
    const handleChange = (ev) => {
        let newColors, newInfo;
        setColorsChanged(true);

        // get the change
        switch (ev.target.id) {
            case "lowScoreColor":
                newColors = scoreColors;
                newInfo = scoreColorInfo;
                newInfo.low = ev.target.value;
                setScoreColorInfo(newInfo)
                break;
            case "highScoreColor":
                newColors = scoreColors;
                newInfo = scoreColorInfo;
                newInfo.high = ev.target.value;
                setScoreColorInfo(newInfo)
                break;
            case "scoreLevels":
                newColors = scoreColors;
                newInfo = scoreColorInfo;
                newInfo.levels = ev.target.value;
                setScoreColorInfo(newInfo)
                break;
            case "lowRatingColor":
                newColors = ratingColors;
                newInfo = ratingColorInfo;
                newInfo.low = ev.target.value;
                setRatingColorInfo(newInfo)
                break;
            case "highRatingColor":
                newColors = ratingColors;
                newInfo = ratingColorInfo;
                newInfo.high = ev.target.value;
                setRatingColorInfo(newInfo)
                break;
            case "ratingLevels":
                newColors = ratingColors;
                newInfo = ratingColorInfo;
                newInfo.levels = ev.target.value;
                setRatingColorInfo(newInfo)
                break;
            case "lowStatusColor":
                newColors = statusColors;
                newInfo = statusColorInfo;
                newInfo.low = ev.target.value;
                setStatusColorInfo(newInfo)
                break;
            case "highStatusColor":
                newColors = statusColors;
                newInfo = statusColorInfo;
                newInfo.high = ev.target.value;
                setStatusColorInfo(newInfo)
                break;
            case "statusLevels":
                newColors = statusColors;
                newInfo = statusColorInfo;
                newInfo.levels = ev.target.value;
                setStatusColorInfo(newInfo)
                break;
            case "lowProgressColor":
                newColors = progressColors;
                newInfo = progressColorInfo;
                newInfo.low = ev.target.value;
                setProgressColorInfo(newInfo)
                break;
            case "highProgressColor":
                newColors = progressColors;
                newInfo = progressColorInfo;
                newInfo.high = ev.target.value;
                setProgressColorInfo(newInfo)
                break;
            case "progressLevels":
                newColors = progressColors;
                newInfo = progressColorInfo;
                newInfo.levels = ev.target.value;
                setProgressColorInfo(newInfo)
                break;
            case "lowApprovedColor":
                newColors = approvedColors;
                newInfo = approvedColorInfo;
                newInfo.low = ev.target.value;
                setApprovedColorInfo(newInfo)
                break;
            case "highApprovedColor":
                newColors = approvedColors;
                newInfo = approvedColorInfo;
                newInfo.high = ev.target.value;
                setApprovedColorInfo(newInfo)
                break;
            default: return;
        }

        // make the change
        switch (ev.target.id) {
            case "lowScoreColor":
            case "highScoreColor":
            case "scoreLevels":
                newColors = buildColorSet(newInfo.low, newInfo.high, newInfo.levels);
                setScoreColors(newColors);
                break;
            case "lowRatingColor":
            case "highRatingColor":
            case "ratingLevels":
                newColors = buildColorSet(newInfo.low, newInfo.high, newInfo.levels);
                setRatingColors(newColors);
                break;
            case "lowStatusColor":
            case "highStatusColor":
            case "statusLevels":
                newColors = buildColorSet(newInfo.low, newInfo.high, newInfo.levels);
                setStatusColors(newColors);
                break;
            case "lowProgresColor":
            case "highProgressColor":
            case "progressLevels":
                newColors = buildColorSet(newInfo.low, newInfo.high, newInfo.levels);
                setProgressColors(newColors);
                break;
            case "lowApprovedColor":
            case "highApprovedColor":
                newColors = buildColorSet(newInfo.low, newInfo.high, 2);
                setApprovedColors(newColors);
                break;
            default: break;
        }
        console.debug(`Handle change: ${ev.target.name} ${ev.target.id} ${ev.nativeEvent.target.id} ${ev.target.type} `)
    };

    if (!appStateData) return <></>;  // remove

    return <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle id="LiftOptionsDialog">LiFT Options</DialogTitle>
        <DialogContent>
            <div style={{ display: "flex", position: "relative" }}><Typography color="primary">Score colors</Typography>&nbsp;&nbsp;<ColorSamples colorScheme={scoreColors} /></div>
            <TextField id="lowScoreColor" size="small" margin="normal" label="Lowest score" style={colorStyle} defaultValue={scoreColorInfo.low} onChange={ev => handleChange(ev)} />
            <input type="color" id="lowScoreColor" style={swatchStyle} value={scoreColorInfo.low} onChange={ev => handleChange(ev)} />
            <TextField id="highScoreColor" size="small" margin="normal" label="Highest score" style={colorStyle} defaultValue={scoreColorInfo.high} onChange={ev => handleChange(ev)} />
            <input type="color" id="highScoreColor" style={swatchStyle} value={scoreColorInfo.high} onChange={ev => handleChange(ev)} />
            <TextField id="scoreLevels" size="small" margin="normal" label="Score levels" type="number" style={countStyle} defaultValue={scoreColorInfo.levels} onChange={ev => handleChange(ev)} />

            <div style={{ display: "flex", position: "relative" }}><Typography color="primary">Rating colors</Typography>&nbsp;&nbsp;<ColorSamples colorScheme={ratingColors} /></div>
            <TextField id="lowRatingColor" size="small" margin="normal" label="Lowest rating" style={colorStyle} defaultValue={ratingColorInfo.low} onChange={ev => handleChange(ev)} />
            <input type="color" id="lowRatingColor" style={swatchStyle} value={ratingColorInfo.low} onChange={ev => handleChange(ev)} />
            <TextField id="highRatingColor" size="small" margin="normal" label="Highest rating" style={colorStyle} defaultValue={ratingColorInfo.high} onChange={ev => handleChange(ev)} />
            <input type="color" id="highRatingColor" style={swatchStyle} value={ratingColorInfo.high} onChange={ev => handleChange(ev)} />
            <TextField id="ratingLevels" size="small" margin="normal" label="Rating levels" type="number" style={countStyle} defaultValue={ratingColorInfo.levels} onChange={ev => handleChange(ev)} />

            <div style={{ display: "flex", position: "relative" }}><Typography color="primary">Status colors</Typography>&nbsp;&nbsp;<ColorSamples colorScheme={statusColors} /></div>
            <TextField id="lowStatusColor" size="small" margin="normal" label="Lowest status" style={colorStyle} defaultValue={statusColorInfo.low} onChange={ev => handleChange(ev)} />
            <input type="color" id="lowStatusColor" style={swatchStyle} value={statusColorInfo.low} onChange={ev => handleChange(ev)} />
            <TextField id="highStatusColor" size="small" margin="normal" label="Highest status" style={colorStyle} defaultValue={statusColorInfo.high} onChange={ev => handleChange(ev)} />
            <input type="color" id="highStatusColor" style={swatchStyle} value={statusColorInfo.high} onChange={ev => handleChange(ev)} />
            <TextField id="statusLevels" size="small" margin="normal" label="Status levels" type="number" style={countStyle} defaultValue={statusColorInfo.levels} onChange={ev => handleChange(ev)} />

            <div style={{ display: "flex", position: "relative" }}><Typography color="primary">Progress colors</Typography>&nbsp;&nbsp;<ColorSamples colorScheme={progressColors} /></div >
            <TextField id="lowProgressColor" size="small" margin="normal" label="Lowest progress" style={colorStyle} defaultValue={progressColorInfo.low} onChange={ev => handleChange(ev)} />
            <input type="color" id="lowProgressColor" style={swatchStyle} value={progressColorInfo.low} onChange={ev => handleChange(ev)} />
            <TextField id="highProgressColor" size="small" margin="normal" label="Highest progress" style={colorStyle} defaultValue={progressColorInfo.high} onChange={ev => handleChange(ev)} />
            <input type="color" id="highProgressColor" style={swatchStyle} value={progressColorInfo.high} onChange={ev => handleChange(ev)} />
            <TextField id="progressLevels" size="small" margin="normal" label="Progress levels" type="number" style={countStyle} defaultValue={progressColorInfo.levels} onChange={ev => handleChange(ev)} />
            <div style={{ display: "flex", position: "relative" }}><Typography color="primary">Approved colors</Typography></div>

            <TextField id="lowApprovedColor" size="small" margin="normal" label="Normal approved" style={colorStyle} defaultValue={approvedColorInfo.low} onChange={ev => handleChange(ev)} />
            <input type="color" id="lowApprovedColor" style={swatchStyle} value={approvedColorInfo.low} onChange={ev => handleChange(ev)} />
            <TextField id="highApprovedColor" size="small" margin="normal" label="Dark approved" style={colorStyle} defaultValue={approvedColorInfo.high} onChange={ev => handleChange(ev)} />
            <input type="color" id="highApprovedColor" style={swatchStyle} value={approvedColorInfo.high} onChange={ev => handleChange(ev)} />

            <Typography color="primary">Display options</Typography>
            <fieldset>
                <FormControlLabel value="darkMode"
                    control={<Switch color="primary" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
                    label="Use dark mode" labelPlacement="start" />
                <FormControlLabel value="smallHeader"
                    control={<Switch color="primary" checked={smallMode} onChange={() => setSmallHeader(!smallMode)} />}
                    label="Use small header" labelPlacement="start" />
                <FormControlLabel value="smallHeader"
                    control={<Switch color="primary" checked={autoClose} onChange={() => setAutoClose(!autoClose)} />}
                    label="Auto-close with keyboard navigation" labelPlacement="start" />
                <FormControlLabel value="smallHeader"
                    control={<Switch color="primary" checked={autoOpen} onChange={() => setAutoOpen(!autoOpen)} />}
                    label="Auto-open portfolios with keyboard navigation" labelPlacement="start" />
            </fieldset>
        </DialogContent >
        <DialogActions>
            <Button onClick={() => handleClose(true)} color="primary">Save</Button>
            <Button onClick={() => handleClose(false)} color="secondary">Close</Button>
        </DialogActions>
    </Dialog >
}

export { LiftOptionsDialog };
