// todo: get custom breakpoints to work
// WARNING: if sizing stops working once you integrate with theme, might be that Containers need maxWidth={false}

// use rem instead of px in theme. Conversion table
// 16px = 1rem
// 18px = 1.125rem
// 20px = 1.25rem
// 22px = 1.375rem
// 24px = 1.5rem
// 26px - 1.625rem
// 28px = 1.75rem
// 30px = 1.875rem
// 32px = 2rem
// 34px = 2.125rem
// 36px = 2.25rem
// 42px = 2.625rem
// 44px = 2.75rem

const liftThemeData = {
  breakpoints: {
    keys: {
      0: "xs",
      1: "sm",
      2: "md",
      3: "lg",
      4: "xl",
      5: "xxl",
    },
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1640,
      xxl: 1920,
    },
  },
  palette: {
    type: "light",
    primary: {
      light: "#467ee1",
      main: "#467ee1",
      dark: "#467ee1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ddd",
      main: "#ddd",
      dark: "#ddd",
      contrastText: "#000",
    },
    urgent: {
      light: "#db4f57",
      main: "#db4f57",
      dark: "#db4f57",
      contrastText: "#fff",
    },
    important: {
      light: "#205eca",
      main: "#205eca",
      dark: "#205eca",
      contrastText: "#fff",
    },
    recent: {
      light: "#05b54d",
      main: "#05b54d",
      dark: "#05b54d",
      contrastText: "#fff",
    },
    alert: {
      light: "#b78e38",
      main: "#b78e38",
      dark: "#b78e38",
      contrastText: "#fff",
    },
    highlight: {
      light: "#ffbb2f",
      main: "#ffbb2f",
      dark: "#ffbb2f",
      contrastText: "#000",
    },
    selection: {
      light: "#205eca",
      main: "#205eca",
      dark: "#205eca",
      contrastText: "#fff",
    },
    selectionHover: {
      light: "#7aa3ea",
      main: "#7aa3ea",
      dark: "#7aa3ea",
      contrastText: "#fff",
    },
    backgrounds: {
      titleBar: {
        light: "#0a2047",
        main: "#0a2047",
        dark: "#0a2047",
      },
      headerBar: {
        light: "#c9daf6",
        main: "#c9daf6",
        dark: "#c9daf6",
        contrastText: "#000",
      },
      progressBar: {
        main: "#c9daf6",
      },
      gradebook: {
        main: "#f0f0ff",
      },
      gradebookParent: {
        main: "#e0e0ff",
      },
      gradebookEvidence: {
        main: "#f0f0f0",
      },
      gradebookBlank: {
        main: "#fafafa",
      },
    },
    icons: {
      titleBar: {
        main: "#c9daf6",
      },
      titleBarDisabled: {
        main: "#18399a",
      },
      statusBar: {
        main: "#0a2047",
      },
      navBar: {
        light: "#205eca",
        main: "#205eca",
        dark: "#205eca",
        contrastText: "#fff",
      },
      pinnedPin: {
        main: "#1c41b5",
      },
      unpinnedPin: {
        main: "#ddd",
      },
    },
  },
  sizing: {
    text: {
      titleBarTeacherName: "2.75rem",
      titleBarTeacherName2: "2.25rem",
      titleBarTeacherName3: "1.5rem",
      titleBarTeacherNameSmall: "2rem",
      titleBarList: "1.375rem",
      titleBarText: "1.25rem",
      titleBarTextSmall: "1.125rem",
      statusBarClassButton: "1.375rem",
      statusBarClassButton2: "1.5rem",
      statusBarClassButton3: "1.25rem",
      statusBarUrgentFont: "1.125rem",
      statusBarUrgentSmallFont: "1.125rem",
      statusBarClassDropdownButton: "1rem",
      classTileClassName: "1.5rem",
      classTileData: "1.25rem",
      classTileData2: "1.125rem",
      mainInstruction: "2.125rem",
      mainInstructionSelection: "1.875rem",
      middleInstruction: "1.625rem",
      middleInstructionSelection: "1.375rem",
      secondaryInstruction: "1.25rem",
      secondaryInstructionSelection: "1.125rem",
      gradebookScore: "2.5rem",
      headerText: "1.25rem",
      expandoText: "1.5rem",
      level1Text: "1.375rem",
      level2Text: "1.25rem",
      dataText: "1.125rem",
      scopeBox: "1.125rem",
      smallText: "1rem",
      chevron: "1.125rem",
    },
    icons: {
      titleBar: "2.625rem",
      titleBar2: "1.875rem",
      titleBarSmall: "2.25rem",
      titleBar2Small: "1.5rem",
      statusBar: "2rem",
      statusBarSmall: "1.875rem",
      faIcon: "1.75rem",
      materialIcon: "1.25rem",
      header: "1.875rem",
      editArrow: "2rem",
    },
  },
  typography: {
    fontFamily: "Roboto, Open Sans, Segoe UI, Helvetica, Arial, sans-serif",
    fontWeightBoldish: 600,
    button: {
      textTransform: "none",
    },
  },
};

export { liftThemeData };
