import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

axios.interceptors.request.use((req) => {
  // console.debug(`${req.method} ${req.url}`);
  const token = localStorage.getItem("token");
  req.headers["Authorization"] = `Bearer ${token}`;
  // Important: request interceptors **must** return the request.
  return req;
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
