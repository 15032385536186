// default
let CONFIG;

if (process.env.REACT_APP_ENV === 'staging') {
    CONFIG = {
        showLearnersTab: false,
        showGroupsTab: false,
        showSubjectsTab: false,
        showProjectsTab: false,
        showSkillsTab: false,
        showProgressTab: false,
        showClassesAddEditButton: false,
        showEvidencesTab: false,
        showPortfoliosTab: false,
        isSelectClassMode: false,
        showGradebookTab: false,
        showGradeReportsTab: false,
        showArrows: false,
        showMessaging: false,
        showNavigationPaneSearch: false,
        showClassesFilterButtons: false,
        showClassesPreview: false,
        showForgotPassword: false
    }
} else {
    CONFIG = {
        showLearnersTab: true,
        showGroupsTab: true,
        showSubjectsTab: true,
        showProjectsTab: true,
        showSkillsTab: true,
        showProgressTab: true,
        showClassesAddEditButton: true,
        showEvidencesTab: true,
        showPortfoliosTab: true,
        isSelectClassMode: true,
        showGradebookTab: true,
        showGradeReportsTab: true,
        showArrows: true,
        showMessaging: true,
        showNavigationPaneSearch: true,
        showClassesFilterButtons: true,
        showClassesPreview: true,
        showForgotPassword: true
    }
}

export { CONFIG }
