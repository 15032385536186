// helper functions for buildColorSet and mutedColor
function rgbToHexString(r, g, b) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;
  return "#" + r + g + b;
}

function parseRgbString(h) {
  let r = 0,
    g = 0,
    b = 0;

  if (h.length !== 7) return [0, 0, 0];
  r = "0x" + h[1] + h[2];
  g = "0x" + h[3] + h[4];
  b = "0x" + h[5] + h[6];
  return [Number(r), Number(g), Number(b)];
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      default:
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [Math.floor(h * 360), Math.floor(s * 100), Math.floor(l * 100)];
}

// newS is the saturation to use, ranges for 0 to 100 (50 is a good choice)
const mutedColor = (original, newS, newL) => {
  const [r, g, b] = parseRgbString(original);
  const [h, s, l] = rgbToHsl(r, g, b);
  const modS = newS && newS !== -1 ? newS : s;
  const modL = newL && newL !== -1 ? newL : l;
  const hexColor = hslToHex(h, modS, modL);

  // console.debug(`r: ${r}, g: ${g}, b: ${b}; h: ${h}, s: ${s}, l: ${l}; hex: ${hexColor}`);
  return hexColor;
};

// can either provide an array of custom colors (colorArray) or two points in the color space to interpolate (color1, color1, levels)
const buildColorSet = (color1, color2, levels) => {
  let colorInfo = [];
  if (color1 && color2 && levels >= 2) {
    colorInfo.push(color1);
    if (levels > 2) {
      // parse colors
      const [r1, g1, b1] = parseRgbString(color1);
      const [r2, g2, b2] = parseRgbString(color2);

      // interpolate intermediate colors
      for (let i = 1; i < levels - 1; i++) {
        const r = Math.round(r1 + ((r2 - r1) * i) / (levels - 1));
        const g = Math.round(g1 + ((g2 - g1) * i) / (levels - 1));
        const b = Math.round(b1 + ((b2 - b1) * i) / (levels - 1));
        const newColor = rgbToHexString(r, g, b);
        colorInfo.push(newColor);
      }
    }
    colorInfo.push(color2);
    // console.debug(`Custom colors: ${colorInfo}`)
  }
  return colorInfo;
};

export { buildColorSet, mutedColor };
