import React from "react";
import { liftTheme } from "./App"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from "@material-ui/core";
import { getRatingLabel, lookupLingo, TermEvidence, TermSkill, highestRating } from "./customizable"

const choiceCancel = 0, choiceAll = 1, choiceOption = 2;
const optionSelected = 0, optionUnderReview = 1, optionSubmitted = 2, optionInProgress = 3, optionAssigned = 4;

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(2),
    },
    optionText: {
        fontSize: 18,
        fontWeight: "normal",
        marginBottom: theme.spacing(3),
    }
}));

const BulkOptionsDialog = ({ selectedCount, bulkMarkState, open, handleOnClose, forEvidence = true, ...props }) => {
    if (isNaN(selectedCount) || !bulkMarkState || isNaN(open) || !handleOnClose) console.debug(`BulkOptionsDialog: missing or invalid parameters`);
    const classes = useStyles(liftTheme);

    // state hooks--the one source of truth
    const [bulkMark, setBulkMark] = bulkMarkState;

    // given handleChange, handleClose just needs to copy the state back and update the custom colors
    const handleClose = (choice) => {
        setBulkMark({ choice: choice, option: bulkMark.option });
        handleOnClose({ choice: choice, option: bulkMark.option }); // changing state is asynchronous, so sometimes state value is wrong, so send a copy to be sure.
    };

    const handleChange = (ev) => {
        setBulkMark({ choice: choiceCancel, option: ev.target.value });
        ev.stopPropagation();
    };

    // todo: now hardwired to 5 ratings, use a loop instead (call getRatingsCount)
    let optionsMenu;
    if (forEvidence)
        optionsMenu = <Select labelId="selectBulkOption" id="selectBulkOption" value={bulkMark.option.toString()} className={classes.text} onChange={ev => handleChange(ev)} >
            {/* <MenuItem value={optionSelected.toString()}>selected evidence</MenuItem> */}
            <MenuItem value={optionUnderReview.toString()}>{lookupLingo(TermEvidence)} under review </MenuItem>
            <MenuItem value={optionSubmitted.toString()}>submitted {lookupLingo(TermEvidence)} </MenuItem>
            <MenuItem value={optionInProgress.toString()}>{lookupLingo(TermEvidence)} in progress</MenuItem>
            <MenuItem value={optionAssigned.toString()}>assigned {lookupLingo(TermEvidence)} </MenuItem>
        </Select>
    else {
        const levels = [];
        for (let i = highestRating; i > 0; i--) levels.push(i);

        optionsMenu = <Select labelId="selectBulkOption" id="selectBulkOption" value={bulkMark.option.toString()} className={classes.text} onChange={ev => handleChange(ev)} >
            {levels.map(level => <MenuItem value={level}>{getRatingLabel(level)} {(level !== highestRating) ? " or higher" : ""}</MenuItem>)}
        </Select>
    }

    let title, option1, option2;
    if (forEvidence) {
        title = `${lookupLingo(TermEvidence, true, true)} bulk selection`;
        option1 = "Mark all selected for completion (regardless of status)";
        option2 = <span>Mark all selected {optionsMenu} for completion</span>;
    } else {
        title = `${lookupLingo(TermSkill, true, true)} bulk selection`;
        option1 = "Approve all selected (regardless of rating)";
        option2 = <span>Approve all selected {optionsMenu}</span>;
    }

    return <Dialog open={open} onClose={() => handleClose(choiceCancel)}>
        <DialogTitle id="BulkOptionsDialog">Bulk Options</DialogTitle>
        <DialogContent>
            <div className={classes.header}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="h5">Choose the action you would like to take for</Typography>
                <Typography variant="h5">{selectedCount} items selected:</Typography>
            </div>
            <Button variant="contained" color="secondary" onClick={() => handleClose(choiceAll)} className={classes.optionText}>{option1}</Button>
            <Button variant="contained" color="secondary" onClick={() => handleClose(choiceOption)} className={classes.optionText}>{option2}</Button>
        </DialogContent >
        <DialogActions>
            <Button onClick={() => handleClose(choiceCancel)} color="secondary">Cancel</Button>
        </DialogActions>
    </Dialog >
}

export { BulkOptionsDialog, choiceCancel, choiceAll, choiceOption, optionSelected, optionUnderReview, optionSubmitted, optionInProgress, optionAssigned };
